import { Component, OnInit } from '@angular/core'
import { PrimeNGConfig } from 'primeng/api'
import { v4 as uuidv4 } from 'uuid';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { HomeService } from '@Services/home.service'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { ConfirmationService } from './utility/confirmation.service'
import { IndexdbHelperService } from '@Utils/indexdb-helper.service'
import { ISEOProperties } from "@Interface/centax-data.interface";
import { LocalStorageDataModel } from './constant/localstorage.constant';
import { environment } from '@Env/environment';
import { NETCORE_EVENT_LIST } from './constant/netcore.eventList';
import { HeightAdjustElementType } from './constant/centax-app.constant';
declare var smartech: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	showSidebar: boolean = false
	showHeader: boolean = false
	isLoggedIn: boolean = false
	showFooter: boolean = false
	isShowResearchRenewalStip: boolean = false;

	constructor(
		private router: Router,
		private primengConfig: PrimeNGConfig,
		private homeService: HomeService,
		public appUtility: AppUtilityService,
		// private serviceWorker: CentaxServiceWorker,
		public confirmationService: ConfirmationService,
		private activatedRoute: ActivatedRoute,
		private indexDBHelperService: IndexdbHelperService
	) {
		// this.serviceWorker.updateClient();
		// this.serviceWorker.checkUpdate();
	}

	ngOnInit(): void {
		
		this.storeUtmInfo();
		this.isLoggedIn = this.appUtility.isLoggedIn()
		this.homeService.showPricing.next(true)
		caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
		sessionStorage.setItem('tabList', '[]')
		this.onHeaderSubscription()
		this.onSidebarSubscription()
		this.onFooterSubscription()
		this.onRouteChangeSubscription()
		this.primengConfig.ripple = true

	}
	onHeaderSubscription(): void {
		this.homeService.showHeader.subscribe((value) => {
			this.showHeader = value
		})
	}

	onSidebarSubscription(): void {
		this.homeService.showSidebarPage.subscribe((flag: boolean) => {
			this.showSidebar = flag
		})
		this.homeService.showSidebarPage.next(false)

		// if (this.appUtility.isMobileView()) {
		// 	this.homeService.showSidebarPage.next(false)
		// }
		// we are taking subscription flag value here , no need for this code
		//else {
		//	this.homeService.showSidebarPage.next(true);
		// }
	}

	onFooterSubscription(): void {
		this.homeService.showFooter.subscribe((value) => {
			this.showFooter = value
		})
	}

	onRouteChangeSubscription(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				// Show progress spinner or progress bar
				// console.log('Route change detected');
				if (!this.appUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP)) {
					this.homeService.getUserIp();
				}
			}

			if (event instanceof NavigationEnd) {
				let netCorePayload = {
					pageUrl: window.location.href
				}
				this.appUtility.netCoreEventTrack(NETCORE_EVENT_LIST.PAGE_BROWSE, netCorePayload)
				console.log("route event", event)
				// Hide progress spinner or progress bar  currentUrl = event.url

				// if (event.url.includes('/auth/login')) {
				// 	this.router.navigate(['/auth/login']);
				// 	this.appUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN);
				// } else {
				// }
				this.initiateMetaProcess();
				let utm_campaign = this.appUtility.getDataFromLocalStorage('activeCampaign');
				if (utm_campaign) {
					if (this.appUtility.getDataFromLocalStorage('activeCampaignUrl') !== this.router.url)
						this.homeService.pushUtmSourceInformation('Page View with campaign - ' + utm_campaign)
				}

				this.activatedRoute.queryParams.subscribe((queryParams: any) => {
					if (!queryParams?.documentSearch || !queryParams) {
						this.indexDBHelperService.clearStore('upload_and_search').subscribe((res) => { })
						this.appUtility.setHideAdvanceSearchIcon(false)
						this.appUtility.setFileUploadSearchResult({ fileName: '', data: {} })
						this.appUtility.setDataOnLocalStorage('fileName', '')
					}
				})
				if (event.url.includes('/auth/login') || event.url.includes('/auth/register') || event.url.includes('/auth/mobileVerification') || event.url.includes('/store/checkout')) {
				} else {
					this.homeService.removeCartInfoFromLocalStorage();
				}
				if (this.isLoggedIn) {
					let path = window.location.href;
					this.homeService.userSiteTracking(path).subscribe();
				}
			}

			if (event instanceof NavigationError) {
				// Hide progress spinner or progress bar
				// Present error to user
				// console.log(event.error);
				// this.router.navigate(['/'])
			}
		})
	}

	initiateMetaProcess() {
		var rt = this.getChild(this.activatedRoute)
		rt.data.subscribe((data) => {
			if (data.hasParams) {
				rt.params.subscribe((params: any) => {
					let paramsVal = params[data.seoDeciderParamsName]
					let seoData = data.seo[data.map[paramsVal]]
					this.applySeoData(seoData)
				})
			} else {
				if (data.seo) {
					this.applySeoData(data.seo)
				} else {
					this.applySeoData()
				}
			}
		})
	}

	getChild(activatedRoute: ActivatedRoute) {
		if (activatedRoute.firstChild) {
			return this.getChild(activatedRoute.firstChild)
		} else {
			return activatedRoute
		}
	}

	applySeoData(data: ISEOProperties = { title: 'CentaxOnline | An Online Library for GST, Customs, Excise, & Allied Laws' }) {
		this.appUtility.applySeoData(data);
	}

	storeUtmInfo() {

		let campaignName = this.appUtility.getDataFromLocalStorage('activeCampaign');
		this.appUtility.removeFromLocalStorage('activeCampaign');
		this.appUtility.removeFromLocalStorage(campaignName);
		this.appUtility.removeFromLocalStorage('utm_campaign_uuid')
		this.activatedRoute.queryParams.subscribe({
			next: (params: any) => {
				if (params.utm_campaign) {
					let utm_campaign_uuid = this.appUtility.getDataFromLocalStorage('utm_campaign_uuid') || '';
					if (!utm_campaign_uuid) {
						utm_campaign_uuid = uuidv4();
						this.appUtility.setDataOnLocalStorage('utm_campaign_uuid', utm_campaign_uuid);
					}
					let utmInfo = {};
					Object.keys(params).forEach((key: string) => {
						if (key.includes('utm_')) {
							utmInfo[key] = params[key];
						}
					});
					this.appUtility.setDataOnLocalStorage(params.utm_campaign, JSON.stringify(utmInfo));
					this.appUtility.setDataOnLocalStorage('activeCampaign', params.utm_campaign);
					this.appUtility.setDataOnLocalStorage('activeCampaignUrl', this.router.url);
					this.homeService.pushUtmSourceInformation('User Initial visit by ' + params.utm_campaign)
				}
			}
		})
	}
	ngAfterViewInit() {
		smartech('register', environment['NETCORE_REGISTER_KEY']);
	}

	researchRenewalStipEvent(event){
       this.isShowResearchRenewalStip = event;
	   this.giveDynamicHeightToSidebar();
	}

	giveDynamicHeightToSidebar(): void{
		this.appUtility.adjustViewHeight('dynamicHeight', HeightAdjustElementType.HTML_CLASS)
	}

}
