import { EventEmitter, Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { BehaviorSubject, Subject, Subscription, interval, take } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ApiStatus, CentaxAppApiConstant, HeightAdjustElementType } from '../constant/centax-app.constant'
import { LocalStorageDataModel } from '../constant/localstorage.constant'
import { ActivatedRoute, Router } from '@angular/router'
import { IndexDBService } from './indexdb.service'
import { printActsCss, PrintCommand, printCss, printPreviewCss } from '@App/constant/constant'
import { IndexdbHelperService } from '@Utils/indexdb-helper.service'
import { filecontentCSS } from '@App/modules/right-panel/constant/file-modify-css'
import { ISEOProperties, IUTMDataProps } from "@Interface/centax-data.interface";
import { Meta, Title } from "@angular/platform-browser";
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { SocialAuthService } from "@abacritt/angularx-social-login";
declare var smartech: Function;
import * as CryptoJS from "crypto-js"

@Injectable({
	providedIn: 'root',
})
export class AppUtilityService {

	defaultOgImage: string = this.getImageUrl() + 'centax/centax-logo-1.png';
	_showLoader: EventEmitter<boolean> = new EventEmitter<boolean>(true)
	_showConfirmPopup: EventEmitter<boolean> = new EventEmitter<boolean>(false)
	reloadResearchBoxFolder = new Subject<boolean>()
	searchHeaderText: {
		searchText: string,
		isGlobal: boolean
	} = {
			searchText: '',
			isGlobal: false
		};
	subjectLabelArr = []
	printWindow: any
	confirmationPopupDetails = new Subject<any>()
	public showMobileFilterIcon$ = new BehaviorSubject<boolean>(false)
	public resetSubjectFilter = new Subject<boolean>()
	uploadDocumentSearchResults = new BehaviorSubject<any>({})
	hideAdvanceSearchIcon = new Subject<boolean>()
	hideSearchSuggestion = new Subject<boolean>()
	resetAdvSearchFilters = new Subject<string>()
	toggleResearchBoxMobView = new Subject<boolean>()
	public isExcusSearch: boolean = false
	public isAdvSearch: boolean = false //subject phrase or advance
	public isheadnoteToggle: boolean = false //search in headnote
	private subjectRelevanceSrch$ = new Subject<boolean>()
	private SubjectHeadnoteSrch$ = new Subject<boolean>()
	private HeadnoteSrchCP$ = new Subject<boolean>()

	private phraseSearchText$ = new Subject()
	public subjectSearchStatus$ = new Subject<boolean>()
	public searchTxtCpSugg$ = new Subject()
	activeIndex: number = 0
	selectedLatestStoryType = 'ALL'
	public middlePanelLoading: boolean = false

	// private resetAdvSrchFilter$ = new Subject<boolean>();
	researchRelatedCaseActive = new Subject<{ id: any; flag: boolean }>()
	dynamicResizeTimer
	socialLoginUserData = new BehaviorSubject<any>(null);
	set showConfirmPopup(val: boolean) {
		this._showConfirmPopup.emit(val)
	}

	set showLoader(val: boolean) {
		this.middlePanelLoading = val
		this._showLoader.emit(val)
	}

	public showFilter$ = new BehaviorSubject(false)
	private advanceSearchResultData$ = new Subject<any>()
	private showGlobalSearch$ = new BehaviorSubject(<boolean>true)
	exactPhraseFilterText: string = ''
	checkForLogin = new BehaviorSubject<boolean>(null)
	userAuthStatus = new Subject<number>()
	TimerSubscription: Subscription;
	public errorAPILimitExceed$ = new BehaviorSubject<any>(
		{
			counter: 0,
			isAPILimitExceed: false
		}
	)
	public errorAPIInvalidCharacters$ = new BehaviorSubject<boolean>( false )
	constructor(
		private router: Router,
		public indexDBService: IndexDBService,
		public indexDBHelperService: IndexdbHelperService,
		private activatedRoute: ActivatedRoute, private titleService: Title,
		private socialAuthService: SocialAuthService,
		private metaService: Meta
	) { }

	getShowFilter() {
		return this.showFilter$.asObservable()
	}

	setShowFilter(data: any) {
		this.showFilter$.next(data)
	}

	getSubjectRelevanceEnable() {
		return this.subjectRelevanceSrch$.asObservable()
	}

	setSubjectRelevanceEnable(data: any) {
		this.subjectRelevanceSrch$.next(data)
	}
	getSubjectSearchStatus() {
		return this.subjectSearchStatus$.asObservable()
	}

	setSubjectSearchStatus(data: any) {
		this.subjectSearchStatus$.next(data)
	}

	getPhraseSearchText() {
		return this.phraseSearchText$.asObservable()
	}

	setPhraseSearchText(data: any) {
		this.phraseSearchText$.next(data)
	}

	// getResetAdvSrchFilter() {
	// 	return this.resetAdvSrchFilter$.asObservable();
	// }

	// setResetAdvSrchFilter(data: any) {
	// 	this.resetAdvSrchFilter$.next(data);
	// }

	getSubjectHeadNoteEnable() {
		return this.SubjectHeadnoteSrch$.asObservable()
	}

	setSubjectHeadNoteEnable(data: any) {
		this.SubjectHeadnoteSrch$.next(data)
	}

	getCPHeadNoteEnable() {
		return this.HeadnoteSrchCP$.asObservable()
	}

	setCPHeadNoteEnable(data: any) {
		this.HeadnoteSrchCP$.next(data)
	}

	getShowMobileFilterIcon() {
		return this.showMobileFilterIcon$.asObservable()
	}

	setShowMobileFilterIcon(data: any) {
		this.showMobileFilterIcon$.next(data)
	}

	getShowGlobalSearchObs() {
		return this.showGlobalSearch$.asObservable()
	}

	setShowGlobalSearchObs(data) {
		this.showGlobalSearch$.next(data)
	}

	getSearchTxtCpSugg() {
		return this.searchTxtCpSugg$.asObservable()
	}

	setSearchTxtCpSugg(data) {
		this.searchTxtCpSugg$.next(data)
	}

	getDbInstance() {
		return this.indexDBService
	}

	setDataOnSessionStorage(key: string, value: string) {
		sessionStorage.setItem(key, value)
	}

	getDataFromSessionStorage(key: string): string {
		if (sessionStorage.getItem(key) != 'undefined' && sessionStorage.getItem(key) != null)
			return sessionStorage.getItem(key)
		return ''
	}

	removeFromSessionStorage(key: string) {
		sessionStorage.removeItem(key)
	}

	setDataOnLocalStorage(key: string, value: string) {
		localStorage.setItem(key, value)
	}

	getDataFromLocalStorage(key: string): string {
		if (localStorage.getItem(key) != 'undefined' && localStorage.getItem(key) != null)
			return localStorage.getItem(key)
		return ''
	}

	removeFromLocalStorage(key: string) {
		localStorage.removeItem(key)
	}

	setDataOnLocalStorageWithExpiry(key, value, ttl) {
		const now = new Date()
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
			date: now.toLocaleDateString(),
		}
		localStorage.setItem(key, JSON.stringify(item))
	}

	getDataFromLocalStorageWithExpiry(key, expireDaily: boolean = false) {
		const itemStr = localStorage.getItem(key)
		if (!itemStr) {
			return null
		}
		const item = JSON.parse(itemStr)
		const now = new Date()
		if (expireDaily) {
			let cdate = now.toLocaleDateString()
			if (cdate != item.date) {
				localStorage.removeItem(key)
				return null
			} else {
				return item.value
			}
		} else {
			if (now.getTime() > item.expiry) {
				localStorage.removeItem(key)
				return null
			}
			return item.value
		}
	}

	setDataOnSessionStorageWithExpiry(key, value, ttl) {
		const now = new Date()
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
			date: now.toLocaleDateString(),
		}
		sessionStorage.setItem(key, JSON.stringify(item))
	}

	getDataFromSessionStorageWithExpiry(key, expireDaily: boolean = false) {
		const itemStr = sessionStorage.getItem(key)
		if (!itemStr) {
			return null
		}
		const item = JSON.parse(itemStr)
		const now = new Date()
		if (expireDaily) {
			let cdate = now.toLocaleDateString()
			if (cdate != item.date) {
				sessionStorage.removeItem(key)
				return null
			} else {
				return item.value
			}
		} else {
			if (now.getTime() > item.expiry) {
				sessionStorage.removeItem(key)
				return null
			}
			return item.value
		}
	}

	setCookie(cname, cvalue, exhour) {
		var d = new Date()
		d.setTime(d.getTime() + exhour * 60 * 1000)
		var expires = 'expires=' + d.toUTCString()
		if (environment.production) {
			if (window.location.hostname.indexOf('localhost') !== -1) {
				document.cookie = cname + '=' + cvalue + ';path=/;expires=' + expires
			} else {
				document.cookie = cname + '=' + cvalue + ';domain=centaxonline.com;path=/;expires=' + expires
			}
		} else {
			document.cookie = cname + '=' + cvalue + ';domain=centaxonline.com;path=/;expires=' + expires
		}
	}

	isLoggedIn(): boolean {
		if (this.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			console.log('cookoe got')
			return true
		} else if (
			this.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1' &&
			this.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
		) {
			console.log('storage got')
			return true
		} else return false
	}

	isMobileView(): boolean {
		let mediaQueryObj = window.matchMedia('(max-width: 950px)')
		if (mediaQueryObj.matches) return true
		else return false
	}

	adjustViewHeight(
		key: string,
		type: Number,
		panelType?: string,
		forceFlag?: boolean,
		mobminusHeight: number = 0,
		minusHeight: number = 0
	) {
		if (this.isMobileView() && !forceFlag) {
			return
		}
		try {
			let selector
			if (type === HeightAdjustElementType.HTML_ELEMENT) {
				selector = key
			} else if (type === HeightAdjustElementType.HTML_CLASS) {
				selector = `.${key}`
			} else if (type === HeightAdjustElementType.HTML_ID) {
				selector = `#${key}`
			} else {
				selector = key
			}

			let arr = document.querySelectorAll(selector)
			if (selector && arr && arr.length) {
				let windowHeight = window.innerHeight
				Array.from(arr).forEach((element: HTMLElement) => {
					let elementTop = element.getBoundingClientRect().top
					if (elementTop > 0) {
						let remainingHeight = +windowHeight - +elementTop
						if (remainingHeight > 0) {
							if (panelType === 'middle') {
								element.style.height = `${remainingHeight - 15}px`
							} else if (this.isMobileView()) {
								element.style.height = `${remainingHeight - 15 - mobminusHeight}px`
							} else {
								element.style.height = `${remainingHeight - 15 - minusHeight}px`
							}
						}
					}
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	public validateAllFormFields(form: FormGroup) {
		const keys = Object.keys(form.controls)
		keys.forEach((field: any) => {
			const control = form.get(field)
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true })
				control.markAsDirty({ onlySelf: true })
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control)
			} else if (control instanceof FormArray) {
				; (<FormArray>control).controls.forEach((element: FormGroup) => {
					if (element instanceof FormGroup) this.validateAllFormFields(element)
				})
			}
		})
	}

	getApiUrl(apiUrl: string, apiServer: string = 'centax') {
		if (apiServer == 'node') {
			return environment['api'][CentaxAppApiConstant.NODE_SERVER] + apiUrl
		} else if (apiServer == 'local') {
			return environment['api'][CentaxAppApiConstant.LDEV_SERVER] + apiUrl
		} else if (apiServer == 'crm') {
			return environment['api'][CentaxAppApiConstant.CRM_SERVER] + apiUrl
		} else if (apiServer == 'centaxAdmin') {
			return environment['api'][CentaxAppApiConstant.CENTAX_ADMIN_SERVER] + apiUrl
		} else if (apiServer == 'donwloadpdf') {
			return environment['api'][CentaxAppApiConstant.DDOWLOAD_PDF] + apiUrl
		}else if (apiServer == 'donwloadrtf') {
			return environment[CentaxAppApiConstant.DOWNLOAD_RTF_URL] + apiUrl
		}else {
			return environment['api'][CentaxAppApiConstant.CENTAX_SERVER] + apiUrl
		}
	}

	makeMarketingUrl(api_url: string) {
		return environment.MARKETING_API + api_url;
	}

	getOneSignal() {
		if (window.location.hostname.indexOf('localhost') !== -1) {
			return "b7a2bc27-ab46-44d3-a38c-2ed815e4d8dc";
		} else {
			return environment.ONE_SIGNAL_KEY
		}
		
	}

	getImageUrl() {
		return environment['IMAGE_URL']
	}

	getCentaxCdnImageUrl() {
		return environment['CENTAX_CDN_IMAGE_URL']
	}

	getApiBasePath(apiServer: string = 'centax') {
		if (apiServer == 'node') {
			return environment['api'][CentaxAppApiConstant.NODE_SERVER]
		} else if (apiServer == 'ldev') {
			return environment['api'][CentaxAppApiConstant.LDEV_SERVER]
		} else {
			return environment['api'][CentaxAppApiConstant.CENTAX_SERVER]
		}
	}

	urlNavigation(url, type = 'internal', sameTab: true) {
		if (url) {
			if (type == 'external') {
				if (sameTab) {
					window.open(url, '_self')
				} else {
					window.open(url, '_blank')
				}
			} else {
				if (sameTab) {
					this.router.navigate([url])
				} else {
					window.open(url, '_blank')
				}
			}
		}
	}

	addDynamicStyles(styleContent: string) {
		const css = styleContent
		const head = document.getElementsByTagName('head')[0]
		const style = document.createElement('style')
		style.type = 'text/css'
		style.appendChild(document.createTextNode(css))
		head.appendChild(style)
	}

	printResearchData(type: number, html: string, listFlag: boolean, fileName?: string) {
		html = html.replace(/\[\n\r]/g, '') //remove escape characters
		html = html.replace(/<!--[\s\S]*?-->/g, '') // noise removal due to comments
		if (fileName) {
			fileName = fileName.substr(0, 60)
		}
		let finalHtml = ''

		if (listFlag) {
			finalHtml = `<html><head>

			<link media="print" rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
			<style>${printPreviewCss} ${filecontentCSS} ${printActsCss}</style>
			<style>
			.grid-hor-1 {
				border-bottom: 1px dashed #000000;
			}</style>
			</head><body>${html}</body></html>`
		} else {
			finalHtml = html
		}

		if (type === PrintCommand.PRINT) {
			this.printWindow = window.open('www.centaxonline.com', 'Print')
			this.printWindow.document.write(finalHtml)
			this.printWindow.document.close()
			setTimeout(() => {
				this.printWindow.focus()
				this.printWindow.print()
				if (!this.isMobileView()) {
					this.printWindow.close()
				}
			}, 200)
		} else {
			let element = document.createElement('a')
			if (type === PrintCommand.SAVE_HTML) {
				element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(finalHtml))
				element.setAttribute('download', `${fileName || 'fileData'}.html`)
			} else if (type === PrintCommand.SAVE_RTF) {
				element.setAttribute('href', 'data:application/rtf;charset=utf-8,' + encodeURIComponent(finalHtml))
				element.setAttribute('download', `${fileName || 'fileData'}.rtf`)
			} else if (type === PrintCommand.SAVE_XLS) {
				element.setAttribute(
					'href',
					'data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent(finalHtml)
				)
				element.setAttribute('download', `${fileName || 'fileData'}.xls`)
			} else if (type === PrintCommand.SAVE_DOC) {
				element.setAttribute(
					'href',
					'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(finalHtml)
				)
				element.setAttribute('download', `${fileName || 'fileData'}.doc`)
			} else {
				element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(finalHtml))
				element.setAttribute('download', `${fileName || 'fileData'}.html`)
			}
			element.style.display = 'none'
			document.body.appendChild(element)
			element.click()
			document.body.removeChild(element)
		}
	}

	get env() {
		if (window.location.hostname.indexOf('maaa') !== -1) {
			return 'local'
		}
		if (window.location.hostname.indexOf('localhost') !== -1) {
			return 'local'
		} else if (window.location.hostname.indexOf('taxpractice') !== -1) {
			return 'local'
		} else if (window.location.hostname.indexOf('preprod') !== -1) {
			return 'beta'
		} else if (window.location.hostname.indexOf('mctl') !== -1) {
			return 'local'
		} else if (window.location.hostname.indexOf('lsupport') !== -1) {
			return 'local'
		} else if (window.location.hostname.indexOf('vflipbook') !== -1) {
			return 'local'
		} else {
			return 'prod'
		}
	}

	getPrintPagination(totalRecords: number) {
		let count = 0
		let pageSize = 50
		let arr = []

		while (totalRecords > 0) {
			if (totalRecords / pageSize >= 1) {
				const start = count + 1
				const end = count + pageSize
				arr.push({
					label: `${start} - ${end}`,
					value: {
						start: start,
						end: end,
					},
				})
				count += pageSize
				totalRecords -= pageSize
			} else {
				const start = count + 1
				const end = count + totalRecords
				arr.push({
					label: `${start} - ${end}`,
					value: {
						start: start,
						end: end,
					},
				})
				count += totalRecords
				totalRecords = 0
			}
		}

		return arr
	}

	openInNewWindow(categoryName: string, fileId: string, subCategory: string, searchText: string) {
		let url = `${location.origin}/preview-document/?categoryName=${categoryName}&fileId=${fileId}&subCategory=${subCategory}`
		if (searchText) {
			url += `&searchText=${encodeURIComponent(searchText)}`
		}
		if(this.subjectLabelArr && this.subjectLabelArr.length){
			if (searchText) {
				url += ` ${this.subjectLabelArr.join(' ')}`;
			}else{
			url += `&searchText=${this.subjectLabelArr.join(' ')}`;
			}
		}
		if (this.isExcusSearch == true) {
			url += `&excusSearch=1`
		}	
		if(this.isAdvSearch){
		}else{
			url += `&excusSearch=1`
		}
		//	window.open(url, '_blank', "scrollbars=yes,resizable=yes,top=100,left=200,width=900,height=400")
	}

	navigateRetainingURL(targetUrl: string, queryParams: object, queryParamsHandling: any): void {
		this.router.navigate([targetUrl], { queryParams: { ...queryParams }, queryParamsHandling })
	}

	getAdvSearchRes() {
		return this.advanceSearchResultData$.asObservable()
	}

	setAdvSearchRes(data) {
		this.advanceSearchResultData$.next(data)
	}

	getFileUploadSearchResult() {
		return this.uploadDocumentSearchResults.asObservable()
	}

	setFileUploadSearchResult(data) {
		if (data) {
			this.uploadDocumentSearchResults.next(data)
		}
	}

	getHideAdvanceSearchIcon() {
		return this.hideAdvanceSearchIcon.asObservable()
	}

	setHideAdvanceSearchIcon(data) {
		this.hideAdvanceSearchIcon.next(data)
	}

	getHideSearchSuggestion() {
		return this.hideSearchSuggestion.asObservable()
	}

	setHideSearchSuggestion(data) {
		this.hideSearchSuggestion.next(data)
	}

	// format number
	formatNumber(num) {
		if (num < 10) {
			num = '0' + num
		}
		return num.toString()
	}

	/**
	 * getFormattedDate // dd/mm/yyyy
	 * @param date
	 * @param filterDateType
	 */
	getFormattedDate(date, filterDateType?: number): string {
		const dt = new Date(date)
		const month = this.formatNumber(dt.getMonth() + 1)
		const day = this.formatNumber(dt.getDate())
		const year = this.formatNumber(dt.getFullYear())

		let formattedDate = `${day}/${month}/${year}` // format : DD/MM/YYYY // invalid in new Date('formattedDate')
		if (filterDateType) {
			switch (filterDateType) {
				case 1:
					formattedDate = year + month + day // format YYYYMMDD
					break
				case 2:
					formattedDate = `${year}-${month}-${day}` // format YYYY-MM-DD
					break
				case 3:
					formattedDate = `${day}-${month}-${year}` // format YYYY-MM-DD
					break
				default:
					break
			}
		}
		return formattedDate
	}

	setTabListInStorage(fileId): void {
		if (this.isLoggedIn()) {
			const tabList = JSON.parse(this.getDataFromSessionStorage('tabList') || '[]')
			if (!tabList.includes(fileId)) {
				tabList.push(fileId)
			}
			this.setDataOnSessionStorage('tabList', JSON.stringify(tabList))
		} else {
			this.setDataOnSessionStorage('tabList', JSON.stringify([]))
		}
	}
	// when tab close of reader
	removeTabFromTabList(fileId) {
		if (this.isLoggedIn()) {
			const tabList = JSON.parse(this.getDataFromSessionStorage('tabList') || '[]')
			if (tabList.includes(fileId)) {
				tabList.splice(
					tabList.findIndex((data) => data == fileId),
					1
				)
			}
			this.setDataOnSessionStorage('tabList', JSON.stringify(tabList))
		} else {
			this.setDataOnSessionStorage('tabList', JSON.stringify([]))
		}
	}

	setSubjectReset(data) {
		this.resetSubjectFilter.next(data)
	}

	getSubjectReset() {
		return this.resetSubjectFilter.asObservable()
	}

	scrollToTop(): void {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	setAdvSearchReset(data) {
		this.resetAdvSearchFilters.next(data)
	}

	getAdvSearchReset() {
		return this.resetAdvSearchFilters.asObservable()
	}

	setRboxMobView(data) {
		this.toggleResearchBoxMobView.next(data)
	}

	getRboxMobView() {
		return this.toggleResearchBoxMobView.asObservable()
	}

	getDataByStoreName(storeName: string): any {
		return this.indexDBHelperService.getAllData(storeName)
	}

	onResize(event, className: string) {
		if (this.dynamicResizeTimer) {
			clearTimeout(this.dynamicResizeTimer)
		}
		this.dynamicResizeTimer = setTimeout(() => {
			this.adjustMobileHeight(className)
		}, 500)
	}

	adjustMobileHeight(className: string) {
		this.adjustViewHeight(className, HeightAdjustElementType.HTML_CLASS, '', true, 40)
	}

	download(url: string, checkLogin: boolean, target: string, fileName: string) {
		if (checkLogin) {
			if (this.isLoggedIn()) {
				this.downloadUrl(url, target, fileName)
			} else {
				this.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
				this.router.navigate(['auth/login'])
			}
		} else {
			this.downloadUrl(url, target, fileName)
		}
	}

	downloadUrl(url, target, fileName) {
		let element = document.createElement('a')
		element.setAttribute('href', url)
		element.setAttribute('download', fileName)
		element.setAttribute('target', target)
		element.style.display = 'none'
		document.body.appendChild(element)
		element.click()
		document.body.removeChild(element)
	}



	applySeoData(data: ISEOProperties = { title: 'CentaxOnline | An Online Library for GST, Customs, Excise, & Allied Laws' }) {
		data.title = data?.title?.trim();
		data.description = data?.description?.trim();

		this.titleService.setTitle(data.title)

		if (data?.title) {
			this.metaService.updateTag({ property: 'og:title', content: data.title })
			this.metaService.updateTag({ name: 'twitter:title', content: data?.title || '' || '' });

		} else {
			this.metaService.removeTag("property='og:title'")
			this.metaService.removeTag("property='twitter:title'");

		}
		if (data?.description) {
			this.metaService.updateTag({ name: 'description', content: data.description })
			this.metaService.updateTag({ property: 'og:description', content: data.description })
			this.metaService.updateTag({ name: 'twitter:description', content: data?.description || '' });
		} else {
			this.metaService.removeTag("name='description'")
			this.metaService.removeTag("property='og:description'")
			this.metaService.removeTag("property='twitter:description'");
		}
		if (data?.keywords) {
			this.metaService.updateTag({ name: 'keywords', content: data?.keywords })
		} else {
			this.metaService.removeTag("name='keywords'")
		}
		if (data?.url) {
			this.metaService.updateTag({ property: 'og:url', content: data?.url || window?.location?.href })
		} else {
			// this.metaService.removeTag("property='og:url'")
			this.metaService.updateTag({ property: 'og:url', content: window?.location?.href })
		}
		if (data?.image) {
			this.metaService.updateTag({ property: 'og:image', content: data?.image || this.defaultOgImage })
			this.metaService.updateTag({ name: 'twitter:image', content: data?.image || this.defaultOgImage });
		} else {
			//this.metaService.removeTag("property='og:image'")
			//this.metaService.removeTag("property='twitter:image'");
			this.metaService.updateTag({ property: 'og:image', content: this.defaultOgImage })
			this.metaService.updateTag({ name: 'twitter:image', content: this.defaultOgImage });

		}
		this.metaService.updateTag({ property: 'og:type', content: 'website' });
		this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
		this.metaService.updateTag({ name: 'twitter:site', content: '@centaxonline.com' });

		// if (data?.robots) {
		// 	this.metaService.updateTag({ name: 'robots', content: data.robots })
		// } else {
		// 	this.metaService.updateTag({ name: 'robots', content: 'follow,index' })
		// }

	}

	updateSEOImage(image: string): void {
		if (image) {
			this.metaService.updateTag({ property: 'og:image', content: image || this.defaultOgImage })
			this.metaService.updateTag({ name: 'twitter:image', content: image || this.defaultOgImage });
		} else {
			this.metaService.removeTag("property='og:image'")
			this.metaService.removeTag("property='twitter:image'");

		}
	}

	createDefaultUtmInfoSite(): void {
		let campaignName = this.getDataFromLocalStorage('activeCampaign');

		if (!campaignName) {
			campaignName = 'REQUEST_FROM_CENTAX_SITE';
			const temp = {
				utm_source: campaignName,
				utm_campaign: campaignName,
				utm_medium: ''
			}
			this.setDataOnLocalStorage('activeCampaign', campaignName);
			this.setDataOnLocalStorage(campaignName, JSON.stringify(temp));
			const utm_campaign_uuid = uuidv4();
			this.setDataOnLocalStorage('utm_campaign_uuid', utm_campaign_uuid);
			this.setDataOnLocalStorage('activeCampaignUrl', `${this.router.url}?utm_source= ${temp.utm_source}&utm_campaign=${temp.utm_campaign}&utm_medium=${temp.utm_medium}`);
		}
	}

	setCRMData(data: any, actionType: string): IUTMDataProps {
		let startDate = moment().format('DD-MMM-YYYY');
		let endDate = moment().add(7, 'days').format('DD-MMM-YYYY');
		let campaignName = this.getDataFromLocalStorage('activeCampaign');
		let utmDetails = JSON.parse(this.getDataFromLocalStorage(campaignName))
		const returnData: IUTMDataProps = {
			fName: data?.fName,
			LName: data?.LName || '',
			eMailID: data?.eMailID || this.getDataFromLocalStorage(LocalStorageDataModel.USER_EMAIL),
			sCompanyName: data?.sCompanyName || '',
			sMobileNo: data?.sMobileNo || '',
			sCity: data?.sCity || '',
			leadsourcecode: (utmDetails?.utm_source == 'REQUEST_FROM_CENTAX_SITE' ? '100000016' : utmDetails?.utm_source) || '100000016', // REQUEST_FROM_SITE = 16 : utm_source
			sUserInterestedIn: actionType == 'FREE_TRIAL' ? '110000000' : '110000001',
			sCompanyType: '2',
			sUserType: data?.sUserType || '',
			sProduct: actionType == 'FREE_TRIAL' ? '44EF3970-EF6E-ED11-8223-005056A453E6' : 'BF5FDF7F-EF6E-ED11-8223-005056A453E6',
			sActionToTaken: data?.sActionToTaken || '', // FREE_TRIAL -> BLANK
			ProductType: actionType == 'FREE_TRIAL' ? '169220001' : '3',
			subActivationDate: actionType == 'FREE_TRIAL' ? startDate : '',
			subExpiryDate: actionType == 'FREE_TRIAL' ? endDate : '',
			MarkettingListID: data?.MarkettingListID || '',
			campaignsource: utmDetails?.utm_campaign || '',
			sAreaOfSpecialization: data?.sAreaOfSpecialization || '',
			Salutation: data?.Salutation || '',
			Designation: data?.Designation || '',
			sState: data?.sState || '',
			sSubjectmatter: data?.sSubjectmatter || '',
			sUserInterestedInOther: data?.sUserInterestedInOther || '',
			sPreferContactMethod: data?.sPreferContactMethod || '',
			sProfession: data?.sProfession || '',
			sCourseLevel: data?.sCourseLevel || '',
			sQualification: data?.sQualification || '',
		}
		return returnData;
	}

	socialLogout(): void {
		this.socialAuthService.signOut().then(res => {
			console.log(res, 'SOCIAL logout');
		}).catch(error => {
			console.log(error, 'errrrr')
		})
	}

	formatDate(date: any, format: any): any {
		return moment(date).format(format);
	}

	netCoreEventTrack(eventType, payload) {
		let email = this.getDataFromLocalStorage(LocalStorageDataModel.USER_EMAIL) || '';
		smartech('identify', email);
		smartech('dispatch', eventType, payload);
	}

	createUtmObj() {
		let utmObj = {}
		let utm_campaign = this.getDataFromLocalStorage('activeCampaign');
		if (utm_campaign) {
			let utmData = JSON.parse(this.getDataFromLocalStorage(utm_campaign));
			utmObj['utmCampaign'] = utmData.utm_campaign;
			utmObj['utmSource'] = utmData.utm_source;
			utmObj['utmMedium'] = utmData.utm_medium;
		}

		return utmObj;
	}

	getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	decrypt(ciphertext: string) {
		let key = environment.SCCRET_KEY_MACID;
		const secretKey = (key).replace(/KEYGEN/g, btoa('KEYGEN'));
        var bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
    }

}
