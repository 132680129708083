import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'
import { AppUtilityService } from '../utility/app-utlility.service'
import { LocalStorageDataModel } from '@Constants/localstorage.constant'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router, private appUtils: AppUtilityService) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.appUtils.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			return true
		} else if (
			this.appUtils.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1' &&
			this.appUtils.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
		) {
			return true
		} else {
			this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } })
			return false
		}
	}
}
