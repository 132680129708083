import { Directive, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core'

@Directive({
	selector: '[keyboard-event]',
})
export class KeyboardEvent implements OnInit {
	capsLockEnabled = null
	private _isEnabled: boolean
	@Output()
	public esc: EventEmitter<any> = new EventEmitter<any>()
	@Output()
	public enter: EventEmitter<any> = new EventEmitter<any>()
	@Output()
	public keydownEvent: EventEmitter<any> = new EventEmitter<any>()
	@Output()
	public capsEvent: EventEmitter<any> = new EventEmitter<any>()
	@Input()
	public set isEnabled(val: boolean) {
		this._isEnabled = val
		this.toggleEvents()
	}
	public get isEnabled() {
		return this._isEnabled
	}
	private globalListenFunc: Function

	constructor(private renderer: Renderer2) {}

	ngOnInit() {}

	private toggleEvents() {
		if (this.isEnabled) {
			this.enableKeypress()
		} else this.disableKeypress()
	}

	private enableKeypress() {
		this.globalListenFunc = this.renderer.listen('document', 'keydown', (e) => {
			this.keydownEvent.emit(e)
			if (e.keyCode == 27) this.esc.emit(e)
			else if (e.keyCode == 13) this.enter.emit(e)
		})
	}
	private disableKeypress() {
		if (this.globalListenFunc) this.globalListenFunc()
	}

	// private getChar(e) {
	//     if (e.which == null) {
	//         return String.fromCharCode(e.keyCode); // IE
	//     }
	//     if (e.which != 0 && e.charCode != 0) {
	//         return String.fromCharCode(e.which); // rest
	//     }
	//     return null;
	// }
}
