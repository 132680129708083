export const constants = {
	onlyNumbers: /^[0-9]*$/,
	onlyAlphabets: /^[a-zA-Z ]*$/,
	mobileNumber: /^[6-9]{1}[0-9]{9}$/,
	PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
	GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;'"<>,.?~=|`\\/-]).{6,}$/,
//	password: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
	numbersWithDecimal: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
	emailPattern:
		/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z](?:[a-z]*[a-z])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
	splitBySpaceHypens: /[ -]+/,
	sharableBookStoreUrlSeparator: '-|-',
	mobile: /^[1-9]{1}[0-9]*$/,
	alphabets: /^[a-zA-Z.'()& ]*$/,
	onlyOneNumber: /^[0-9]{1}$/,
	firstNumberNotZero: /^[1-9][0-9]*$/,
	// 	emailPattern :/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
	// 	emailPattern :/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
	alphabetNumbers: /^[a-zA-Z0-9.'()&“‘’,/;""\\ ]*$/,
}

export const letterheadHtml = `
   <table style="margin-bottom:10px; padding-top:25px;" width="100%" cellpadding="0" cellspacing="0" border="0">
    <tbody>
     <tr>
        <td valign="top">
        <div>
        <img alt="header"
                src="https://d3lzbbhyvqc4k.cloudfront.net/images/centax/centax-logo-1.png" style="max-width:205px;">
        </div> 
        
        <div style="margin-bottom:5px; font-size:12px; font-style:italic; padding-left:48px; color:#6c6c6c;"> <span> Centaxonline.com</span>: A Legal Research Platform on GST, Customs, Excise & Service Tax, Foreign Trade Policy </div> 
        

        </td>
     
    </tr>
    </tbody>
</table>`

export const letterfooterHtml = `
<table style="margin-top:20px; padding:15px 0px;" width="100%" cellpadding="0" cellspacing="0" border="0"> 
<tr> 
<td style="font-size:14px; text-align:left;"> Date : 19/10/2022 </td>
<td style="font-size:14px; text-align:left; padding-left:50px;"> Time : 2:30 PM </td>
<td style="font-size:14px; text-align:right;"> Page No. : 1 of 4 </td>
</tr>
</table>`

export enum PrintCommand {
	SAVE_HTML = 0,
	PRINT,
	SAVE_RTF,
	SAVE_DOC,
	SAVE_XLS,
	SAVE_PDF,
}

export const printPreviewCss = `
@media screen {

    .print
    {
    display:block;
    padding-right:20px;
    font-family: Verdana;
     font-size: 11pt;
    -webkit-font-size: 11pt;
    -o-font-size: 11pt;
     color: #000000;
    -webkit-color: #000000;
    -o-color: #000000;
     font-weight: bold;
    -webkit-font-weight: bold;
    -o-font-weight: bold;

    }
    }
    @media print {
      .print-header {
        display: block;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 25px;
        margin-top: 25px;
    }
    .print-header .print-tag-line {
      display: block;
      font-size: 12px;
      font-style: italic;
      padding-left: 36px;
      color: #6c6c6c;
      margin-top: 9px;
  }
      .print-file-header {
        border: 1px solid #000000;
        padding: 15px 25px;
        text-align: center;
        margin-bottom: 25px;
    }
    .row {
      display: flex;
      flex-wrap: wrap;    
      box-sizing: border-box;  
  }
    .col-lg-3 {
      width: 25%;
      padding:0.5rem 15px;
      box-sizing: border-box;
  }
  .col-lg-9 {
    width: 75%;
    padding:0.5rem 15px;
    box-sizing: border-box;
  }  
  .col-lg-12 {
    width: 100%;
    padding:0.5rem 15px;
    box-sizing: border-box;
  }
  .col-lg-6 {
    width: 50%;
    padding:0.5rem 15px;
    box-sizing: border-box;
  }  
      
    }`

export const printActsCss = `	.print-container .print-header {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 20px;
  }
  .print-container .print-header img {
      max-width: 205px;
    }
 .print-container .print-header .print-tag-line {
      display: block;
      font-size: 12px;
      font-style: italic;
      padding-left: 36px;
      color: #6c6c6c;
      margin-top: 9px;
    }
    .print-container .print-contents .print-file-header {
      border: 1px solid #000000;
      padding: 15px 25px;
      text-align: center;
      margin-bottom: 25px;
    }
    .print-container .print-contents .print-file-header .p-f-h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        margin-bottom: 0;
        margin-top: 0;
      }
      .print-container .print-contents .print-file-header .p-p1 {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        margin-bottom: 0;
        margin-top: 10px;
      }
      .print-container .print-contents .print-files {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      }
      .print-container .grid-hor-h1 {
        border-bottom: 1px solid #000000;
        font-weight: 700;
      }
      .print-container .grid-hor-1 {
        border-bottom: 1px dashed #000000;
      }
      .print-container .p-grid-colls {
        padding-top: 10px;
        clear: both;
      }
      .print-container .p-grid {
        margin: 0;
      }
      .print-container .grid-hor-1 {
        border-bottom: 1px dashed #000000;
      }
      .row {
        display: flex;
        flex-wrap: wrap;    
        box-sizing: border-box;  
    }
      .col-lg-3 {
        width: 25%;
        padding:0.5rem 15px;
        box-sizing: border-box;
    }
    .col-lg-9 {
      width: 75%;
      padding:0.5rem 15px;
      box-sizing: border-box;
    }  
    .col-lg-12 {
      width: 100%;
      padding:0.5rem 15px;
      box-sizing: border-box;
    }
    .col-lg-6 {
      width: 50%;
      padding:0.5rem 15px;
      box-sizing: border-box;
    }    
`

export const DeviceType = {
	MOBILE: 'MOBILE',
	TABLET: 'TABLET',
	DESKTOP: 'DESKTOP',
	ANDROID: 'ANDROID',
	MOBILE_WEB: 'MOBILE_WEB',
}

export const printCss = `        .print-files {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;            
  color: #000000;
  .grid-hor-h1 {
      border-bottom: 1px solid #000000;
      font-weight: 700;
  }
  .grid-hor-1 {
      border-bottom: 1px dashed #000000;
  }
  .p-grid-colls {
      padding-top: 10px;
     // padding-bottom: 10px;
      clear: both;
  }

  .p-grid {
      margin: 0;
  }
}`

export const DiscountType = {
	EXCUSDISCOUNT: 'discountFlag',
	TABLET: 'TABLET',
}

export const HeaderDropDownPageChange = {
	CENTAX_ONLINE: 'centaxonline',
	GST_EXCUS: 'gstexcus',
}

export enum UserAuthStatus {
	LOGGED_OUT = 1,
	NOT_SUBSCRIBED,
	INACTIVE_SESSION,
	OK,
	IP_NOT_REGISTERED,
	EXPIRED,
	AUTH_TOKEN_NOT_RECEIVED,
  API_EXCEED_ERROR
}
