import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
@Component({
	selector: 'centax-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
})
export class PopupComponent implements OnInit {
	@Input() header: string
	@Input() modalType: string = 'LARGE'
	@Input() showCross: boolean
	@Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>()
	@Input() modalClass: string = 'app-view-md'
	showModal = true
	constructor() {}

	ngOnInit() {
		if (!this.modalClass) {
			this.modalClass = ''
		}
	}

	ngAfterViewInit() {
		let element = document.querySelector('.app-modal')
		if (element && getComputedStyle(element).position != 'absolute') {
			document.body.classList.add('scroll-off')
		}
	}
	/**
	 * close popup event
	 * @param e
	 */

	onCloseClick(e) {
		console.log('emit click', e)
		this.showModal = false
		setTimeout(() => {
			this.onClose.emit(true)
		}, 300)
	}

	//   onActivate() {
	//     window.scroll(0,0);
	//     //or document.body.scrollTop = 0;
	//     //or document.querySelector('body').scrollTo(0,0)
	// }

	ngOnDestroy() {
		document.body.classList.remove('scroll-off')
	}
}
