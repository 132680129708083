import { Component, OnInit, EventEmitter, Output } from '@angular/core'
import { AppUtilityService } from '@Utils/app-utlility.service'

@Component({
	selector: 'centax-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
	details: any
	showHide: boolean = false

	@Output() accept = new EventEmitter()
	@Output() reject = new EventEmitter()

	constructor(public globalUtilityService: AppUtilityService) {}

	ngOnInit(): void {
		this.globalUtilityService.confirmationPopupDetails.subscribe((details: any) => {
			this.details = details
		})
		this.globalUtilityService._showConfirmPopup.subscribe((val) => {
			this.showHide = val
		})
	}

	closePopup(event, data?) {
		this.globalUtilityService.showConfirmPopup = event
		this.reject.emit({
			flag: true,
			data,
		})
	}

	onAccept() {
		this.accept.emit(true)
	}
}
