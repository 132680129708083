export const CentaxApiList = {
	LEFT_MENU: 'getLeftMenu',
	FILE_TEXT: 'getFileText',
	GET_ACT_LAWS: 'getLaws',
	LATEST_NEWS: 'getLatestNews',
	TOP_STORY_DETAILS: 'getTopStoryFileText',
	SEARCH_SUGGESTION: 'getCentaxSuggestion',
	RELATED_STORIES_DETAILS: 'getFileRelevantStories',
	FOOT_NOTE_CONTENT: 'getFileContentFootNote',
	ADD_TO_FAVOURITE: 'document/favourite',
	VIABILITY_DATA: 'viabilityData',
	GET_DOC_DETAILS: 'document/',
	ADD_BOOKMARK: 'document/bookmark',
	DELETE_ALL_BOOKMARKS: 'bookmarks/',
	GET_ALL_FOLDERS: 'folders',
	STICKY_NOTE_HANDLER: 'document/sticky_note',
	FOLDER_HANDLER: 'document/folder',
	GET_ALL_DOCUMENTS: 'documents',
	GET_FAVOURITE_DOCUMENT: 'favourite/documents',
	GET_STICKY_DOCUMENT: 'sticky/documents',
	GET_BOOKMARK_DOCUMENT: 'bookmarked/documents',
	GET_RESEARCH_BOX_CATEGORIES: 'categories',
	GET_RESEARCH_BOX_SUB_CATEGORIES: 'subCategories',
	DOWNLOAD_PDF: 'getFilehtmlTopdf',
	CRUD_FOLDER: 'folder',
	CLEAR_FOLDER: 'folder/documents',
	ADVANCE_SEARCH: 'getSearchResult',
	LOGOUT: 'logout',
	DOWNLOAD_RTF: 'centax/getfilehtmlrtf',
	BOOKMARK_HANDLER: 'document/bookmark',
	MOVE_DOCUMENT: 'moveDocument',
	COPY_DOCUMENT: 'copyDocument',
	UPLOAD_AND_SEARCH: 'uploadAndSearch',
	BREAKING_NEWS: 'getBreackingNews',
	VIABILITY_DATA_COUNT: 'viabilityDataCount/',
	SUBSEQUENT_DEVELOPMENT_DATA: 'SubsequentDevelopmentData/',
	DOCUMENT_STRIP: 'DocumentStrip/',
	ADVERTISEMENTS: 'Advertisements',
	GET_FAQS: 'GetAllFAQs',
	GET_CITIES: 'Cities/',
	GET_STATES: 'States',
	ETDS_TIME_SLOT: 'TimeSlots',
	ETDS_DEMO_REQUEST_SEND_OTP: 'RequestCallback/SendOtp',
	ETDS_DEMO_REQUEST_VERIFY_OTP: 'RequestCallback/VerifyOtp',
	REQUEST_CALL_BACK: 'RequestCallback',
	SAVE_UTM_DETAILS: 'taxmann/SaveUTMDetails',
	CHECK_ACTIVE_LOGIN_SESSION: 'check_active_login_session',
	LOGIN_IP_USER: 'ipUserLogin',
	GET_USER_IP: 'getClientIp',
	CHECK_ACTIVE_SESSION: 'check_active_session',
	GST_EXCUS_DOWNLOAD_LOG: 'GstExcusDownloadLog',
	VERIFY_EMAIL: 'verifyemail',
	USER_SITE_TRACKING: 'userSiteTracking',
	SEARCH_LOG: 'search/log',
	GST_EXCUS_URLs : 'GSTExcusURL',
	HTML_TO_PDF : 'getFilehtmlTopdf',
	VERIFY_EMAIL_PPC_USERS: 'verifyemail',
	REGISTER_PPC_USERS:'registerUserByPPC',
	ADD_ADDRESS_PPC_USERS:'postAddressPPC',
    //  Renewal Notification Alert
    RENEWAL_ALERT: 'notify/showRenewAlert',
    PUSH_RENEWAL_ALERT: 'notify/pushRenewAlert'

}

export const ResearchApiList = {
	SAVE_ERROR_LOG: 'research/saveerrorlog',
}

export const LoginApiList = {
	LOGIN: 'login',
	SEND_OTP: 'loginwithmobile',
	VERIFY_OTP: 'verifyotpmobileprimary',
	FORGOT_PASSWORD: 'resetPasswordSendRequest',
	CHECK_ACTIVE_SESSION: 'check_active_login_session',
	GET_MACHINE_ID: "machineId",
}

export const PricingApiList = {
	SINGLE_USER_PRICING: 'singleUserPricing',
	FREE_TRIAL: 'activateFreeTrial',
	ADD_MULTIPLE_PRODUCT_TO_CART: 'addMultipleProductToCart',
	ENTERPRISE_CONTACT: 'enterpriseContact',
	SEND_OTP : 'enterpriseContact/sendOtp',
	VERIFY_OTP : 'enterpriseContact/verifyOtp',
	EXTEND_FREE_TRIAL:'extendFreeTrial',
	CREATE_LEAD: 'createleadfromcentax',
}

export const StoreApiList = {
	CART_DETAILS: 'getCartDetails',
	SAVE_TEMP_ORDER: 'saveTempOrder',
	PAYMENT_METHOD: 'getPaymentModes',
	RP_ACESS_KEY: 'payment/getRpAccesskey/',
	POST_PRODUCT_ORDER: 'postProductOrder',
	INVOICE: 'payment/getinvoice/',
	SAVE_NEFT_ORDER: 'saveNeftChequeOrder',
}
