import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HeaderService } from '@Modules/header/services/header.service'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { APIResponse } from '@Interface/centax-data.interface'
import { ApiStatus } from '@Constants/centax-app.constant'
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http'
import { CommonAlertService } from '@Services/common-alert.service'

declare const $: any

@Component({
	selector: 'centax-upload-and-search',
	templateUrl: './upload-and-search.component.html',
	styleUrls: ['./upload-and-search.component.scss'],
})
export class UploadAndSearchComponent implements OnInit {
	@Output() closeSearchDocPopup = new EventEmitter()
	@Output() uploadedFileName = new EventEmitter()
	uploadPercentage: number = 30
	uploadingStage: string = '1'

	fileToUpload: any
	fileExtension: string = ''

	allowedExtensions: string[] = ['pdf', 'doc', 'docx']
	searchResults: any[] = []

	constructor(
		private router: Router,
		private headerService: HeaderService,
		private commonAlertService: CommonAlertService,
		private activatedRoute: ActivatedRoute,
		private appUtilityService: AppUtilityService
	) {}

	ngOnInit(): void {}

	onFileChange($fileEvent: any): void {
		if ($fileEvent?.target?.files?.length > 0) {
			this.fileToUpload = $fileEvent?.target?.files[0]
			this.fileExtension = this.fileToUpload.name.split('.')[1]
			if (!this.allowedExtensions.includes(this.fileExtension)) {
				this.commonAlertService.errorToast('File not supported')
				this.fileToUpload = ''
			} else {
				this.uploadFile()
			}
		}
	}

	uploadFile(): void {
		const formData: FormData = new FormData()
		const otherFields = {
			searchText: '',
			keyList: [],
			filters: {},
			page: 0,
			pageSize: 50,
			sortorder: 0,
		}
		formData.append('file', this.fileToUpload)
		formData.append('payload', JSON?.stringify(otherFields))
		this.headerService.uploadAndSearch(formData).subscribe((event) => {
			if (event.type === HttpEventType?.UploadProgress) {
				this.uploadingStage = '2'
				this.uploadPercentage = Math.round((100 * event.loaded) / event.total)
			} else if (event instanceof HttpResponse) {
				this.uploadingStage = '3'
				this.searchResults = event.body
			}
		})
	}

	loadResultsNow(): void {
		this.appUtilityService.setDataOnLocalStorage('fileName', this.fileToUpload?.name)
		this.router.navigate(['/search'], { queryParams: { documentSearch: this.fileToUpload?.name } })
		this.uploadedFileName.emit(this.fileToUpload.name)
		setTimeout(() => {
			this.appUtilityService.setFileUploadSearchResult({
				fileName: this.fileToUpload?.name,
				Data: this.searchResults,
			})
			this.closeSearchDocPopup.emit(false)
		}, 10)
	}

	resetSearch(): void {
		this.closeSearchDocPopup.emit(false)
		this.fileToUpload = ''
	}
}
