import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import {} from '@angular/common/http'
import { APIResponse } from '@App/interface/centax-data.interface'
import { catchError, map } from 'rxjs/operators'
import { AppUtilityService } from '@App/utility/app-utlility.service'
import { LocalStorageDataModel } from '@App/constant/localstorage.constant'
import { HttpErrorResponse } from '@angular/common/http'
import { ResearchApiList } from '@App/constant/centax-api.constant'
import { ApiStatus, CentaxAppApiConstant } from '@App/constant/centax-app.constant'
import { HttpCacheService } from './http-cache.service'
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@Env/environment'

export interface HttpRequestConfig {
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[]
		  }
	observe?: 'body'
	params?:
		| HttpParams
		| {
				[param: string]: string | string[]
		  }
	reportProgress?: boolean
	responseType?: 'json'
	withCredentials?: boolean
}
const ApiMethod = {
	GET: 'get',
	POST: 'post',
	PUT: 'put',
	PATCH: 'patch',
	DELETE: 'delete',
}
@Injectable()
export class HttpHelperService {
	fileId = ''
	payloadBody
	constructor(
		private _http: HttpClient,
		private appUtilityService: AppUtilityService,
		private httpCacheService: HttpCacheService
	) {}

	/*public request(method: string, url: string,data:any={},options?:any) {
		if(ApiMethod.GET == method){
			return this._http.get(url);
		}
		if(ApiMethod.POST == method){
			return this._http.post(url, data);
		}

	}

	public doRequest(apiUrl: string, method: string, body?: any, config?: HttpRequestConfig) {
		let response: Observable<any>;
		let reqObservable: Observable<any>;
		if (method === ApiMethod.GET || method === ApiMethod.DELETE) {
			if (!config && body) {
				config = {observe: body};
			}else if (config && body) {
				config.observe = body;
			}
		}
		switch (method) {
			case ApiMethod.GET:
				reqObservable = this._http.get(apiUrl, config);
				break;
			case ApiMethod.DELETE:
				reqObservable = this._http.delete(apiUrl, config);
				break;
			case ApiMethod.PATCH:
				reqObservable = this._http.patch(apiUrl, body, config);
				break;
			case ApiMethod.POST:
				reqObservable = this._http.post(apiUrl, body, config);
				break;
			case ApiMethod.PUT:
				reqObservable = this._http.put(apiUrl, body, config);
				break;
		}
		return response;
	}*/

	get(url: string, query: Object, requiresAuth = false, header?: any): Observable<APIResponse> {
		let cachedResponse = this.httpCacheService.getCachedItems(url)
		if (cachedResponse) {
			return of(cachedResponse.body)
		}
		let headers = this.addCustomHeaders(false, requiresAuth, url, header)
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = []
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`)
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = ''
		return this._http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	getData(url: string, query: Object, requiresAuth = false, header?: HttpHeaders): Observable<APIResponse> {
		let headers = this.addCustomHeaders(false, requiresAuth, url, header)
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = []
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`)
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = ''
		return this._http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map((res) => res.body),
			catchError(this.errorHandler.bind(this))
		)
	}

	post(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, url, header)
		// if (body['fileId']) {
		// 	this.fileId = body['fileId'];
		// } else {
		// 	this.fileId = '';
		// }
		this.payloadBody = body

		return this._http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	postWithQueryParam(url: string, body: any,query: Object, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, url, header)
		// if (body['fileId']) {
		// 	this.fileId = body['fileId'];
		// } else {
		// 	this.fileId = '';
		// }
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = []
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`)
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = body

		return this._http.post<APIResponse>(url, body, { headers, observe: 'response', ...query }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	postFile(
		url: string,
		body: any,
		requiresAuth = false,
		isUrlEncoded = false,
		header?: any,
		isFileUpload: boolean = false,
		observeResponse: boolean = true
	): Observable<any> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, url, header, isFileUpload)
		// if (body['fileId']) {
		// 	this.fileId = body['fileId'];
		// } else {
		// 	this.fileId = '';
		// }
		this.payloadBody = body
		const observe: any = observeResponse ? 'events' : 'response'

		return this._http.post<any>(url, body, { headers, reportProgress: isFileUpload, observe })
	}

	put(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, url, header)
		// if (body['fileId']) {
		// 	this.fileId = body['fileId'];
		// } else {
		// 	this.fileId = '';
		// }
		this.payloadBody = body

		return this._http.put<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	private addCustomHeaders(
		isUrlEncoded = false,
		requiresAuth = false,
		url:string,
		customHeaders?: any,
		isFileUpload?: boolean,
	): HttpHeaders {
		let headers: HttpHeaders
		if (isUrlEncoded) {
			headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded',
			})
		} else if (isFileUpload) {
			{
				headers = new HttpHeaders({
					'Access-Control-Allow-Origin': '*',
					appid: '2020',
				})
			}
		} else {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				appid: '2020',
			})
		}
		if (requiresAuth) {
			if (this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(
					LocalStorageDataModel.AUTH_TOKEN,
					this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
				)
			} else {
				headers = headers.append(
					LocalStorageDataModel.AUTH_TOKEN,
					this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
				)
			}
		}
		let machineId = '';
		if(url.indexOf(environment[CentaxAppApiConstant.DOWNLOAD_RTF_URL]) > -1 || url.indexOf(environment['api'][CentaxAppApiConstant.LDEV_SERVER]) > -1){
			machineId = environment[CentaxAppApiConstant.MACHINE_ID_RTF_DOWNLOAD];
		}else{
		 machineId = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW);
		}
		// if(machineId){}else{
			// generate machibe id and save ib local storage
        //   let uuid = uuidv4();
		//   uuid = uuid.replace(/-/g,"");
		//   machineId = uuid;
		//   this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		// }
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (customHeaders){
			Object.keys(customHeaders).forEach((key) => {
				headers = headers.set(key,customHeaders[key])
			})
		}
		return headers
	}

	postDownload(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any) {
		let headers: HttpHeaders = new HttpHeaders()
		if (requiresAuth) {
			if (this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(
					LocalStorageDataModel.AUTH_TOKEN,
					this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
				)
			} else {
				headers = headers.append(
					LocalStorageDataModel.AUTH_TOKEN,
					this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
				)
			}
		}
		let machineId = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW);
		// if(machineId){}else{
		// 	// generate machibe id and save ib local storage
        //   let uuid = uuidv4();
		//   uuid = uuid.replace(/-/g,"");
		//   machineId = uuid;
		//   this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		// }
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		this.payloadBody = body

		return this._http.post(url, body, { headers, responseType: 'blob' })
	}

	posttest(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		// let headers = new HttpHeaders({
		// 	'Content-Type': 'application/pdf',
		// })
		let headers = new HttpHeaders()
		headers = headers.append(
			'Authorization',
			'44633bea-ba42-44aa-8776-9be561f632e3'
		);
		let machineId = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW);
		// if(machineId){}else{
		// 	// generate machibe id and save ib local storage
        //   let uuid = uuidv4();
		//   uuid = uuid.replace(/-/g,"");
		//   machineId = uuid;
		//   this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		// }
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)

		this.payloadBody = body

		return this._http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	private checkResponse(response): APIResponse {
		if (response.body && response.body.Data && response.body.Data.login_token) {
			this.appUtilityService.setCookie(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token, 43200)
			this.appUtilityService.setDataOnLocalStorage(
				LocalStorageDataModel.AUTH_TOKEN,
				response.body.Data.login_token
			)
		}

		if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]) {
			this.appUtilityService.setDataOnLocalStorage(
				LocalStorageDataModel.CONTENT_TOKEN,
				response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]
			)
		}

		if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.I_READER_TOKEN]) {
			this.appUtilityService.setDataOnLocalStorage(
				LocalStorageDataModel.I_READER_TOKEN,
				response.body.Data[LocalStorageDataModel.I_READER_TOKEN]
			)
		}

		// if (response.body.ResponseType == ApiStatus.ERROR || response.body.ResponseType === ApiStatus.WARNING) {
		if (response.body.ResponseType == ApiStatus.USER_NOT_FOUND) {
			// this._commonAlertService.errorToast('This account does not exist. <a href="/auth/register">Create a new account<a>');
		} else if (response.body.ResponseType == ApiStatus.INCORRECT_PASSWORD) {
			// this._commonAlertService.errorToast('Wrong password. Try again or <a href="/auth/forgotPassword">Reset it now</a>');
		} else if (
			response.body.ResponseType == ApiStatus.ERROR ||
			response.body.ResponseType === ApiStatus.WARNING ||
			response.body.ResponseType === ApiStatus.INVALID_LOGIN
		) {
			// this._commonAlertService.errorToast(response.body.StatusMsg);
		}
		// else if(response.body.ResponseType == 'SOCIAL_LOGIN_NOT_FOUND'){
		//   this._commonAlertService.errorToast('This account does not exist. <a href="/auth/register">Create a new account<a>');
		// }else if(response.body.ResponseType == 'OTP_NOT_VERIFIED'){
		//   this._commonAlertService.errorToast(response.body.StatusMsg);
		// }
		// }
		return response.body
	}

	private errorHandler(error: HttpErrorResponse) {
		if (error.status == 301 || error.status == 404) {
			// this.createRedirectUrl()
			return
		}
		let errorMsg = ''
		if (error.error instanceof ErrorEvent)
			errorMsg = 'There is a problem with the service. We are notified & working.Please try again later.'
		else if (
			error.error &&
			error.error.StatusMsg &&
			error.error.ResponseType === ApiStatus.API_CALLING_LIMIT_EXCEED
		) {
			errorMsg = 'You have reached at max limit of API Hit'
		} else {
			errorMsg = 'There is some network issue. Please try again later.'
		}
		console.log('error received', error, this.payloadBody)
		if (error.status == 400) {
			if (error?.error?.StatusMsg) {
				errorMsg = error?.error?.StatusMsg
			}
			let url = this.appUtilityService.getApiUrl(ResearchApiList.SAVE_ERROR_LOG, 'local')
			let date = new Date()
			let data = {
				errortext:
					typeof error.error.StatusMsg === 'object'
						? JSON.stringify(error.error.StatusMsg)
						: error.error.StatusMsg,
				fileid: this.fileId,
				apiname: error.url,
				currenturl: location.href,
				APIResponse: JSON.stringify(error.error),
				payloadbody: JSON.stringify(this.payloadBody),
				apirequesttime: date.toString(),
			}
			this.post(url, data, false).subscribe()
		}
		// ap call exceed error
		if (error.status == 409) {
			if(this.appUtilityService.isLoggedIn()){
				if (this.appUtilityService.TimerSubscription) {
				}else{
			this.appUtilityService.errorAPILimitExceed$.next(
				{isAPILimitExceed: true, counter:30});
			}
		}
		}
		// this._commonAlertService.errorToast(errorMsg);
		// throw new Error(error.message);
		return of(error?.error)
	}

	private addSearchParams(customParam: Object) {
		let search: HttpParams = new HttpParams()
		for (const key in customParam) search = search.append(key, customParam[key])
		return search
	}

	delete(
		url: string,
		query: Object,
		isUrlEncoded = false,
		requiresAuth = false,
		header?: any
	): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, url, header)
		let searchParams = this.addSearchParams(query)
		this.payloadBody = ''
		return this._http.delete<APIResponse>(url, { headers, observe: 'response', params: searchParams }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	postWithoutErrorHandler(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, url, header)
		// if (body['fileId']) {
		// 	this.fileId = body['fileId'];
		// } else {
		// 	this.fileId = '';
		// }
		this.payloadBody = body

		return this._http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map((res) => this.checkResponse(res)),
		)
	}

}
