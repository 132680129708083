import { Injectable } from '@angular/core'
import { map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { DeviceDetectorService } from 'ngx-device-detector'
import { Location } from '@angular/common'
import { HttpHelperService } from '@App/services/http-helper.service'
import { LocalStorageDataModel } from '@App/constant/localstorage.constant'
import { CentaxApiList, LoginApiList } from '@App/constant/centax-api.constant'
import { AppUtilityService } from './app-utlility.service'
import { DeviceType } from '@App/constant/constant'
import { ApiStatus } from '@App/constant/centax-app.constant'

@Injectable()
export class StartupService {
	constructor(
		private router: Router,
		private globalUtility: AppUtilityService,
		private httpHelperService: HttpHelperService,
		private deviceService: DeviceDetectorService,
		private location: Location
	) {}
	/** This is the method you want to call at bootstrap
	 * Important: It should return a Promise
	 */

	public async load(): Promise<any> {
		if(await this.getMachineId()){
		if (await this.checkLoginSession()) {
			this.getUserIp()
			return Promise.resolve(true)
		}
	}
	}

	getUserIp() {
		let url = this.globalUtility.getApiUrl(CentaxApiList.GET_USER_IP)
		let doaminName = window.location.hostname
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_DOMAIN, doaminName)
		// let payload = {
		// 	'domainname': doaminName
		// }
		this.httpHelperService.get(url, {}, false).subscribe(
			(res) => {
				console.log(res)
				if (res.Data) {
					let ipAddress = res.Data['ipAddress']
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_IP, ipAddress)
					// this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.IS_PROXY_USER, res.Data[1]);
					if (!this.globalUtility.isLoggedIn()) {
						this.loginIpUser(ipAddress)
						//	this.loginIpUser("111.223.28.130");
					}
				}
			},
			(error) => {
				console.log('error in getting IP Address')
				console.log(error)
			}
		)
		// }
	}

	/**
	 * login ip user
	 * @param ip_address
	 */

	loginIpUser(ip_address: string) {
		let deviceType = this.deviceService.isDesktop()
			? DeviceType.DESKTOP
			: this.deviceService.isTablet()
			? DeviceType.TABLET
			: this.deviceService.isMobile()
			? DeviceType.MOBILE
			: ''
		const data = {
			ip_address: ip_address, //'121.242.160.162' -sample ip
			ipAddress: ip_address,
			userAgent: this.deviceService.getDeviceInfo()['userAgent'],
			device: this.deviceService.device,
			os: this.deviceService.os,
			osVersion: this.deviceService.os_version,
			browser: this.deviceService.browser,
			browserVersion: this.deviceService.browser_version,
			deviceType: deviceType,
		}

		let url = this.globalUtility.getApiUrl(CentaxApiList.LOGIN_IP_USER)
		this.httpHelperService.post(url, data, false).subscribe(
			(res) => {
				console.log(res)
				if (res && res.Data) {
					let data = res.Data
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_EMAIL, data.user_email)
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.EMAIL, data.user_email)
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_NAME, data.user_name)
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.ISIPUSER, 'true')
					this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, data?.login_token, 43200)
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, data?.login_token)
					this.globalUtility.checkForLogin.next(true)
				}
			},
			(error) => {
				console.log(error)
			}
		)
	}

	checkLoginSession() {
		if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			this.isloginSessionValid().subscribe((res: any) => {
				if (res.success == false && res.ResponseType == 'SESSION_EXPIRED') {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
					this.router.navigate(['/auth/login'])
				} else if (res.success == false && res.ResponseType == 'TOKEN_INVALID') {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
					this.router.navigate(['/auth/login'])
				}
				return Promise.resolve(true)
			})
		} else {
			return Promise.resolve(true)
		}
	}

	isloginSessionValid() {
		let url = this.globalUtility.getApiUrl(CentaxApiList.CHECK_ACTIVE_LOGIN_SESSION)
		return this.httpHelperService.post(url, {}, true)
	}

	getMachineId() {
		if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW)) {
			return Promise.resolve(true);
		} else {
		 this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.MACHINE_ID)
			let url = this.globalUtility.getApiUrl(LoginApiList.GET_MACHINE_ID);
		    return new Promise((resolve, reject) => {
				this.httpHelperService.get(url, {}, false)
					.subscribe((res:any) => {
						if(res && res.ResponseType && res.ResponseType == ApiStatus.SUCCESS){
						if (res.Data) {
							let decryptedID= this.globalUtility.decrypt(res.Data);
							if(decryptedID){
								decryptedID = JSON.parse(decryptedID)
								if(decryptedID && decryptedID.machineId){
							this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW, decryptedID.machineId);
							// kept old machine id too , as it can affect the otther apps like bookstore and students
							this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, decryptedID.machineId);
							resolve(res);
							return Promise.resolve(true);
								}else{
									reject()
									return Promise.resolve(false);	
								}
							}else{
								reject()
								return Promise.resolve(false);	
							}
						}else{
							reject()
							return Promise.resolve(false);
						}
					}else{
						reject()
						return Promise.resolve(false);
					}
						}, error => {
							reject()
							return Promise.resolve(true);
						});
					});
					}
			} 
}
