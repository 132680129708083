import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'centax-no-data-found',
	templateUrl: './no-data-found.component.html',
	styleUrls: ['./no-data-found.component.scss'],
})
export class NoDataFoundComponent implements OnInit {
	@Input() message: string = ''

	constructor() {}

	ngOnInit(): void {}
}
