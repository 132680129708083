import { Injectable } from '@angular/core'
import { HeaderDropDownPageChange } from '@App/constant/constant'
import { LocalStorageDataModel } from '@App/constant/localstorage.constant'
import { Subject, BehaviorSubject } from 'rxjs'
import { CentaxApiList } from '../constant/centax-api.constant'
import { AppUtilityService } from '../utility/app-utlility.service'
import { HttpHelperService } from './http-helper.service'
import { Router } from "@angular/router";
import { IUTMDataProps } from "@Interface/centax-data.interface";
import { DeviceDetailsService } from './device-details.service'

@Injectable({
	providedIn: 'root',
})
export class HomeService {
	public onTaxmannLogoVisible = new BehaviorSubject<boolean>(false)
	public showSidebarPage = new Subject<boolean>()
	public showHeader = new Subject<boolean>()
	public sideBarCollapse = new BehaviorSubject<boolean>(false)
	searchTextForCurrentPage = new BehaviorSubject<string>('')
	public showFooter = new BehaviorSubject<boolean>(true)
	public showPricing = new Subject<boolean>()
	public pagechangeValue = new BehaviorSubject<string>(HeaderDropDownPageChange.CENTAX_ONLINE)
	constructor(private httpService: HttpHelperService, private _appUtilityService: AppUtilityService, private router: Router,
		private deviceDetailsService: DeviceDetailsService) {
		this.searchTextForCurrentPage.subscribe((searchText) => {
			this._appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.SEARCH_TEXT, searchText || '')
		})
		// this.intializeScript('razorpayScript', "https://checkout.razorpay.com/v1/checkout.js");
	}

	ngDoCheck() {
		if (this._appUtilityService.isMobileView()) {
			this.sideBarCollapse.next(true)
		} else {
			this.sideBarCollapse.next(false)
		}
	}

	public getLeftMenuData() {
		let url = this._appUtilityService.getApiUrl(CentaxApiList.LEFT_MENU)
		return this.httpService.get(url, {})
	}

	public getSearchSuggestions(query: string) {
		let url = this._appUtilityService.getApiUrl(CentaxApiList.SEARCH_SUGGESTION)
		return this.httpService.post(url, { query })
	}

	public getAdvertisements(data) {
		let url = this._appUtilityService.getApiUrl(CentaxApiList.ADVERTISEMENTS, 'centaxAdmin')
		return this.httpService.post(url, data)
	}

	// 	/**
	//  * initialize script via script name and id
	//  * @param scriptId
	//  * @param srciptUrl
	//  */

	// 	 intializeScript(scriptId, srciptUrl) {
	// 		let js;
	// 		const fjs = document.getElementsByTagName('script')[0];
	// 		if (document.getElementById(scriptId)) {
	// 			return;
	// 		}
	// 		js = document.createElement('script');
	// 		js.id = scriptId;
	// 		js.async = true;
	// 		js.src = srciptUrl;
	// 		fjs.parentNode.insertBefore(js, fjs);
	// 	}

	checkActiveSession(data) {
		const headers = {}
		let content_token = this._appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN)
		if (content_token) {
			headers[LocalStorageDataModel.CONTENT_TOKEN] = content_token
		}
		data['ipAddress'] = this._appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_IP)
		let url = this._appUtilityService.getApiUrl(CentaxApiList.CHECK_ACTIVE_SESSION)
		return this.httpService.postWithoutErrorHandler(url, data, true, false, headers)
	}

	pushUtmSourceInformation(description: string, deleteCampaignAfterPush: boolean = false, recordType = 'GENERAL', data: any = {}, actionType = 'GENERAL'): void {
		let campaignName = this._appUtilityService.getDataFromLocalStorage('activeCampaign');
		if (!campaignName) {
			return
		}
		let userIp = this._appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
		let campaignUrl = this._appUtilityService.getDataFromLocalStorage('activeCampaignUrl');
		let payload = {
			IpAddress: userIp,
			Action: description,
			CampaignName: campaignName,
			UtmDetails: JSON.parse(this._appUtilityService.getDataFromLocalStorage(campaignName)),
			ComponentUrl: this.router.url,
			CampaignUrl: campaignUrl,
			platform: 'centax',
			recordType: recordType, // GENERAL, CRM,
			actionType: actionType, // GENERAL, FREE_TRIAL, REQUEST_CALLBACK,
			data: data, // can be as per IUTMDataProps keys
			uuid: this._appUtilityService.getDataFromLocalStorage('utm_campaign_uuid'),
			UserEmail: this._appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_EMAIL),
			UserName: this._appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME),
		}
		let isLoggedIn = this._appUtilityService.isLoggedIn();
		let url = isLoggedIn ? 'centaxtracking/userActionTracking' : 'centaxtracking/userActionTracking?verifyToken=NO';
		this.httpService.post(this._appUtilityService.makeMarketingUrl(url), payload, isLoggedIn).subscribe({
			next: () => {
				if (deleteCampaignAfterPush) {
					this._appUtilityService.removeFromLocalStorage('activeCampaign');
					this._appUtilityService.removeFromLocalStorage(campaignName);
					this._appUtilityService.removeFromLocalStorage('utm_campaign_uuid')
				}
			}
		});
	}

	removeCartInfoFromLocalStorage() {
		this._appUtilityService.removeFromLocalStorage(LocalStorageDataModel.CART_INFO);

	}

	userSiteTracking(componentURL) {
		let payload = { 'TrackingList': [] };
		let data;
		data = this.deviceDetailsService.getUserDeviceDetails();
		data['componentURL'] = componentURL;
		payload['TrackingList'].push(data);
		let url = this._appUtilityService.getApiUrl(CentaxApiList.USER_SITE_TRACKING);
		return this.httpService.post(url, payload, true)
	}

	searchHistoryLog(isGlobal: boolean, searchKeyword, componentURL) {
		let data = {};
		data['isGlobal'] = isGlobal
		data['searchKeyword'] = searchKeyword
		data['componentURL'] = componentURL;
		let verifyToken: boolean = false
		if (this._appUtilityService.isLoggedIn()) {
			verifyToken = true;
		}
		data['verifyToken'] = verifyToken ? 'Yes' : 'No'
		let url = this._appUtilityService.getApiUrl(CentaxApiList.SEARCH_LOG);
		return this.httpService.post(url, data, verifyToken);
	}

	getUserIp() {
		let url = this._appUtilityService.getApiUrl(CentaxApiList.GET_USER_IP)
		let doaminName = window.location.hostname
		this._appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.USER_DOMAIN, doaminName)
		this.httpService.get(url, {}, false).subscribe({
			next: (res) => {
				console.log(res)
				if (res.Data) {
					let ipAddress = res.Data['ipAddress']
					this._appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.USER_IP, ipAddress)
				}
			},
			error: (error) => {
				console.log('error in getting IP Address')
				console.log(error)
			}
		}

		)
	}

}
