import { Component, OnInit } from '@angular/core'
import { HomeService } from '@Services/home.service'
import { Router } from '@angular/router'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { SidebarComponent } from '@Modules/sidebar/sidebar.component'
import { Subscription } from 'rxjs'
import { SubSink } from 'subsink'

@Component({
	selector: 'centax-mobile-sidebar',
	templateUrl: './mobile-sidebar.component.html',
	styleUrls: ['./mobile-sidebar.component.scss'],
})
export class MobileSidebarComponent extends SidebarComponent {
	sideBarBtn: boolean = false
	showFilterIcon = false
	showMobileFilters = false

	mobileFilterShow$: Subscription = new Subscription()
	protected subs = new SubSink()

	constructor(
		protected homeService: HomeService,
		protected router: Router,
		protected appUtilityService: AppUtilityService
	) {
		super(appUtilityService, router, homeService)
		this.initSubs()
	}

	initSubs(): void {
		if (this.isMobileView) {
			this.showFilterIcon = true
		}
		this.subs.add(
			(this.mobileFilterShow$ = this.appUtilityService.getShowMobileFilterIcon().subscribe((val) => {
				this.showFilterIcon = val
			}))
		)
		this.subs.add(
			this.appUtilityService.getShowFilter().subscribe((val) => {
				this.showMobileFilters = val
			})
		)
	}

	sidebarClick() {
		this.sideBarBtn = !this.sideBarBtn
		this.homeService.showSidebarPage.next(this.sideBarBtn)
	}

	showFilters() {
		this.appUtilityService.setShowFilter(!this.showMobileFilters)
		if (this.isMobileView) {
			this.homeService.sideBarCollapse.next(false)
		}
	}

	ngOnDestroy() {
		this.mobileFilterShow$.unsubscribe()
	}
}
