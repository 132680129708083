import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CentaxApiList } from '@App/constant/centax-api.constant'
import { LocalStorageDataModel } from '@App/constant/localstorage.constant'
import { HomeService } from '@App/services/home.service'
import { HttpHelperService } from '@App/services/http-helper.service'
import { AppUtilityService } from '@App/utility/app-utlility.service'
import { BehaviorSubject, of, Subject } from 'rxjs'
import { v4 as uuidv4 } from 'uuid';

interface TabObjectSchema {
	fileId: any
	categoryName: string
	results?: any
	fileOpenSource?: number
	tabFileType?: string
	subCategory?: string,
	openType?:number
	tabIndex?:number,
	prevStackData?:any[]
}
@Injectable({
	providedIn: 'root',
})
export class RightPanelService {
	public hidePanelIfContentOpen = new Subject()
	public resetTabList = new Subject()
	private newContentTab$ = new Subject<TabObjectSchema>()
	private newUploadSearchContentTab$ = new BehaviorSubject<any>({})
	onTabClose = new Subject()
	isPhraseSubjectSearch: boolean = false
	phraseSubjectFilterText = [];
	currentStackData;
	constructor(private httpHelperService: HttpHelperService, private appUtilityService: AppUtilityService, private activatedRoute: ActivatedRoute,
		private homeService: HomeService, private http: HttpClient) { }

	getFileContents(fileId, searchText?: string, excusSearch?:string) {

		let data = {
			fileID: fileId,
			catName: '',
			isExcus: false,
			searchText: searchText,
		};
		let pattern = / - /;
		let SearchTextNew = '';
		if(searchText){
		let matches = searchText.match(pattern);
		if(matches){
				let splitHeaderSearchText = searchText.split(' - ');
				SearchTextNew = splitHeaderSearchText[0];
		}else{
			SearchTextNew = data['searchText'];
		}
		data['searchText'] = SearchTextNew
	}
	if(this.appUtilityService.subjectLabelArr && this.appUtilityService.subjectLabelArr.length){
		data['subjectText'] =  this.appUtilityService.subjectLabelArr.join(' | ');
		if(this.appUtilityService.isAdvSearch){
			data['subjectType'] = 'a'
		}else{
			data['subjectType'] = 'p'
		}
	}
		// if (this.isPhraseSubjectSearch) {
		// 	// this.phraseSubjectFilterText = this.appUtilityService.subjectLabelArr
		// }

		if (this.exactPhraseFilterText && this.exactPhraseFilterText.length ) {
			// this.phraseSubjectFilterText.push(this.exactPhraseFilterText)
			data['searchText'] = data['searchText']
				? data['searchText'] + ' | ' + this.exactPhraseFilterText
				: this.exactPhraseFilterText
		}
		/**
		 * Need to discuss with shivani mam why below line was added on 23-01-2023
		 */
			// this.appUtilityService.searchHeaderText['searchText'] = '';
			if(this.appUtilityService.isExcusSearch == true){
			data['isExcus'] = true;
		}else{
			if( this.appUtilityService.searchHeaderText['searchText'] && this.appUtilityService.searchHeaderText['searchText'].trim().length > 0 ){
				data['isExcus'] = false;
			}else{
				if((this.appUtilityService.subjectLabelArr && this.appUtilityService.subjectLabelArr.length > 0)){
					if(this.appUtilityService.isAdvSearch){
						data['isExcus'] = false;
					}else{
					data['isExcus'] = true;
					}
				}else{
					data['isExcus'] = false;
				}
			}	
			} 

			if (this.appUtilityService.searchHeaderText['searchText'] && this.appUtilityService.searchHeaderText['searchText'].length ) {
				let headerSearchText = '';
				let matches = this.appUtilityService.searchHeaderText['searchText'].match(pattern);
				if(matches){
						let splitHeaderSearchText = this.appUtilityService.searchHeaderText['searchText'].split( '- ');
						headerSearchText = splitHeaderSearchText[0];
				}else{
					headerSearchText = this.appUtilityService.searchHeaderText['searchText'];
				}
				data['searchText'] = data['searchText']
			? data['searchText'] + ' | ' + headerSearchText
			: headerSearchText
			}

		let url = this.appUtilityService.getApiUrl(CentaxApiList.FILE_TEXT);
		let path = window.location.href;
		if(data['searchText']){
			let isGlobal: boolean = false;
			if(this.appUtilityService.searchHeaderText['isGlobal']){
				isGlobal =true;
			}
			
		this.homeService.searchHistoryLog(isGlobal,data['searchText'],path).subscribe();
		}
		if(excusSearch == '1'){
			data['isExcus'] = true;
		}
		path = path + '/' + fileId
		this.homeService.userSiteTracking(path).subscribe();
		return this.httpHelperService.post(url, data, true)
	}

	getRelatedStoriesData(fileId, docType, catUrl) {
		let data = {
			fileID: fileId,
			docType: docType || undefined,
			catUrl: catUrl,
		}
		let url = this.appUtilityService.getApiUrl(CentaxApiList.RELATED_STORIES_DETAILS)
		return this.httpHelperService.post(url, data, true)
	}

	getFootNoteContent(data: any) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.FOOT_NOTE_CONTENT)
		return this.httpHelperService.post(url, data, true)
	}

	addToFav(data: any) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.ADD_TO_FAVOURITE)
		return this.httpHelperService.post(url, data, true)
	}
	getNewTabContent() {
		return this.newContentTab$.asObservable()
	}

	setNewTabContent(data: TabObjectSchema) {
		return this.newContentTab$.next(data)
	}
	getNewUploadSearchContentTab() {
		return this.newUploadSearchContentTab$.asObservable()
	}

	setNewUploadSearchContentTab(data: TabObjectSchema) {
		return this.newUploadSearchContentTab$.next(data)
	}

	getDocumentDetails(fileID) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.GET_DOC_DETAILS)
		url += fileID
		return this.httpHelperService.get(url, {}, true)
	}

	getAllFolders(data) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.GET_ALL_FOLDERS)
		return this.httpHelperService.post(url, data, true)
	}

	getFileViabilityData(fileId: string) {
		const url = this.appUtilityService.getApiUrl(CentaxApiList.VIABILITY_DATA) + '/' + fileId
		return this.httpHelperService.get(url, {}, true)
	}

	// downloadDocPdf(data) {
	// 	let url = this.appUtilityService.getApiUrl(CentaxApiList.DOWNLOAD_PDF)
	// 	return this.httpHelperService.postDownload(url, data, true)
	// }

	// downloadDocPdftest(data) {
	// 	let url = 'https://v2.api2pdf.com/wkhtml/pdf/html'
	// 	return this.httpHelperService.posttest(url, data, true)
	// }

	downloadDocPdf(data) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.HTML_TO_PDF, 'donwloadpdf');
		return this.httpHelperService.post(url, data, true)
	  }
	

	getTopStoriesDetails(id: any, categoryname: string = '') {
		let payload = { fileID: id, catName: '' }
		let url = this.appUtilityService.getApiUrl(CentaxApiList.TOP_STORY_DETAILS)
		return this.httpHelperService.post(url, payload, true)
	}

	downloadDocRtf(data) {
		let url = this.appUtilityService.getApiUrl(CentaxApiList.DOWNLOAD_RTF, 'donwloadrtf');
		return this.httpHelperService.post(url, data, true)
	}

	getFileViabilityDataCount(fileId) {
		const url = this.appUtilityService.getApiUrl(CentaxApiList.VIABILITY_DATA_COUNT) + fileId
		return this.httpHelperService.get(url, {}, true)
	}

	getSubsequentDevelopmentData(fileId: any) {
		const url = this.appUtilityService.getApiUrl(CentaxApiList.SUBSEQUENT_DEVELOPMENT_DATA) + fileId
		return this.httpHelperService.get(url, {}, true)
	}

	get exactPhraseFilterText() {
		return this.appUtilityService.exactPhraseFilterText
	}

	getDocumentStrip(fileId) {
		const url = this.appUtilityService.getApiUrl(CentaxApiList.DOCUMENT_STRIP) + fileId
		return this.httpHelperService.get(url, {}, true)
	}

	verifyApiToken(token) {
		let url = this.appUtilityService.getApiUrl('ApiUserLogin');
		let payload = {
		  "ipAddress": "103.206.13.130",
		  "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
		  "device": "Unknown",
		  "os": "Windows",
		  "osVersion": "windows-10",
		  "browser": "Chrome",
		  "browserVersion": "102.0.0.0",
		  "deviceType": "DESKTOP"
		}
		let headers = new HttpHeaders({
			centaxauthorization : `Bearer${token}`
		});
		let machineId = this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID_NEW);
		// if(machineId){}else{
		// 	// generate machibe id and save ib local storage
        //   let uuid = uuidv4();
		//   uuid = uuid.replace(/-/g,"");
		//   machineId = uuid;
		//   this.appUtilityService.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		// }
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)

		return this.http.post(url, payload, {
		  headers: headers
		});
	
	}

	trackDocumentPrint(data:Object){
        let url = this.appUtilityService.getApiUrl(CentaxApiList.TRACK_DOCUMENT_PRINT, 'donwloadpdf' );
        return this.httpHelperService.post(url, data, true);
	    }

}
