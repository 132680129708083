import { Injectable } from '@angular/core'
import { HttpHelperService } from '@Services/http-helper.service'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { Observable } from 'rxjs'
import { constants } from '@Constants/constant'
import { CentaxApiList, LoginApiList } from '@Constants/centax-api.constant'
import { LocalStorageDataModel } from '@Constants/localstorage.constant'
import { HomeService } from '@Services/home.service'
import { CentaxSocialAuthService } from '@Utils/centax-social-auth.service'
import { Router } from '@angular/router'

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private httpHelperService: HttpHelperService,
		private homeService: HomeService,
		private centaxSocialAuthService: CentaxSocialAuthService,
		private router: Router,
		private appUtility: AppUtilityService
	) {}

	sendResetPasswordLink(formData: any): Observable<any> {
		const url = this.appUtility.getApiUrl(LoginApiList.FORGOT_PASSWORD)
		return this.httpHelperService.post(url, formData, false)
	}

	logOut(): Observable<any> {
		const headers = {}
		let content_token = this.appUtility.getDataFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN)
		if (content_token) {
			headers[LocalStorageDataModel.CONTENT_TOKEN] = content_token
		}
		const url = this.appUtility.getApiUrl(CentaxApiList.LOGOUT)
		return this.httpHelperService.post(url, {}, true, false, headers)
	}

	logout(): void {
		this.router.navigate(['/auth/login'])
		this.homeService.showHeader.next(false)
		this.homeService.showSidebarPage.next(false)
		// this.centaxSocialAuthService.signOut()
		this.appUtility.socialLogout()
		this.logOut().subscribe((resp) => {})
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
		this.appUtility.setDataOnSessionStorage('tabList', '[]')
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.USER_EMAIL);
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.USER_NAME);
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN);
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.USER_LNAME);
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.USER_MOBILE);
		this.appUtility.removeFromLocalStorage(LocalStorageDataModel.NEWSLETTER_CONSENT_FLAG)
		this.appUtility.removeFromSessionStorage(LocalStorageDataModel.DISPLAY_CONSENT_FORM)
	}
}
