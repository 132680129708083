import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from '@Guards/auth.guard'
import { AboutUsComponent } from '@App/miscellaneous/about-us/about-us.component'
import { CopyrightPolicyComponent } from '@App/miscellaneous/copyright-policy/copyright-policy.component'
import { DisclaimerComponent } from '@App/miscellaneous/disclaimer/disclaimer.component'
import { PrivacyPolicyComponent } from '@App/miscellaneous/privacy-policy/privacy-policy.component'
import { ReturnPolicyComponent } from '@App/miscellaneous/return-policy/return-policy.component'
import { ShippingPolicyComponent } from '@App/miscellaneous/shipping-policy/shipping-policy.component'
import { TermsAndConditionsComponent } from '@App/miscellaneous/terms-and-conditions/terms-and-conditions.component'
import { ContactUsComponent } from '@App/miscellaneous/contact-us/contact-us.component'
import { MetaInfo } from './constant/meta-info.constant'
import { RedirectGuard } from './guards/redirect.guard'

const routes: Routes = [
	{
		path: 'latest-news-updates',
		loadChildren: () => import('@Modules/latest-stories/latest-stories.module').then((m) => m.LatestStoriesModule),
	},
	{
		path: 'gst-excus',
		loadChildren: () => import('./modules/gstexcus/gstexcus.module').then((m) => m.GstexcusModule),
		data: {
			seo: MetaInfo.GST_EXCUS,
			// ,
			// hasParams:true,
			// seoDeciderParamsName:'groupName',
			// map: {
			// 	'all':'ALL',
			// 	'gst':'GST',
			// 	'custom':'CUSTOMS',
			// 	'exciseservicetax':'EXCISE',
			// 	'archives':'ARCHIVES'
			// }
		},
	},
	{
		path: 'home',
		loadChildren: () => import('./modules/home-page/home-page.module').then((m) => m.HomePageModule),
	},
	// {
	// 	path: 'ppc',
	// 	loadChildren: () => import('./modules/home-page-ppc/home-page-ppc.module').then((m) => m.HomePagePPCModule),
	// },
	// {
	// 	path: 'ppc/thank-you',
	// 	loadChildren: () => import('./modules/home-page-ppc/home-page-ppc.module').then((m) => m.HomePagePPCModule),
	// },
	{
		path: '',
		redirectTo: 'latest-news-updates',
		pathMatch: 'full',
	},
	{
		path: 'page-not-found',
		loadChildren: () => import('@Modules/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
	},
	{
		path: 'search',
		loadChildren: () =>
			import('@App/modules/global-search/global-search.module').then((m) => m.GstGlobalSearchModule),
	},
	{
		path: 'preview-document',
		loadChildren: () =>
			import('./modules/right-panel/preview-file/preview-file.module').then((m) => m.PreviewFileModule),
	},
	{
		path: 'api-preview-document',
		loadChildren: () =>
			import('./modules/right-panel/api-preview-file/api-preview-file.module').then((m) => m.APIPreviewFileModule),
	},
	{
		path: 'pricing',
		loadChildren: () => import('./pricing/pricing.module').then((m) => m.PricingModule),
		data: {
			seo: MetaInfo.PRICING,
		},
	},
	{
		path: 'mail',
		loadChildren: () => import('./mail/mail.module').then((m) => m.MailModule),
		data: {
		},	
	},
	{
		path: ':category/case-laws',
		loadChildren: () =>
			import('@Modules/component-modules/case-laws/case-laws.module').then((m) => m.CaseLawsModule),
		data: {
			seo: MetaInfo.CASE_LAWS,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/caselaws',
		redirectTo: ':category/case-laws',
	},
	{
		path: ':category/acts',
		loadChildren: () => import('@Modules/component-modules/acts/acts.module').then((m) => m.ActsModule),
		data: {
			seo: MetaInfo.ACT,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		 canActivate: [RedirectGuard],
	},
	{
		path: ':category/articles',
		loadChildren: () => import('@Modules/component-modules/articles/articles.module').then((m) => m.ArticlesModule),
		data: {
			seo: MetaInfo.ARTICLES,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/circulars',
		loadChildren: () =>
			import('@Modules/component-modules/circulars/circulars.module').then((m) => m.CircularsModule),
		data: {
			seo: MetaInfo.CIRCULAR,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/commentaries',
		loadChildren: () =>
			import('@Modules/component-modules/commentaries/commentaries.module').then((m) => m.CommentariesModule),
		data: {
			seo: MetaInfo.COMMENTARIES,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/tariff',
		loadChildren: () => import('@Modules/component-modules/tariff/tariff.module').then((m) => m.TariffModule),
		data: {
			seo: MetaInfo.TARIFF,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/ftp',
		loadChildren: () => import('@Modules/component-modules/foreign-trade-policy/foreign-trade-policy.module').then((m) => m.ForeignTradePolicyModule),
		data: {
			seo: MetaInfo.FTP,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/foreign-trade-policy',
		redirectTo: ':category/ftp'
	},
	{
		path: ':category/handbook-of-procedures',
		loadChildren: () => import('@Modules/component-modules/handbook-procedures/handbook-procedures.module').then((m) => m.HandbookProceduresModule),
		data: {
			seo: MetaInfo.HANDBOOK_PROCEDURE,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreign-trade-policy': 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/handbook-procedure',
		redirectTo: ':category/handbook-of-procedures',
	},
	{
		path: ':category/aayat-niryat-forms',
		loadChildren: () => import('@Modules/component-modules/aayat-niryat-forms/aayat-niryat-forms.module').then((m) => m.AayatNiryatFormsModule),
		data: {
			seo: MetaInfo.AAYAT_NIRYAT_FORMS,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreign-trade-policy': 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/appendices',
		loadChildren: () => import('@Modules/component-modules/appendices/appendices.module').then((m) => m.AppendicesModule),
		data: {
			seo: MetaInfo.APPENDICES,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/gstn-updates',
		loadChildren: () => import('@Modules/component-modules/gstn-updates/gstn-updates.module').then((m) => m.GstnUpdatesModule),
		data: {
			seo: MetaInfo.GSTN,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/state-ut',
		loadChildren: () => import('@Modules/component-modules/stateut/stateut.module').then((m) => m.StateutModule),
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/council-meeting',
		loadChildren: () =>
			import('@Modules/component-modules/council-meeting/council-meeting.module').then(
				(m) => m.CouncilMeetingModule
			),
		data: {
			seo: MetaInfo.GST_COUNCIL_MEETING,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/council-meetings',
		redirectTo: ':category/council-meeting',
	},
	{
		path: ':category/faqs',
		loadChildren: () => import('@Modules/component-modules/faqs/faqs.module').then((m) => m.FaqsModule),
		data: {
			seo: MetaInfo.FAQ,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/finance-bill',
		loadChildren: () =>
			import('@Modules/component-modules/finance-bills/finance-bills.module').then((m) => m.FinanceBillsModule),
		data: {
			seo: MetaInfo.FINANCE_BILL,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/finance-act',
		loadChildren: () =>
			import('@Modules/component-modules/finance-acts/finance-acts.module').then((m) => m.FinanceActsModule),
		data: {
			seo: MetaInfo.FINANCE_ACTS,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/finance-bills',
		redirectTo: ':category/finance-bill'
	},
	{
		path: ':category/finance-acts',
		redirectTo: ':category/finance-act'
	},
	{
		path: ':category/notifications',
		loadChildren: () =>
			import('@Modules/component-modules/notifications/notifications.module').then((m) => m.NotificationsModule),
		data: {
			seo: MetaInfo.NOTIFICATION,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/rules',
		loadChildren: () => import('@Modules/component-modules/rules/rules.module').then((m) => m.RulesModule),
		data: {
			seo: MetaInfo.RULE,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				'foreigntradepolicy' : 'FTP',
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
				'foreign-trade-policy': 'FTP',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: ':category/forms',
		loadChildren: () => import('@Modules/component-modules/forms/forms.module').then((m) => m.TxFormsModule),
		data: {
			seo: MetaInfo.FORMS,
			hasParams: true,
			seoDeciderParamsName: 'category',
			map: {
				gst: 'GST',
				customs: 'CUSTOMS',
				'excise-service-tax': 'EXCISE_SERVICE_TAX',
			},
		},
		canActivate: [RedirectGuard],
	},
	{
		path: 'case-laws',
		loadChildren: () =>
			import('@Modules/component-modules/all-case-laws/all-case-laws.module').then((m) => m.AllCaseLawsModule),
		data: {
			seo: MetaInfo.ALL_CASE_LAWS,
		},
	},
	{
		path: 'caselaws',
		redirectTo: 'case-laws',
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		// canActivate: [ThemeGuard],
	},
	{
		path: 'user',
		canActivate: [AuthGuard],
		loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
	},
	{
		path: 'research',
		loadChildren: () => import('./modules/research-box/research-box.module').then((m) => m.ResearchBoxModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'store',
		loadChildren: () => import('./modules/store/store.module').then((m) => m.StoreModule),
	},
	{
		path: 'about-us',
		component: AboutUsComponent,
		data: {
			seo: MetaInfo.ABOUT_US,
		},
	},
	{
		path: 'copyrights-policy',
		component: CopyrightPolicyComponent,
		data: {
			seo: MetaInfo.LEGAL.COPYRIGHTS_POLICY,
		},
	},
	{
		path: 'disclaimer',
		component: DisclaimerComponent,
		data: {
			seo: MetaInfo.LEGAL.DISCLAIMER,
		},
	},
	{
		path: 'privacy-policy',
		component: PrivacyPolicyComponent,
		data: {
			seo: MetaInfo.LEGAL.PRIVACY_POLICY,
		},
	},
	{
		path: 'return-policy',
		component: ReturnPolicyComponent,
		data: {
			seo: MetaInfo.LEGAL.RETURN_POLICY,
		},
	},
	{
		path: 'shipping-policy',
		component: ShippingPolicyComponent,
		data: {
			seo: MetaInfo.LEGAL.SHIPPING_POLICY,
		},
	},
	{
		path: 'contact-us',
		component: ContactUsComponent,
		data: {
			seo: MetaInfo.CONTACT_US,
		},
	},
	{
		path: 'terms-conditions',
		component: TermsAndConditionsComponent,
		data: {
			seo: MetaInfo.LEGAL.TERMS_AND_CONDITIONS,
		},
	},
	{
		path: 'dealer',
		loadChildren: () => import('./miscellaneous/dealer/dealer.module').then((m) => m.DealerModule),
	},
	{
		path: 'payments',
		loadChildren: () => new Promise( () => { if(window.location.href.match(/payments/) ) window.location.href = 'https://old.centaxonline.com/payments/'; } ) 
	  },
	{
		path: '**',
		redirectTo: '/page-not-found',
	},
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
