import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HeaderComponent } from './header.component'
import { ListboxModule } from 'primeng/listbox'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DividerModule } from 'primeng/divider'
import { DialogModule } from 'primeng/dialog'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { DropdownModule } from 'primeng/dropdown'
import { MenuModule } from 'primeng/menu'
import { RippleModule } from 'primeng/ripple'
import { MobileHeaderComponent } from './mobile-header/mobile-header.component'
import { SidebarModule } from 'primeng/sidebar'
import { ClickOutsideModule } from 'ng-click-outside'
import { CentaxSidebarModule } from '@Modules/sidebar/sidebar.module'
import { CalendarModule } from 'primeng/calendar'
import { MultiSelectModule } from 'primeng/multiselect'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ChipsModule } from 'primeng/chips'
import { CentaxSharedModule } from '@App/shared/shared.module'
import { UploadAndSearchComponent } from './upload-and-search/upload-and-search.component'
import { ProgressBarModule } from 'primeng/progressbar'
import { TooltipModule } from 'primeng/tooltip'
import { JoyrideModule } from 'ngx-joyride'
import { PricingService } from '@App/pricing/pricing.service'

@NgModule({
	declarations: [HeaderComponent, MobileHeaderComponent, UploadAndSearchComponent],
	imports: [
		CommonModule,
		ListboxModule,
		FormsModule,
		ReactiveFormsModule,
		DividerModule,
		DialogModule,
		ButtonModule,
		InputTextModule,
		DropdownModule,
		MenuModule,
		RippleModule,
		SidebarModule,
		ClickOutsideModule,
		CentaxSidebarModule,
		CalendarModule,
		MultiSelectModule,
		AutoCompleteModule,
		FormsModule,
		ChipsModule,
		CentaxSharedModule,
		ProgressBarModule,
		TooltipModule,
		JoyrideModule.forChild(),
	],
	exports: [HeaderComponent],
	providers: [PricingService]
})
export class CentaxHeaderModule {}
