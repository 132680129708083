import { Injectable } from '@angular/core'
import { HttpHelperService } from '@Services/http-helper.service'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { Observable } from 'rxjs'
import { USER_PROFILE_API } from '@Modules/component-modules/constant/api.constant'
import { CentaxApiList } from '@App/constant/centax-api.constant'

@Injectable({
	providedIn: 'root',
})
export class HeaderService {
	constructor(private httpHelperService: HttpHelperService, private _appUtilityService: AppUtilityService) {}

	getUserInfo(params): Observable<any> {
		const url = this._appUtilityService.getApiUrl(USER_PROFILE_API.GET_USER_PROFILE_DATA)
		return this.httpHelperService.get(url, params, true)
	}

	callAdvanceSearchApi(data) {
		const url = this._appUtilityService.getApiUrl(CentaxApiList.ADVANCE_SEARCH)
		return this.httpHelperService.post(url, data, true)
	}

	uploadAndSearch(formData: FormData): Observable<any> {
		const headers = new Headers()
		headers.append('Content-Type', 'multipart/form-data;')
		headers.append('Accept', '*/*')
		const url = this._appUtilityService.getApiUrl(CentaxApiList.UPLOAD_AND_SEARCH)
		return this.httpHelperService.postFile(url, formData, true, false, headers, true)
	}

	public getRenewalAlert(): Observable<any> {
		const url: string = this._appUtilityService.getApiUrl(CentaxApiList.RENEWAL_ALERT);
		return this.httpHelperService.get(url, {}, true)
	}

	public postRenewalAlert(data:any): Observable<any> {
		const url: string = this._appUtilityService.getApiUrl(CentaxApiList.PUSH_RENEWAL_ALERT);
		return this.httpHelperService.post(url, data, true)
	}

}
