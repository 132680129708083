export const CentaxAppApiConstant = {
	LDEV_SERVER: 'ldevServer',
	NODE_SERVER: 'nodeServer',
	CENTAX_SERVER: 'centaxServer',
	CRM_SERVER: 'crmServer',
	CENTAX_ADMIN_SERVER: 'centaxAdminServer',
	DDOWLOAD_PDF:'DDOWLOAD_PDF',
	DOWNLOAD_RTF_URL: 'DOWNLOAD_RTF_URL',
	MACHINE_ID_RTF_DOWNLOAD: 'MACHINE_ID_RTF_DOWNLOAD'
}

export const CentaxAppModuleList = [
	{
		id: 'RSRH',
		label: 'Research',
		url: '/research',
		type: 'external',
		sameTab: true,
		active: false,
	},
	{
		id: 'BKSTR',
		label: 'Bookstore',
		url: '/bookstore',
		type: 'external',
		sameTab: true,
		active: false,
	},
	{
		id: 'VBOOK',
		label: 'Virtual Books & Journals',
		url: '/research',
		type: 'external',
		sameTab: true,
		active: false,
	},
	{
		id: 'CMPLNCE',
		label: 'Compliance',
		url: '/compliance-etds',
		type: 'external',
		sameTab: false,
		active: false,
	},
	{
		id: 'CENTAX',
		label: 'Centax',
		url: '/',
		type: 'internal',
		sameTab: true,
		active: true,
	},
]

export const ApiStatus = {
	// LIMIT_EXCEED:"LIMIT_EXCEED_LOGIN_ATTEMPTS",
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	DOCUMENT_ACCESS_LIMIT_EXCEED: 'DOCUMENT_ACCESS_LIMIT_EXCEED',
	SESSION_EXPIRED: 'SESSION_EXPIRED',
	USER_ALREADY_REGISTERED: 'USER_ALREADY_REGISTERED',
	OTP_NOT_VERIFIED: 'OTP_NOT_VERIFIED',
	PLAN_NOT_SUBCRIBED: 'PLAN_NOT_SUBCRIBED',
	DATA_NOT_FOUND: 'DATA_NOT_FOUND',
	WARNING: 'WARNING',
	DATA_FOUND: 'DATA_FOUND',
	INVALID_KEY: 'INVALID_KEY',
	USED_KEY: 'USED_KEY',
	KEY_USAGE_LIMIT_EXCEED: 'KEY_USAGE_LIMIT_EXCEED',
	KEY_EXPIRED: 'KEY_EXPIRED',
	NOT_IP_USER: 'NOT_IP_USER',
	MODULE_NOT_SUBSCRIBED: 'MODULE_NOT_SUBSCRIBED',
	DATA_SAVED: 'DATA_SAVED',
	ALREADY_PURCHASED: 'ALREADY_PURCHASED',
	IREADER_ACCESS_LIMIT_EXCEED: 'IREADER_ACCESS_LIMIT_EXCEED',
	TOKEN_INVALID: 'TOKEN_INVALID',
	NOT_DELIVERABLE: 'NOT_DELIVERABLE',
	USER_NOT_FOUND: 'USER_NOT_FOUND',
	INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
	INVALID_LOGIN: 'INVALID_LOGIN',
	PLAN_EXPIRED: 'PLAN_EXPIRED',
	PLAN_SUBCRIBED: 'PLAN_SUBCRIBED',
	INVALID_CONTENT_TOKEN: 'INVALID_CONTENT_TOKEN',
	INVALID_IREADER_TOKEN: 'INVALID_IREADER_TOKEN',
	USER_CASHBACK_LIMIT_EXCEED: 'USER_CASHBACK_LIMIT_EXCEED',
	FREE_COPY_ALREADY_INCART: 'FREECOPYALREADYINCART',
	USER_REGISTERED: 'USER_REGISTERED',
	OTP_SEND: 'OTP_SEND',
	OTP_VERIFIED: 'OTP_VERIFIED',
	IP_NOT_REGISTERED: 'IP_NOT_REGISTERED',
	USER_MULTIPLE_EMAILS: 'USER_MULTIPLE_EMAILS',
	USER_NOT_REGISTERED: 'USER_NOT_REGISTERED',
	MOBILE_VERIFIED: 'MOBILE_VERIFIED',
	INVALID_OTP: 'INVALID_OTP',
	OTP_EXPIRED : 'OTP_EXPIRED',
	EMAIL_SENT: 'EMAIL_SENT',
	USER_NOT_ACTIVE: 'USER_NOT_ACTIVE',
	USER_PHONE_NOT_FOUND: 'USER_PHONE_NOT_FOUND',
	API_CALLING_LIMIT_EXCEED: 'API_CALLING_LIMIT_EXCEED',
	FILE_NOT_FOUND: 'FILE_NOT_FOUND',
	AUTH_TOKEN_NOT_RECEIVED: 'AUTH_TOKEN_NOT_RECEIVED',
	DONGLE_NO_ALREADY_VERIFIED: 'DONGLE_NO_ALREADY_VERIFIED',
	DONGLE_NO_NOT_FOUND: 'DONGLE_NO_NOT_FOUND',
	DONGLE_NO_ASSOCIATED_WITH_ANOTHER_EMAIL: 'DONGLE_NO_ASSOCIATED_WITH_ANOTHER_EMAIL',
	EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
	MOBILE_NOT_VERIFIED: 'MOBILE_NOT_VERIFIED',
	MOBILE_EMAIL_NOT_VERIFIED: 'MOBILE_EMAIL_NOT_VERIFIED',
	MOBILE_ALREADY_VERIFIED: 'MOBILE_ALREADY_VERIFIED', 
	SOCIAL_USER_REGISTERED: 'SOCIAL_USER_REGISTERED',
	EMAIL_NOT_RECEIVED: 'EMAIL_NOT_RECEIVED',
	PLAN_ALREADY_ACTIVATED: 'PLAN_ALREADY_ACTIVATED',
	FREE_TRIAL_ALREADY_EXTENDED: 'FREE_TRIAL_ALREADY_EXTENDED',
	INVALID_CHARACTERS:'invalid characters in request',
	INVALID_CHARACTERS_ERROR_MSG:'We do not support the following characters: [!#$%^*:{}|<>]'
}

export interface IDynamicCompilerData {
	templateRef: any
	inputData?: any
	template: string
	css?: string
	rootClass: any
	imports?: any[]
	animations?: any[]
	declarations?: any
	outputs?: any
	encapsulation?: any
}

export enum HeightAdjustElementType {
	HTML_ELEMENT = 1,
	HTML_ID,
	HTML_CLASS,
}

export enum FileOpenSource {
	FILE_OPEN = 0,
	TOP_STORY
}

export const DocumentCategory = {
	ACTS: 'ACTS',
	CASE_LAWS: 'CASE_LAWS',
	FORMS: 'FORMS',
	RULES: 'RULES',
	CIRCULARS: 'CIRCULARS',
	NOTIFICATIONS: 'NOTIFICATIONS',
	ARTICLES: 'ARTICLES',
	TARIFFS: 'TARIFFS',
	COMMENTARIES: 'COMMENTARIES',
	FAQS: 'FAQS',
	FINANCE_BILL: 'FINANCE_BILL',
	FINANCE_ACTS: 'FINANCE_ACTS',
	COUNCIL_MEETING: 'COUNCIL_MEETING',
	STATE_OUT: 'STATE_OUT',
	SEARCH: 'SEARCH',
	FOREIGN_TRADE_POLICY:'FOREIGN_TRADE_POLICY',
	HANDBOOK_PROCEDURES:'HANDBOOK_PROCEDURES',
	AAYAT_NIRYAT_FORMS:"AAYAT_NIRYAT_FORMS",
	APPENDICES:"APPENDICES",
	GSTN_UPDATES:'GSTN_UPDATES'
}

export const DocumentCategoryTitle = {
	ACTS: 'Acts',
	CASE_LAWS: 'Case Laws',
	FORMS: 'Forms',
	RULES: 'Rules',
	CIRCULARS: 'Circulars',
	NOTIFICATIONS: 'Notifications',
	ARTICLES: 'Articles',
	TARIFFS: 'Tariffs',
	COMMENTARIES: 'Commentaries',
	FAQS: 'Faqs',
	FINANCE_BILL: 'Finance Bill',
	FINANCE_ACTS: 'Finance Acts',
	COUNCIL_MEETING: 'Council Meeting',
	STATE_OUT: 'State Out',
	FOREIGN_TRADE_POLICY:'Foreign Trade Policy',
	HANDBOOK_PROCEDURES:'Handbook Procedures',
	AAYAT_NIRYAT_FORMS:"Aayat Niryat Forms",
	APPENDICES:"Appendices",
	GSTN_UPDATES:'GSTN Updates'
}

export const DataTypeMiddlePanel = {
	Chapter_Wise: 'Chapter_Wise',
	Section_Wise: 'Section_Wise',
	Finance_Act_Section_Wise:'Finance_Act_Section_Wise'
}

export const LatestStoryGroupNameMap = {
	all: 'all',
	gst: 'centax-gst',
	customs: 'centax-customs',
	'excise-service-tax': 'centax-excise-service-tax',
	archives: 'archives',
	ftp:'centax-foreign-trade-policy',
	'foreign-trade-policy':'foreign-trade-policy'
}

export const imageBasePath: string = 'https://cdn.centaxonline.com/Researchimages'

export const CDNBasePath: string = 'https://cdn.centaxonline.com/'


export const GroupMap = {
	GST: 'GST',
	CUSTOM: 'CUSTOM',
	EXCISE_SERVICE_TAX: 'EXCISE_SERVICE_TAX',
	'FOREIGN_TRADE_POLICY':'FOREIGN_TRADE_POLICY'
}

export const CategoryURLGroupMap = {
	'centax-gst': 'GST',
	'centax-customs': 'Customs',
	'centax-excise-service-tax': 'Excise & Service Tax',
	'centax-foreign-trade-policy':'Foreign Trade Policy'
}

export const CheckoutFrom = {
	CART: 'C',
	BUY_NOW: 'B',
}

export enum Currency {
	INR = 0,
	USD,
}

export const CurrencySymbol = {
	INR: '₹',
	USD: '$',
}

export enum PaymentType {
	RAZORPAY = 1,
	DD_CHEQUE_PAYMENTS,
	NEFT_PAYMENTS,
	EMI = 6,
}

export const DocumentURLCategory = {
	ACTS: 'acts',
	CASE_LAWS: 'case-laws',
	FORMS: 'forms',
	RULES: 'rules',
	CIRCULARS: 'circular',
	NOTIFICATIONS: 'notification',
	ARTICLES: 'articles',
	TARIFFS: 'tariffs',
	COMMENTARIES: 'commentaries',
	FAQS: 'faqs',
	FINANCE_BILL: 'finance_bill',
	FINANCE_ACTS: 'finance_acts',
	COUNCIL_MEETING: 'council_meeting',
	STATE_OUT: 'state_out',
	SEARCH: 'search',
	RESEARCH_BOX: 'research_box',
	FOREIGN_TRADE_POLICY:'foreign_trade_policy',
	HANDBOOK_PROCEDURES:'handbook-procedures',
	AAYAT_NIRYAT_FORMS:'AAYAT_NIRYAT_FORMS',
	APPENDICES:"APPENDICES",
	GSTN_UPDATES:'GSTN_UPDATES'
}

export const RouteCategoryURLToCategoryNameMapping = {
	'centax-gst': 'GST',
	'centax-customs': 'CUSTOM',
	'centax-excise-service-tax': 'Excise & Service Tax',
	'foreigntradepolicy':'FOREIGN_TRADE_POLICY',
	'GST': 'GST',
	'Customs': 'CUSTOM',
	'Excise': 'Excise & Service Tax',
	'Excise&ServiceTax': 'Excise & Service Tax',
	'foreign-trade-policy':'FOREIGN_TRADE_POLICY'
}

export const SocialSubmitRegisterType = {
     EMAIL: 'EMAIL',
	 Mobile: 'Mobile'
}
