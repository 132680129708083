import { Injectable } from '@angular/core'
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login'
import { Observable } from 'rxjs'
import { AppUtilityService } from '@Utils/app-utlility.service'
import { HttpHelperService } from '@Services/http-helper.service'

@Injectable({
	providedIn: 'root',
})
export class CentaxSocialAuthService {
	constructor(
		private authService: SocialAuthService,
		private _appUtilityService: AppUtilityService,
		private httpHelperService: HttpHelperService
	) {}

	signInWithGoogle(): any {
		return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
	}

	signInWithFB(): any {
		return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
	}

	signOut(): any {
		return this.authService.signOut()
	}

	loginUserBySocialDetails(formData: any): Observable<any> {
		const url = this._appUtilityService.getApiUrl('login')
		return this.httpHelperService.post(url, formData, false)
	}

	getEmailByProviderId(socialProviderId: any): Observable<any> {
		const url = this._appUtilityService.getApiUrl(`Email/${socialProviderId}`)
		return this.httpHelperService.get(url, {}, false)
	}
}
