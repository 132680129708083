import { Component, OnInit } from '@angular/core'
import { HeaderDropDownPageChange } from '@App/constant/constant'
import { HomeService } from '@Services/home.service'
import { AppUtilityService } from '@Utils/app-utlility.service'

@Component({
	selector: 'centax-disclaimer',
	templateUrl: './disclaimer.component.html',
	styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
	constructor(private homeService: HomeService, private appUtilityService: AppUtilityService) {}

	ngOnInit(): void {
		this.homeService.showHeader.next(true)
		this.appUtilityService.scrollToTop()
		this.homeService.showSidebarPage.next(false)
		this.homeService.showPricing.next(true)
		this.appUtilityService.setShowGlobalSearchObs(true)
		this.homeService.pagechangeValue.next(HeaderDropDownPageChange.CENTAX_ONLINE)
	}
}
