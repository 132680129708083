export const GST_CAE_LAWS_API = {
	NAME_SUGGESTION: 'getCaselawsNameSuggestion',
	DEFAULT_FILTER: 'getCaselaws',
	SUBJECT_FILTER: 'getSubject',
	MORE_FILTER: 'getCaselawsMore',
}

export const ALL_CAE_LAWS_API = {
	NAME_SUGGESTION: 'getCaselawsNameSuggestion',
	DEFAULT_FILTER: 'getAllCaselaws',
	SUBJECT_FILTER: 'getSubject',
	MORE_FILTER: 'getAllCaselawsMore',
}

export const GST_ACTS_API = {
	DEFAULT_FILTER: 'getAct',
	SUBJECT_FILTER: 'getSubject',
	ChapterSectionsList: 'getActChapterSectionsList',
	PRINT_ACT_CHAPTER_SECTION_LIST: 'printActChapterSectionsList',
}

export const GST_RULES_API = {
	DEFAULT_FILTER: 'getRule',
	SUBJECT_FILTER: 'getSubject',
}

export const GST_FORM_API = {
	DEFAULT_FILTER: 'getForm',
	SUBJECT_FILTER: 'getSubject',
}

export const GST_CIRCULAR_API = {
	DEFAULT_FILTER: 'getCircular',
	MORE_FILTER: '',
	SUBJECT_FILTER: 'getSubject',
}

export const GST_NOTIFICATION_API = {
	DEFAULT_FILTER: 'getNotification',
	MORE_FILTER: '',
	SUBJECT_FILTER: 'getSubject',
}

export const GST_ARTICLES_API = {
	DEFAULT_FILTER: 'getArticle',
	MORE_FILTER: 'getArticleMore',
	SUBJECT_FILTER: 'getSubject',
}

export const GST_STATE_UT_API = {
	DEFAULT_FILTER: 'getStateUt',
	MORE_FILTER: '',
	SUBJECT_FILTER: 'getSubject',
}
export const GST_TARIFF_API = {
	DEFAULT_FILTER: 'getTariff',
	SUBJECT_FILTER: 'getSubject',
}

export const GST_COMMENTARIES_API = {
	DEFAULT_FILTER: 'getCommentary',
	SUBJECT_FILTER: 'getSubject',
	ChapterSectionsList: 'getCommentaryChild',
	PRINT_ACT_CHAPTER_SECTION_LIST: 'PrintCommentaryData',
}

export const GST_FAQS_API = {
	DEFAULT_FILTER: 'getFaq',
	SUBJECT_FILTER: 'getSubject',
	ChapterSectionsList: 'getFaqChild',
}

export const GST_FINANCE_BILLS_API = {
	DEFAULT_FILTER: 'getFinanceBill',
	SUBJECT_FILTER: 'getSubject',
	ChapterSectionsList: 'getFinanceBillChild',
	PRINT_ACT_CHAPTER_SECTION_LIST: 'PrintFinanceBillData',
}

export const GST_FINANCE_ACTS_API = {
	DEFAULT_FILTER: 'getFinanceAct',
	SUBJECT_FILTER: 'getSubject',
	GET_SECTIONS_LIST:'getFinanceActSectionListData',
	GET_SECTIONS_CHILD_LIST:'getFinanceActChildData',
	GET_FINANCE_ACT_CHAPTER_WISE_PRINT:'printFinanceActChapter',
	GET_FINANCE_ACT_SECTION_WISE_PRINT:'printSectionWiseData'
}

export const GST_COUNCIL_MEETING_API = {
	DEFAULT_FILTER: 'getCounilMeeting',
	SUBJECT_FILTER: 'getSubject',
	ChapterSectionsList: 'getCounilMeetingChild',
	PRINT_ACT_CHAPTER_SECTION_LIST: 'PrintGSTCouncilData',
}

export const GST_GLOBAL_SEARCH_API = {
	// NAME_SUGGESTION: '',
	DEFAULT_FILTER: 'getSearchResult',
	SUBJECT_FILTER: 'getSubject',
	MORE_FILTER: 'getSearchResultMore',
	UPLOAD_SEARCH: 'uploadAndSearch',
}

export const USER_PROFILE_API = {
	/**
	 * GET API's
	 */
	GET_USER_PROFILE_DATA: 'getUserInfo',
	GET_COUNTRY_CODES: 'countryCodes',
	GET_DESTINATIONS: 'getDesignationList',
	GET_COUNTRY_STATES: 'getCountryStates',
	GET_STATE_BY_PIN: 'getStateCitybyPIN',
	GET_BILLING_ADDRESS: 'getAddress',
	GET_ALL_ENTITIES: 'getEntityTypes',
	GET_USER_SUBSCRIPTION_DETAILS: 'getMySubscription',

	/**
	 * Update API's
	 */
	UPDATE_PERSONAL_DATA: 'editPersonalInfo',
	UPDATE_ADDRESS_DATA: 'postAddress',
	DELETE_ADDRESS_DATA: 'deleteAddress',
	UPDATE_GSTIN_FLAG_DATA: 'updateGstnFlag',
	ADD_GSTIN_DATA: 'addGstnInfo',
	UPDATE_GSTIN_DATA: 'updateGstnDetails',
	DELETE_GSTIN_DATA: 'deleteGstnDetails',
	UPDATE_PASSWORD: 'password',
	GENERATE_OTP: 'generateOTP',
	GENERATE_EMAIL_OTP: 'SendEmailOtp',
	VERIFY_OTP: 'verifyOTP',
	VERIFY_EMAIL_OTP: 'VerifyEmailOtp',
	REGISTER_USER_BY_SOCIAL: 'registerUserBySocial',
	GET_STATES : 'states',
	GET_GSTN_CATEGORY:'gstnCategory',
	GET_GSTN_SUB_CATEGORY:'gstnSubCategory'
}

export const CHECKOUT_API = {
	DONGLE_VERIFY: 'dongle/verify',
}

export const MANAGE_NEWSLETTER_API = {
	NEWSLETTER : 'newsLatters',
	NEWSLETTER_SETTING : 'newsLattersSetting',
	NEWSLETTER_DEFAULT_SETTING :'setNewsLettersDefaultSetting',
	NEWLETTER_CONSENT_FLAG:'newsletterConsentFlags',
	NEWSLETTER_OPT_OUT:'optOutNewsletter'
}

export const FOREIGN_TRADE_POLICY_API = {
	DEFAULT_FILTER: 'ftp',
	SUBJECT_FILTER: 'getSubject',
}

export const HANDBOOK_PROCEDURES_API = {
	DEFAULT_FILTER: 'handbookprocedures',
	SUBJECT_FILTER: 'getSubject',
}

export const AAYAT_NIRYAT_FORMS_API = {
	DEFAULT_FILTER: 'ftpanforms',
	SUBJECT_FILTER: 'getSubject',
}

export const APPENDICES_API={
	DEFAULT_FILTER: 'getAppendices',
	SUBJECT_FILTER: 'getSubject',
}

export const GSTN_UPDATES_API = {
	DEFAULT_FILTER: 'gstnUpdate ',
	SUBJECT_FILTER: 'getSubject',
}
