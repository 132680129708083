import {Injectable} from '@angular/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {LocalStorageDataModel} from '@Constants/localstorage.constant';
import {DeviceType} from "@Constants/constant";
import {AppUtilityService} from "@Utils/app-utlility.service";

@Injectable({
	providedIn: 'root'
})
export class DeviceDetailsService {

	constructor(private deviceDetectorService: DeviceDetectorService,
				private appUtilityService: AppUtilityService) {
	}

	getUserDeviceDetails(): any {
		return {
			ipAddress: this.appUtilityService.getDataFromLocalStorage(LocalStorageDataModel.USER_IP),
			userAgent: this.deviceDetectorService.getDeviceInfo()?.userAgent,
			device: this.deviceDetectorService?.device,
			os: this.deviceDetectorService.os,
			osVersion: this.deviceDetectorService.os_version,
			browser: this.deviceDetectorService.browser,
			browserVersion: this.deviceDetectorService.browser_version,
			deviceType: this.deviceDetectorService.isDesktop() ? DeviceType.DESKTOP :
				this.deviceDetectorService.isTablet() ? DeviceType.TABLET : this.deviceDetectorService.isMobile() ? DeviceType.MOBILE : ''

		};
	}
}
