import { Component, HostListener, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { HeightAdjustElementType } from '@App/constant/centax-app.constant'
import { APIResponse, SidebarmenuList } from 'src/app/interface/centax-data.interface'
import { HomeService } from 'src/app/services/home.service'
import { AppUtilityService } from 'src/app/utility/app-utlility.service'

@Component({
	selector: 'centax-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	sideBarCollapse: boolean = false
	isMobileView: boolean = this.appUtilityService.isMobileView()
	sideBarMenuList: SidebarmenuList[] = []
	childState: string = 'block'
	showSideMenuItems: boolean = false
	initialmenu: boolean = false
	activeCategory: string = 'latestnews'
	currentUrl: string = '/latest-news'
	hoverSidebar: boolean = false
	 _isShowResearchRenewalStip: boolean = false;
	@Input()
	public set isShowResearchRenewalStip(val: boolean) {
		this._isShowResearchRenewalStip = val
		setTimeout(() => {
			this.giveDynamicHeightToSidebar();
		}, 500);
	}
	public get isShowResearchRenewalStip() {
		return this._isShowResearchRenewalStip
	}
	constructor(
		protected appUtilityService: AppUtilityService,
		protected router: Router,
		protected homeService: HomeService
	) {}

	ngOnInit(): void {
		this.appUtilityService.setDataOnLocalStorage('showSidebar', 'N')
		this.homeService.getLeftMenuData().subscribe((response: APIResponse) => {
			this.sideBarMenuList = response.Data || []
		})
		this.homeService.sideBarCollapse.subscribe((flag: boolean) => {
			this.sideBarCollapse = flag
			if (this.sideBarCollapse == true) {
				this.hoverSidebar = false
			}
		})
		this.getActiveSidebarTab()
		this.router.events.subscribe((res: any) => {
			this.getActiveSidebarTab()
		})
		this.giveDynamicHeightToSidebar();
	}

	toggleSidebar() {
		this.sideBarCollapse = !this.sideBarCollapse
		if (this.sideBarCollapse) {
			this.appUtilityService.setDataOnLocalStorage('showSidebar', 'N')
		} else {
			this.appUtilityService.setDataOnLocalStorage('showSidebar', 'Y')
		}
		if (this.isMobileView) {
			this.appUtilityService.setShowFilter(false)
			if (this.sideBarCollapse && this.appUtilityService.isMobileView()) {
				document.body.classList.add('scroll-off')
			} else {
				document.body.classList.remove('scroll-off')
			}
		}
	}
	activeList = ''
	onSidebarItemClick(event: any, list: SidebarmenuList, ischildren: boolean): void {
		this.appUtilityService.setHideSearchSuggestion(false)
		this.appUtilityService.setDataOnSessionStorage('tabList', '')
		console.log('list', list)
		if (this.activeList != list.name) {
			this.showSideMenuItems = false
		}
		if (!list.parent_category_id && !ischildren && !['All Case Laws', 'Latest News'].includes(list?.name)) {
			this.showSideMenuItems = !this.showSideMenuItems
			if (list.category_id == 'centax-gst') {
				if (!this.showSideMenuItems) {
					document.getElementById('child-centax-gst-null').className = 'p-d-none'
					this.activeList = ''
				} else {
					document.getElementById('child-centax-gst-null').className = 'p-d-block'
					document.getElementById('child-centax-customs-null').className = 'p-d-none'
					document.getElementById('child-centax-excise-service-tax-null').className = 'p-d-none'
					document.getElementById('child-centax-foreign-trade-policy-null').className = 'p-d-none'
					this.activeList = list.name
				}
			}
			if (list.category_id == 'centax-customs') {
				if (!this.showSideMenuItems) {
					;(document.getElementById('child-centax-customs-null').className = 'p-d-none'),
						(this.activeList = '')
				} else {
					;(document.getElementById('child-centax-customs-null').className = 'p-d-block'),
						(document.getElementById('child-centax-gst-null').className = 'p-d-none')
					document.getElementById('child-centax-excise-service-tax-null').className = 'p-d-none'
					document.getElementById('child-centax-foreign-trade-policy-null').className = 'p-d-none'
					this.activeList = list.name
				}
			}
			if (list.category_id == 'centax-excise-service-tax') {
				if (!this.showSideMenuItems) {
					document.getElementById('child-centax-excise-service-tax-null').className = 'p-d-none'
					this.activeList = ''
				} else {
					document.getElementById('child-centax-excise-service-tax-null').className = 'p-d-block'
					;(document.getElementById('child-centax-customs-null').className = 'p-d-none'),
						(document.getElementById('child-centax-gst-null').className = 'p-d-none')
						document.getElementById('child-centax-foreign-trade-policy-null').className = 'p-d-none'
					this.activeList = list.name
				}
			}
			if (list.category_id == 'centax-foreign-trade-policy') {
				if (!this.showSideMenuItems) {
					document.getElementById('child-centax-foreign-trade-policy-null').className = 'p-d-none'
					this.activeList = ''
				} else {
					document.getElementById('child-centax-foreign-trade-policy-null').className = 'p-d-block'
					;(document.getElementById('child-centax-customs-null').className = 'p-d-none'),
						(document.getElementById('child-centax-gst-null').className = 'p-d-none')
					this.activeList = list.name
				}
			}
			return
		} else {
			this.appUtilityService.activeIndex = 0
			this.router.navigateByUrl(list.url)
		}
		// this.router.navigate([list.url]);
		if (this.appUtilityService.isMobileView()) {
			this.homeService.sideBarCollapse.next(false)
		} else {
			if (this.appUtilityService.getDataFromLocalStorage('showSidebar') == 'Y') {
				this.homeService.sideBarCollapse.next(false)
			} else {
				this.homeService.sideBarCollapse.next(true)
			}
		}
		// this.homeService.resetFilterFormValue();
		// if(list.defaultFilterApi) {
		// 	this.homeService.resetFilterFormValue();
		// 	this.homeService.setFilterFormValue('defaultApiUrl',list.defaultFilterApi);
		// 	this.homeService.setFilterFormValue('moreFilterApiUrl',list.moreFilterApi);
		// 	this.homeService.setFilterFormValue('groupLabel',list.name);
		// 	this.homeService.setFilterFormValue('categoryName',list.parent_category_id);
		// 	this.homeService.callFiltersApiList();
		// }
		if (!ischildren) {
			this.hideShowChild(event, list)
		}
		this.appUtilityService.subjectLabelArr = []
		//this.appUtilityService.searchHeaderText = ''
		this.appUtilityService.searchHeaderText['searchText'] = '';
		this.appUtilityService.searchHeaderText['isGlobal'] = false;	
		event.preventDefault()
		event.stopPropagation()
	}

	hideShowChild(event, list: SidebarmenuList) {
		event.preventDefault()
		event.stopPropagation()
		let ele: any = document.getElementById('child-' + list.category_id + '-' + list.parent_category_id)
		if (ele) {
			if (ele.style.display == 'block') {
				ele.style.display = 'none'
			} else {
				ele.style.display = 'block'
			}
		}
	}

	getActiveSidebarTab(): void {
		this.currentUrl = this.router.url
		if (this.currentUrl.includes('latest-news-updates')) {
			this.activeCategory = 'latestnews'
		} else if (this.currentUrl.includes('gst')) {
			this.activeCategory = 'gst'
		} else if (this.currentUrl.includes('customs')) {
			this.activeCategory = 'customs'
		} else if (this.currentUrl.includes('excise-service-tax')) {
			this.activeCategory = 'exciseservicetax'
		} else if(this.currentUrl.includes('foreigntradepolicy')){
			this.activeCategory = 'foreigntradepolicy'
		}else if (this.currentUrl.includes('caselaws')) {
			this.activeCategory = 'caselaws'
		} else if(this.currentUrl.includes('foreign-trade-policy')){
			this.activeCategory = 'foreigntradepolicy'
		}
	}

	hovering(event: any): void {
		if (this.sideBarCollapse) {
			this.hoverSidebar = event
		}
	}

	giveDynamicHeightToSidebar(): void{
		this.appUtilityService.adjustViewHeight('dynamicHeight', HeightAdjustElementType.HTML_CLASS)
	}

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		this.giveDynamicHeightToSidebar();
	}
}
