import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse, HttpErrorResponse, HttpInterceptor
} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, finalize, retry, tap} from 'rxjs/operators';
import { HttpCacheService } from '../services/http-cache.service';
import { AppUtilityService } from '../utility/app-utlility.service';
import {ApiStatus} from "@Constants/centax-app.constant";
import {AuthService} from "@Services/auth.service";
import {CommonAlertService} from "@Services/common-alert.service";
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

	constructor(
		private _cache: HttpCacheService, private authService: AuthService, private commonAlertService: CommonAlertService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.method === 'POST' || request.method === 'PUT' || request.method === 'PATCH' || request.method === 'DELETE') {
			const removedFromCache = this._cache.delete(request);
			if (removedFromCache) {
			}
		}
		return next.handle(request)
			.pipe(
				tap<HttpEvent<any>>((httpEvent: HttpEvent<any>) => {
					if (httpEvent instanceof HttpResponse) {
						this._cache.put(request, httpEvent);
					}
					if (httpEvent && httpEvent['body']?.ResponseType === ApiStatus.TOKEN_INVALID) {
						this.commonAlertService.errorToast('Token Expired. Please login again.');
						this.authService.logout();
					} else {
						return httpEvent;
					}
				}, (error: any) => {

				}),
				catchError((err: HttpErrorResponse) => {
					throw err;
				}),
				finalize(() => {
					// this._loading.setLoading(false, request);
				}),
				// retry(2)
			);
	}
}
