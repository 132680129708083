import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { FooterComponent } from './footer.component'
import { RouterModule } from '@angular/router'
import { TooltipModule } from 'primeng/tooltip'

@NgModule({
	declarations: [FooterComponent],
	imports: [CommonModule, RouterModule, TooltipModule],
	exports: [FooterComponent],
})
export class CentaxFooterModule {}
