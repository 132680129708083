import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'substring'
})
export class SubstringPipe implements PipeTransform {

    transform(str: string, length: number): any {
        if (str && length) {
            return str.toString().length <= length ? str : str.toString().substring(0, length) + '...';
        }
        return str;
    }
}

