export const NETCORE_EVENT_LIST = {
    PRODUCT_VIEWED : 'product_viewed',
    ADD_TO_CART : 'product_addtocart_clicked',
    BUY_NOW : 'product_buynow_clicked',
    ADD_TO_WISHLIST : 'product_wishlisticon_clicked',
    PROCEED_TO_CHECKOUT : 'cart_proceedtocheckout_clicked',
    PROCEED_TO_PAYMENT : 'checkout_proceedtopay_clicked',
    PAYMENT_INITIATED : 'payment_initiated',
    PAYMENT_SUCCESSFUL : 'payment_successful',
    PAYMENT_FAILED : 'payment_failed',
    ORDER_CONFIRMED : 'order_confirmed',
    PAGE_BROWSE : 'page_visited',
    SIGN_UP_INITIATE : 'user_signup_initiated',
    SIGN_UP_CONFIRMED : 'user_signup_confirmed',
    SIGNED_IN : 'user_signedin',
    LOGOUT : 'logout',
    FREE_TRIAL_INITIATED : 'free_trial_initiated',
    FREE_TRIAL_ACTIVATED : 'free_trial_activated',
    SUBSCRIPTION_INITIATED : 'subscription_initiated',
    GST_EXCUS_BUY_NOW : 'gst_excus_buy_now_clicked',
    GST_EXCUS_DOWNLOAD: 'gst_excus_download',
    CONTACT_US_SUBMITTED : 'contact_us_submitted',
    SEARCH_PERFORMED : 'search_performed',
    LOGIN_SUCCESS : 'login_success'
    
}