// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	type: 'local',
	api: {
		ldevServer: 'https://lbackoffice.taxmann.com/researcnest/api/', //dotnet
		nodeServer: 'http://nodedev.taxmann.com/', //mctl
		centaxServer: 'https://devapi.centaxonline.com/centax/api/v1/',
		crmServer: 'https://supportws.taxmann.com/centaxlead.svc/',
		centaxAdminServer: 'https://nodedev.taxmann.com/centaxadmin/api/v1/',
		DDOWLOAD_PDF:'https://pdf.taxmann.com/research/'
	},
	IMAGE_URL: 'https://d3lzbbhyvqc4k.cloudfront.net/images/',
	CENTAX_CDN_IMAGE_URL: 'https://cdn.centaxonline.com/Researchimages',
	MARKETING_API: "https://devapi.taxmann.dev/marketing/api/v1/",
	NETCORE_REGISTER_KEY: "cc58a9636208aa14ca3e0ce72952fd31",
	ONE_SIGNAL_KEY:"12ff94c5-bc76-4c0a-92f2-3114b9911515",
	SCCRET_KEY_MACID: 'dsdgjSWDhjKEYGEN232xsdf5Asf5Op',
	DOWNLOAD_RTF_URL: "https://lbackoffice.taxmann.com/researcnest/api/",
	MACHINE_ID_RTF_DOWNLOAD: '5cd34f8061024d69a8bdc5005afc90a0'
}

export const PROJECT_NAME = 'centax-frontend'

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
