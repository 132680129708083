export const journalTypeList = {
    GSTL: '111160000000000033',
    ELT: '111160000000000032',
    STR: '111160000000000034',
    CENTAX: '111160000000000035'
}

export const citationParamJournalType = {
    GSTL: 'citationcentaxgstlwise',
    ELT: 'citationcentaxeltwise',
    STR: 'citationcentaxstrwise',
    CENTAX: 'citatiocentaxnwise',
    DEFAULT: 'citationwise'
}