import { Injectable } from '@angular/core';

@Injectable()
export class ConfirmationService {
    public showPopup: boolean;
    public header: string;
    public message: string;
    public accept: () => void;
    public reject: () => void;
    public confirmBtnText: string;
    public rejectBtnText: string;
    private _confirm: any;
    constructor() { }

    public confirm(confirm: any) {
        this.showPopup = true;
        this.header = confirm.header || 'Confirm';
        this.message = confirm.message || 'Are you sure?';
        this.confirmBtnText = confirm.confirmBtnText || '';
        this.rejectBtnText = confirm.rejectBtnText || '';
        this._confirm = confirm;
    }

    public onAccept() {
        this.showPopup = false;
        if (typeof this._confirm.accept === 'function') {
            this._confirm.accept();
        }
    }
    public onReject() {
        this.showPopup = false;
        if (typeof this._confirm.reject === 'function') {
            this._confirm.reject();
        }
    }
}
