import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'
import { AppUtilityService } from '../utility/app-utlility.service'
import { LocalStorageDataModel } from '@Constants/localstorage.constant'

@Injectable({
	providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
	constructor( private router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if(next && next.params && next.params.category && next.params.category== 'foreigntradepolicy'){
			let url = state.url;
			url =  url.replace('foreigntradepolicy', 'foreign-trade-policy');
			this.router.navigate([url]);;
			return true
		}else{
		return true;
	}
}
}
