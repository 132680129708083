import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { HttpHelperService } from '@Services/http-helper.service'
import { GST_CAE_LAWS_API, MANAGE_NEWSLETTER_API, USER_PROFILE_API } from '@Modules/component-modules/constant/api.constant'
import { AppUtilityService } from '@Utils/app-utlility.service'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	userData = new BehaviorSubject<any>(null)

	personalInfoDetails = new Subject()
	addressDetails = new Subject()
	GSTINDetails = new Subject()

	constructor(private httpHelperService: HttpHelperService, private appUtilityService: AppUtilityService) {}

	getUserProfileData(params): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_USER_PROFILE_DATA)
		return this.httpHelperService.get(url, params, true)
	}

	getAllCountries(): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_COUNTRY_STATES)
		return this.httpHelperService.get(url, {}, true)
	}

	getAllStatesByPinCode(pinCode): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_STATE_BY_PIN + '/' + pinCode)
		return this.httpHelperService.get(url, {}, true)
	}

	getAllDesignations(): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_DESTINATIONS)
		return this.httpHelperService.get(url, {}, true)
	}

	updatePersonalInfo(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.UPDATE_PERSONAL_DATA)
		return this.httpHelperService.post(url, data, true)
	}

	getgstcategory(): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_GSTN_CATEGORY)
		return this.httpHelperService.get(url, {}, true)
	} 
	getgstSubcategory(params: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_GSTN_SUB_CATEGORY + params)
		return this.httpHelperService.get(url, {}, true)
	} 
	generateOTP(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GENERATE_OTP)
		return this.httpHelperService.post(url, data, true)
	}

	verifyOTP(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.VERIFY_OTP)
		return this.httpHelperService.post(url, data, true)
	}

	addEditAddress(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.UPDATE_ADDRESS_DATA)
		return this.httpHelperService.post(url, data, true)
	}

	deleteUserAddress(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.DELETE_ADDRESS_DATA)
		return this.httpHelperService.post(url, data, true)
	}

	updateGSTFlag(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.UPDATE_GSTIN_FLAG_DATA)
		return this.httpHelperService.post(url, data, true)
	}

	addGSTInfo(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.ADD_GSTIN_DATA)
		return this.httpHelperService.post(url, data, true)
	}

	updateGSTInfo(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.UPDATE_GSTIN_DATA)
		return this.httpHelperService.post(url, data, true)
	}
	changePassword(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.UPDATE_PASSWORD)
		return this.httpHelperService.put(url, data, true)
	}

	getAllEntities(): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_ALL_ENTITIES)
		return this.httpHelperService.get(url, {}, true)
	}

	removeGSTInfo(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.DELETE_GSTIN_DATA)
		return this.httpHelperService.post(url, data, true)
	}

	getUserSubscriptionDetails(queryParams: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(USER_PROFILE_API.GET_USER_SUBSCRIPTION_DETAILS)
		return this.httpHelperService.get(url, queryParams, true)
	}

	getNewsLettersDetails(): Observable<any> {
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWSLETTER)
		return this.httpHelperService.get(url, {}, true)
	}

	postNewsLettersToggle(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWSLETTER)
		return this.httpHelperService.post(url, data, true)
	}


	getNewsLetterSettings(params): Observable<any> {
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWSLETTER_SETTING)
		return this.httpHelperService.get(url, params, true)
	}

	postNewsLetterSettings(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWSLETTER_SETTING)
		return this.httpHelperService.post(url, data, true)
	}
     
	NewsletterDefaultsetting(data: any): Observable<any> {
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWSLETTER_DEFAULT_SETTING)
		return this.httpHelperService.put(url, data, true)
	}

	getNewsLetterConsentFlag(): Observable<any> {
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWLETTER_CONSENT_FLAG)
		return this.httpHelperService.get(url, {}, true)
	}
	
	postNewsletterOptout(){
		const url = this.appUtilityService.getApiUrl(MANAGE_NEWSLETTER_API.NEWSLETTER_OPT_OUT)
		return this.httpHelperService.post(url, {}, true)
	}

}
