import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { MessageService, PrimeNGConfig } from 'primeng/api'
import { HomeService } from 'src/app/services/home.service'
import { AppUtilityService } from 'src/app/utility/app-utlility.service'
import { HeaderComponent } from '../header.component'
import { CentaxSocialAuthService } from '@Utils/centax-social-auth.service'
import { HeaderService } from '@Modules/header/services/header.service'
import { UserService } from '@App/user/services/user.service'
import { BaseService } from '@App/modules/component-modules/base/base.service'
import { AuthService } from '@Services/auth.service'
import { JoyrideService } from 'ngx-joyride'
import { CommonAlertService } from '@App/services/common-alert.service'
import { PricingService } from '@App/pricing/pricing.service'

@Component({
	selector: 'centax-mobile-header',
	templateUrl: './mobile-header.component.html',
	styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent extends HeaderComponent {
	sideBarBtn: boolean = false

	uploadfilestoggle: boolean
	showResearchMenu: boolean = false
	showProfileMenu: boolean = false

	showSearchBar: boolean = false

	constructor(
		protected _homeService: HomeService,
		protected router: Router,
		protected activatedRoute: ActivatedRoute,
		protected formBuilder: FormBuilder,
		protected appUtilityService: AppUtilityService,
		protected messageService: MessageService,
		protected primengConfig: PrimeNGConfig,
		protected centaxSocialAuthService: CentaxSocialAuthService,
		protected headerService: HeaderService,
		protected userService: UserService,
		protected baseService: BaseService,
		protected authService: AuthService,
		protected readonly joyrideService: JoyrideService,
		protected commonAlertService: CommonAlertService,
		protected pricingService: PricingService
	) {
		super(
			_homeService,
			router,
			activatedRoute,
			formBuilder,
			appUtilityService,
			messageService,
			primengConfig,
			centaxSocialAuthService,
			headerService,
			userService,
			baseService,
			authService,
			joyrideService,
			commonAlertService,
			pricingService
		)
	}

	showFilters() {
		this.appUtilityService.setShowFilter(true)
	}

	uploadfiles() {
		this.uploadfilestoggle = true
	}

	sidebarClick() {
		this.sideBarBtn = !this.sideBarBtn
		this._homeService.showSidebarPage.next(this.sideBarBtn)
	}

	manageShowSearch(event: boolean) {
		this.showSearchBar = event
	}

	navigateToExternal(event) {
		if (event == 'Bookstore') {
			window.open('https://old.centaxonline.com/book-store/', '_blank')
		}
	}
}
