import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SidebarComponent } from './sidebar.component'
import { RouterModule } from '@angular/router'
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component'
import { SidebarUrlPipe } from './sidebar-research.pipe'

const routes = []
@NgModule({
	declarations: [SidebarComponent, MobileSidebarComponent, SidebarUrlPipe],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [SidebarComponent, MobileSidebarComponent],
})
export class CentaxSidebarModule {}
