export const MetaInfo = {
	MAIN: {
		title: `Centaxonline | Indirect Taxes Legal Research Platform with a Century's Experience`,
		description: `Centaxonline is the largest & most accurate legal database on GST, Customs, Excise & Service Tax. It combines the Editorial Legacy of 50 years & Research Legacy of 20 years with the Latest Technology`,
		keywords:
			'excus, gst excus, excus subscription, indirect tax, gst, customs law, service tax, excise duty, excus online download, gst excus',
	},
	LATEST_STORIES: {
		title: ``,
		description: ``,
		keywords: ``,
		child: {
			ALL: {
				title: `Centaxonline | Latest News and Update | Indirect Taxes`,
				description: `Explore the latest News & Updates on Indirect Taxes at Centaxonline, your go-to source for GST, FTP, Customs, Excise, and Service Tax information. Our platform provides essential news, updates, and insights to help professionals and businesses stay informed about the latest policies, changes, and guidelines. Whether you're looking for the latest tax law or practical advice for compliance, find everything you need to stay ahead in the dynamic field of Indirect Taxes.`,
				keywords: `tax news today, tax news India, indirect tax news, gst news, gst updates, customs and excise, service tax updates, excise duty in India, gst articles, gst, gst newsletter, custom duties updates, Foreign Trade Policy Update, international trade policy news, ftp update,ftp news`,
			},
			GST: {
				title: `Centaxonline | Latest News & Updates | Goods and Service Tax (GST)`,
				description: `Explore the latest news, top stories, most-viewed articles, recent notifications, circulars, and opinions on GST. Additionally, access past news and updates on GST, with the ability to filter documents by type, tags, duration, and more.`,
				keywords: `gst updates, gst news, gst new update, gst latest updates, gst latest news, gst update, gst news update, gst news today, new update in gst, gst articles, gst, gst newsletter`,
			},
			CUSTOMS: {
				title: `Centaxonline | Latest News & Updates | Customs`,
				description: `Discover the Latest News, Featured Articles, Most Popular Content, Recent Notifications, and New Circulars on Customs on Centaxonline. You can also access past news and updates about Customs, sorting through documents by type, tags, duration, and other filters.`,
				keywords: `custom news, customs updates, customs law news, custom duty news, custom exchange rate, customs news today, indirect tax statutes`,
			},
			EXCISE: {
				title: `Centaxonline | Latest News & Updates | Excise and Service Tax`,
				description: `Stay updated on Excise and Service Tax with Centaxonline, accessing the latest legislative changes, regulatory guidelines, and essential insights for tax professionals. Also, explore the latest news, articles, circulars and opinions on Excise and Service Tax, all filterable by document type, tags, and duration for easy access.`,
				keywords: `excise and service tax news, excise updates, excise news, service tax news, service tax updates`,
			},
			ARCHIVES: {
				title: `Centaxonline | Latest News & Updates Archives – GST | Customs | Excise | Service Tax | FTP`,
				description: `Explore Centaxonline's archives for a detailed history of tax updates across GST, Customs, Excise & Service Tax, and FTP. Our archive feature allows users to filter information by module type, document type, year, and specific time duration. Ideal for professionals and businesses looking for past data, policy changes, and tax guidelines to support research, compliance, and strategic planning.`,
				keywords: `gst news, gst updates, custom news, central excise news, gst rules, gst articles, good service tax`,
			},
			FTP: {
				title: `Centaxonline | Latest News & Updates | Foreign Trade Policy (FTP)`,
				description: `Access the Latest News, Featured Stories, Most Viewed, Latest Notifications, Latest Circulars, etc. on Foreign Trade Policy. Also, you can access archival news & updates on FTP, where you can sort documents using filters like document type, tags, duration, etc.`,
				keywords: `foreign trade policy updates, foreign trade policy news, foreign trade policy news and updates, latest news related to FTP, news related to FTP in india`,
			}
		},
	},

	ALL_CASE_LAWS: {
		title: `Centaxonline | Access 1.23 Lakh Case Laws on GST | Customs | Excise and Service Tax | FTP`,
		description: `Centaxonline's Case Law Library features a database of over 1,23,000 case laws from domains like GST, Customs, Excise and Service Tax, Foreign Trade Policy, etc. It covers rulings from the Supreme Court, High Courts, and Tribunals. With advanced search features offering more than 15 parameters, including appeal number and judge name, it provides quick and easy access to relevant case laws. Each record includes expertly drafted headnotes for ease of reference.`,
		keywords: `indirect tax case laws, gst case laws, case laws on gst, customs case laws, service tax case laws, excise case laws, ftp case laws`,
	},
	PRICING: {
		title: `Centaxonline | Pricing | Single & Multi User Plans`,
		description: `Become a part of the R.K. Jain legacy by subscribing to India's largest, most authentic & accurate legal database of GST, Customs, Excise & Service Tax Laws of India.`,
		keywords: `centax pricing, Centaxonline, gst excus`,
	},
	ABOUT_US: {
		title: `Centaxonline | About Us | Our Mission and Values`,
		description: `CENTAX comes from a family having nearly a century’s expertise in the field of Tax Laws. It has a notable history in the publication of legal literature on taxation and even had a Publishing House in England. CENTAX is now working on “Indirect Tax Portal” covering all Indirect Tax Laws with Allied Laws such as FTP, PMLA, FEMA etc.`,
		keywords: `centaxonline, about centax, Centaxonline, gst excus`,
	},
	CONTACT_US: {
		title: `Centaxonline | Contact Us`,
		description: `Speaking to a CENTAX Specialist is easy. Connect with us via Call or Mail, and we will be happy to address all your Indirect Tax needs.`,
		keywords: `contact centax, contact us, contact number centax, centax contact, call centax, email centax, whatsApp centax`,
	},
	GST_EXCUS: {
		title: `R.K. Jain's GST-ExCus | The Legacy Database of 80+ Years`,
		description: `GST ExCus is a research software with a legacy database of more than 80 years for Case Laws, Notifications & Circulars, Statutes, etc. The GST Department, Courts/Tribunals, and Firms trust GST-ExCus for their litigation, advisory & assessment work.`,
		keywords: `excus, gst excus, excus dvd, rk jain excus`,
	},
	BOOKSTORE: {
		title: `Centaxonline | Bookstore | R.K. Jain's Latest & Amended Books`,
		description: `Buy R.K. Jain's Latest, Authentic & Amended books on Indirect Taxes of India with Expedited/Fast Delivery | Cash on Delivery | Easy Returns | Dedicated Customer Support | Discounted Price.`,
		keywords: `gst books, custom law books, excise law books, centax bookstore, indirect tax books`,
	},

	CASE_LAWS: {
		FTP : {
			title: `Centaxonline | Foreign Trade Policy – Access 6000+ Case Laws on FTP`,
			description: `The Foreign Trade Policy (FTP) module includes a database of over 6,000 case laws pertinent to FTP from the Supreme Court, High Court, Tribunals, etc. It features advanced filtering capabilities with more than 15 parameters (including but not limited to appeal no. judge name, party name, citation-wise, court-wise, date-wise, etc.). Each case law is complemented with expertly crafted headnotes for ease of reference.`,
			keywords: `Foreign Trade Policy case laws, ftp case laws, FTP Legal Rulings, International Trade Law Precedents, Export-Import Case Studies, Trade Policy Legal Insights, FTP Court Decisions`,
		},
		GST: {
			title: `Centaxonline | Goods and Services Tax – Access 10,000+ Case Laws on GST`,
			description: `The Goods and Services Tax (GST) module provides 10,000+ case laws pertinent to GST from the Supreme Court, High Court, Tribunals, etc. Each case law includes expertly crafted 'headnotes' for relevant facts & observations. It features advanced filtering capabilities with more than 15 parameters (including but not limited to appeal no. judge name, party name, citation-wise, court-wise, date-wise, etc.).`,
			keywords: `latest high court judgement on gst, gst case laws, latest gst updates, gst cases, gst judgements, gst new updates, gst case, gst high court judgements, gst judgment, case laws on gst`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Access 33,000+ Case Laws on Customs`,
			description: `The Customs module offers over 33,000 case laws related to Customs from the Supreme Court, High Court, Tribunals, etc. Each case law comes with expertly written 'headnotes' detailing the relevant facts & observations. It also includes advanced filtering options with more than 15 parameters, such as appeal no., judge name, party name, citation-wise, court-wise, date-wise, and more.`,
			keywords: `Customs act case laws, customs case laws, case laws on customs, customs laws`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Access 94,000+ Case Laws`,
			description: `Excise and Service Tax module on Centaxonline provides you with a collection of 94000+ related Case Laws from the Supreme Court, High Courts, Tribunals, etc. Each case law has detailed headnotes that summarize key facts and insights. The platform also offers search functions with over 15 filters, such as appeal number, judge's name, party names, and sorting by citation, court, or date.`,
			keywords: `Excise Tax Case Laws, Service Tax Court Rulings, Excise Duty Judgments, Central Excise Tribunal Cases, Excise Case Laws, Central Excise Case Laws, Service Tax Case Laws`,
		},
	},
	ACT: {
		GST: {
			title: `Centaxonline | Goods & Services Tax – Access Amended Acts and Updates`,
			description: `Centaxonline gets you the most amended and updated Acts on Goods & Services Tax and other acts related to Laws under CGST, IGST, Compensation Cess, SGST and UTGST formatted section-wise with advanced filters to access particular details and pinpoint your tax research.`,
			keywords: `Gst Act, Gst act 2017, Goods and Services Tax Act, Goods and Services Tax Act 2017, Gst act amendment, Gst act 2023`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Access Updated & Amended 10+ Acts on Customs`,
			description: `Centaxonline provides updated and amended Acts related to the Customs Laws of India in a section-wise format. Get the information you want to access quickly with advanced search. Filters are available through the documents and sections within the platform.`,
			keywords: `Customs Act 1962, Customs Act, custom act, custom law in India, custom duty act`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Get Amended Acts till Date`,
			description: `Centaxonline provides you with the latest updated and Amended Acts specifically dealing with Excise & Service Tax. Organized section-wise with advanced filtering options, Centaxonline enables users to efficiently locate precise information to aid in specialized tax research and compliance.`,
			keywords: `amendments in service tax, service tax act, central excise acts, rules of service tax`,
		},
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Access Always Amended Acts`,
			description: `Centaxonline provides you with always updated and amended Foreign Trade (Development & Regulation) Act, Special Economic Zone (SEZ) Act, and Foreign Exchange Management Act (FEMA) in a section-wise format.`,
			keywords: `FTP Acts, Foreign Trade Policy Acts, Foreign Trade Development and regulation act 1992, foreign trade act 1992, Foreign Trade Act, sez in India, sez act 2005, special economy zone act 2005, foreign exchange management act, fema 1999`,
		},
	},
	RULE: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Access Always Amended Rules and Regulations`,
			description: `Centaxonline provides you with always amended and updated Foreign Trade (Regulation) Rules, 1993 and FEM (Export of Goods & Services) Regulations, 2015 in a rule-wise format`,
			keywords: `FTP Rules, Foreign Trade Policy rules, foreign trade regulations, regulation of foreign trade, international trade regulations, foreign trade rules 1993, FEM Regulations 2015`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Access Amended Rules and Regulations`,
			description: `Centaxonline provides you with updated and amended Goods & Services Tax Rules, backup up with advanced technology to dig through the rules & regulations of GST and related acts laws of IGST, UTGST, SGST and Compensation Cess in a rule-wise format`,
			keywords: `Gst rules, Gst rules and regulations, Gst rules 2017, Gst rules and regulations 2023, Goods and Services Tax regulations, Goods and Services Tax rules 2017`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Access Amended Rules and Regulations`,
			description: `Centaxonline provides the most updated Customs rules and regulations, reflecting real-time amendments. This ensures that you always have access to the latest rules issued by the Central Board of Indirect Taxes and Customs (CBIC)`,
			keywords: `custom rules, indian customs rules, customs rules, customs valuation rules, customs regulations, customs rules in India`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Latest Rules`,
			description: `Centaxonline provides you with the latest and amended Rules and Regulations related to Excise & Service Tax; powered by AI search and advanced filtering capabilities, Centaxonline offers records for selected Acts in a rule-wise format.`,
			keywords: `central excise rules 2016, central excise rules 1944, service tax rules, rule 6(3) of service tax rules, service tax rules 1994`,
		},
	},
	FORMS: {
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get all GST Forms`,
			description: `Centaxonline provides you with the most amended GST Forms and Formats. They are documents used by individuals and businesses to comply with Goods and Services Tax regulations, facilitating interactions with the CBIC for registrations, filings, and claims.`,
			keywords: `Gst form, gst forms list, gst form pdf, gst forms pdf, gst forms, gst forms download, gst return forms, all gst forms, form gst cmp-01, gst reg-05, gst return form`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Updated and Amended Forms`,
			description: `Get Access to Centaxonline's latest and amended Forms related to Customs. These documents facilitate customs clearance, ensure compliance with local laws, and involve the declaration of goods, payment of duties, and adherence to trade regulations.`,
			keywords: `forms of customs duty, custom forms, customs declaration forms, export declaration forms, excise duty forms`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Forms`,
			description: `Browse all Amended and Updated Excise & Service Tax-related Forms in one place with Centaxonline. These forms are documents used by business entities and individuals to adhere to India's Excise and Service Tax regulations, maintain statutory records, file returns, etc.`,
			keywords: `service tax forms, excise forms, central excise forms, central excise return forms, forms of service tax`,
		},
	},
	CIRCULAR: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Access 85+ FTP Circulars`,
			description: `Centaxonline offers you access to 85+ official FTP Circulars. These circulars are updated in real-time to ensure you get the most updated, amended, and authentic Circulars. Centaxonline allows users to navigate through a vast array of circulars with the convenience of filtering by Year and Act. Search for specific circulars to gain insights into policy updates, clarifications, and implementation guidelines. `,
			keywords: `FTP Circulars, Foreign Trade Policy circulars, International Trade Circulars, FTP Legal Circulars, Trade Regulation Circulars, FTP Advisory Notices`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Access 900+ GST Circulars on GST`,
			description: `Centaxonline offers you access to 900+ official GST Circulars. These circulars are updated in real-time to ensure you get the most updated, amended, and authentic Circulars. Centaxonline allows users to sift through the circulars with the ease of filtering by Year and Act. Search for specific circulars to gain insights into policy updates, clarifications, and implementation guidelines.`,
			keywords: `gst circular, gst circulars, TDS on gst circular, cbic circular gst, latest gst circular, gst refund circular, gst new circular 2023, gst circular 2024, gst revision`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Get Access to Over 2300 Official Circulars`,
			description: `Centaxonline offers access to over 2300 essential Customs Circulars, updated in real-time to ensure the latest and most accurate information. Centaxonline allows users to browse a wide selection of circulars, with controls to filter by Year and Act. This will enable users to easily search for specific circulars for in-depth information on policy changes, clarifications, and guidelines for implementation.`,
			keywords: `customs circulars, circulars on customs, CBIC circulars, customs public notice, customs trade notice`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Get 2800+ Circulars`,
			description: `Centaxonline offers access to 2800+ official Excise & Service Tax Circulars, updated in real-time so that You can refer to the most authentic and updated circulars. Also, Centaxonline provides you with filtering circulars by year and act so that you can access updates, implementation guidelines, and clarifications.`,
			keywords: `service tax circular 2016, central excise circulars, central board of excise and customs circulars, service tax circulars, service tax circulars and notifications`,
		},
	},
	NOTIFICATION: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Access 55+ FTP Notifications`,
			description: `Centaxonline brings you a complete collection of 55+ official FT Policy Notifications. They are updated in real-time to ensure you have access to the most current and authentic information. Centaxonline allows users to filter Notifications by Year and Act or search notifications by their numbers.`,
			keywords: `dgft notification, FTP notifications, Foreign Trade Announcements, FTP Latest notifications, Trade Facilitation Notifications`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Access 690+ GST Notifications`,
			description: `Centaxonline provides a comprehensive collection of 690+ official GST-related Notifications. These notifications are updated promptly to ensure you receive the most current and authentic information. Centaxonline enables users to filter notifications by Year and Act or search for notifications by their numbers.`,
			keywords: `cbic notification, gst latest notifications, gst notification, gst notification 2023, cbic gst notification, gst notifications and circulars, notification gst`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Get 12,000+ Notifications in One Place`,
			description: `Centaxonline offers a detailed collection of 12,000+ official Customs-related Notifications. These notifications are continually updated to provide the most current and authentic information. Centaxonline allows users to filter notifications by Year and Regulation or search for notifications by their numbers. This access helps a tax researcher conduct thorough and accurate analyses.`,
			keywords: `cbic tax information, customs notifications, cbic customs notifications, cbec gov customs notifications, custom notifications, custom exchange rate notification, custom exchange rate notification today, cbic notifications`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Access Over 7000 Official Notifications`,
			description: `Centaxonline gets you an extensive collection of over 7000+ official Excise & Service Tax notifications. These notifications are regularly updated to provide the latest, most accurate, and authentic information. Users can filter by Year and Act or search by their numbers and notification Types and search through the database directly subject-wise.`,
			keywords: `service tax notification, central excise notification, excise notification`,
		},
	},
	ARTICLES: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – 55+ Expertly Curated Articles and Analysis`,
			description: `Access in-depth insights with over 55+ articles and analysis on Foreign Trade Policy. Authored by experts, Centaxonline presents a wealth of knowledge that keeps you informed on the latest developments in FTP. You can filter content by authors and year or search manually for precisely what you need. Whether seeking expert opinions or detailed analyses, Centaxonline is your go-to resource for comprehensive and up-to-date information on all aspects of Foreign Trade Policy.`,
			keywords: `FTP Articles, Foreign Trade Policy articles, FTP Analysis, practical guide to Foreign Trade Policy, Analysis of Foreign Trade Policy`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – 960+ Expertly Curated Articles and Analysis`,
			description: `Access in-depth insights with over 960+ articles and Goods and Services Tax analyses. Authored by experts, Centaxonline presents a wealth of knowledge that keeps you informed on the latest developments in GST. You can filter content by authors and year or search manually for pinpoint information. Whether seeking expert opinions or detailed analyses, Centaxonline is your go-to resource for updated and thorough information on all Goods and Services Tax aspects.`,
			keywords: `gst article, gst related articles, article on gst bill, centax gst, gst council article`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Access 340+ Experts Articles on Customs`,
			description: `Explore a rich collection of over 340+ articles and analyses on Customs written by experts in the field. Centaxonline delivers essential knowledge, ensuring you stay current with the latest Customs trends. You can sort content by author and year or conduct targeted searches for specific details. For expert insights and thorough analyses, Centaxonline is your essential resource for up-to-date and updated information on Customs.`,
			keywords: `customs articles, additional duty of customs, prevention of money laundering act, custom duty articles`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – 300+ Articles Curated by Experts`,
			description: `Get in-depth of Excise and Service Tax with a collection of over 300 articles curated by experts in the field. Access the Articles on the latest developments and happenings in the world of taxes. Get the exact information you want with filters by Author, Year of Publication and AI search option within the platform.`,
			keywords: `service tax articles India, excise duty articles, import duty, export duty, central excise`,
		},
	},
	TARIFF: {
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get Access to Latest GST Rates, and GST Tariff`,
			description: `Centaxonline provides access to the GST Tariff for Goods and Services, which details HSN-wise CGST, SGST/UTGST, and IGST rates. Discover the applicable GST Tariff for Goods & Services, whether IGST or combined CGST + SGST, through chapter-wise or heading-wise searches.`,
			keywords: `HSN code, gst rate, gst rates, hsn code with gst rate, gst rate finder, gst rates in India, gst rates list, gst slab rates, gst tax rate, gst slabs, new gst rate list, tariff, gst tariff, gst tariff schedule, gst tariff for goods`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Explore all Customs Tariff and Rates of Duties`,
			description: `Centaxonline provides access to the Rates of Custom Duties and Tariffs for Import and Export in one place chapter-wise, powered by advanced filters and search by chapters and subjects. It allows users to quickly access and compare import and export duties for various goods globally.`,
			keywords: `cbic exchange rate, customs tariff, customs tariff 2023, custom rate, custom tariff, custom duty rates, cbic rates, cbic rate`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax Tariffs | Chapter/Heading No.`,
			description: `Excise & Service Tax Tariffs provide the latest rates of central excise, which are updated in real-time. Start your search chapter-wise or heading-wise.`,
			keywords: `central excise tariff, excise tariff, excise duty tariff`,
		},
	},
	AAYAT_NIRYAT_FORMS: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Aaayat Niryat Forms`,
			description: `Ayaat Niryaat forms are a collection of official documents required for import from and export in India under the Foreign Trade Policy (FTP). They are application forms and declarations used by individuals and businesses to interact with the Directorate General of Foreign Trade (DGFT). Centaxonline provides you with amended Aayaat Niryaat Forms.`,
			keywords: `aayat niryat, anf full form, dgft application form, anf, form 13 in export, anf 1b in word format`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get Access to Latest GST Rates and GST Tariff`,
			description: `Centaxonline provides access to the GST Tariff for Goods and Services, which details HSN-wise CGST, SGST/UTGST, and IGST rates. Discover the applicable GST Tariff for Goods & Services, whether IGST or combined CGST + SGST, through chapter-wise or heading-wise searches.`,
			keywords: `HSN code, gst rate, gst rates, hsn code with gst rate, gst rate finder, gst rates in India, gst rates list, gst slab rates, gst tax rate, gst slabs, new gst rate list, tariff, gst tariff, gst tariff schedule, gst tariff for goods`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Explore all Customs Tariff and Rates of Duties`,
			description: `Centaxonline provides access to the Rates of Custom Duties and Tariffs for Import and Export in one place chapter-wise, powered by advanced filters and search by chapters and subjects. It allows users to quickly access and compare import and export duties for various goods globally.`,
			keywords: `cbic exchange rate, customs tariff, customs tariff 2023, custom rate, custom tariff, custom duty rates, cbic rates, cbic rate`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax Tariffs | Chapter/Heading No.`,
			description: `Excise & Service Tax Tariffs provide the latest rates of central excise, which are updated in real-time. Start your search chapter-wise or heading-wise.`,
			keywords: `central excise tariff, excise tariff, excise duty tariff`,
		},
	},
	APPENDICES: {
		FTP: {
			title: `CentaxOnline | Foreign Trade Policy – Access 90+ Essential FTP Appendices`,
			description: `FTP Appendices are the documents attached to the main Handbook of Procedures document. These appendices provide additional information, forms, and templates relevant to various import and export procedures. Centaxonline covers always amended 90+ FTP appendices.`,
			keywords: `New: Foreign Trade Policy appendix, Foreign Trade Policy appendices, town of export excellence, agriculture export policy, certificate of origin for export`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get Access to Latest GST Rates and GST Tariff`,
			description: `Centaxonline provides access to the GST Tariff for Goods and Services, which details HSN-wise CGST, SGST/UTGST, and IGST rates. Discover the applicable GST Tariff for Goods & Services, whether IGST or combined CGST + SGST, through chapter-wise or heading-wise searches.`,
			keywords: `HSN code, gst rate, gst rates, hsn code with gst rate, gst rate finder, gst rates in India, gst rates list, gst slab rates, gst tax rate, gst slabs, new gst rate list, tariff, gst tariff, gst tariff schedule, gst tariff for goods`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Explore all Customs Tariff and Rates of Duties`,
			description: `Centaxonline provides access to the Rates of Custom Duties and Tariffs for Import and Export in one place chapter-wise, powered by advanced filters and search by chapters and subjects. It allows users to quickly access and compare import and export duties for various goods globally.`,
			keywords: `cbic exchange rate, customs tariff, customs tariff 2023, custom rate, custom tariff, custom duty rates, cbic rates, cbic rate`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax Tariffs | Chapter/Heading No.`,
			description: `Excise & Service Tax Tariffs provide the latest rates of central excise, which are updated in real-time. Start your search chapter-wise or heading-wise.`,
			keywords: `central excise tariff, excise tariff, excise duty tariff`,
		},
	},
	FTP: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy (FTP) – Authentic and Amended`,
			description: `Access authentic and updated versions of the Foreign Trade Policy introduced by the Central Government, including the Handbook of Procedures and Aayat Niryat Forms. The FTP focuses on trade facilitation, legal frameworks, duty exemption schemes, export promotion, and digitalising trade processes. It details the FTP's legal basis, operational duration, amendments provisions, and comprehensive measures for easing business operations, enhancing transparency, and promoting exports. The policy also emphasises the role of the Director General of Foreign Trade (DGFT) in facilitating trade, the importance of technology and infrastructure in trade facilitation, and the initiatives for resolving trade disputes and supporting exporters through various schemes and digital platforms.`,
			keywords: `Foreign Trade Policy, Aayat Niryat Forms, FTP Handbook of Procedures, Indian foreign trade policy, exim policy, indian foreign trade policy, export import policy of India, foreign trade policy 2023, ftp 2023, India foreign trade policy`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get Access to Latest GST Rates and GST Tariff`,
			description: `Centaxonline provides access to the GST Tariff for Goods and Services, which details HSN-wise CGST, SGST/UTGST, and IGST rates. Discover the applicable GST Tariff for Goods & Services, whether IGST or combined CGST + SGST, through chapter-wise or heading-wise searches.`,
			keywords: `HSN code, gst rate, gst rates, hsn code with gst rate, gst rate finder, gst rates in India, gst rates list, gst slab rates, gst tax rate, gst slabs, new gst rate list, tariff, gst tariff, gst tariff schedule, gst tariff for goods`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Explore all Customs Tariff and Rates of Duties`,
			description: `Centaxonline provides access to the Rates of Custom Duties and Tariffs for Import and Export in one place chapter-wise, powered by advanced filters and search by chapters and subjects. It allows users to quickly access and compare import and export duties for various goods globally.`,
			keywords: `cbic exchange rate, customs tariff, customs tariff 2023, custom rate, custom tariff, custom duty rates, cbic rates, cbic rate`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax Tariffs | Chapter/Heading No.`,
			description: `Excise & Service Tax Tariffs provide the latest rates of central excise, which are updated in real-time. Start your search chapter-wise or heading-wise.`,
			keywords: `central excise tariff, excise tariff, excise duty tariff`,
		},
	},
	HANDBOOK_PROCEDURE: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy | Handbook of Procedures – Amended and Updated`,
			description: `Centaxonline's FTP Handbook of Procedures outlines the steps and guidelines for implementing India's Foreign Trade Policy 2023. It is a rulebook that exporters and other stakeholders must follow to comply with the policy and avail of the benefits. Centaxonline offers you always amended FTP Handbook of Procedures`,
			keywords: `foreign trade policy handbook of procedures, handbook of procedures foreign trade policy, Handbook of Procedures Volume 1 & 2, DGFT Handbook of Procedures,  Foreign Trade Policy Procedures, Operational Guidelines under FTP, Amendments to the FTP Handbook`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get Access to Latest GST Rates and GST Tariff`,
			description: `Centaxonline provides access to the GST Tariff for Goods and Services, which details HSN-wise CGST, SGST/UTGST, and IGST rates. Discover the applicable GST Tariff for Goods & Services, whether IGST or combined CGST + SGST, through chapter-wise or heading-wise searches.`,
			keywords: `HSN code, gst rate, gst rates, hsn code with gst rate, gst rate finder, gst rates in India, gst rates list, gst slab rates, gst tax rate, gst slabs, new gst rate list, tariff, gst tariff, gst tariff schedule, gst tariff for goods`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Explore all Customs Tariff and Rates of Duties`,
			description: `Centaxonline provides access to the Rates of Custom Duties and Tariffs for Import and Export in one place chapter-wise, powered by advanced filters and search by chapters and subjects. It allows users to quickly access and compare import and export duties for various goods globally.`,
			keywords: `cbic exchange rate, customs tariff, customs tariff 2023, custom rate, custom tariff, custom duty rates, cbic rates, cbic rate`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax Tariffs | Chapter/Heading No.`,
			description: `Excise & Service Tax Tariffs provide the latest rates of central excise, which are updated in real-time. Start your search chapter-wise or heading-wise.`,
			keywords: `central excise tariff, excise tariff, excise duty tariff`,
		},
	},
	GSTN: {
		GST: {
			title: `Centaxonline | GST | Get All GSTN Updates Live at Your Fingertips`,
			description: `Get the latest GSTN updates and expert insights with Centaxonline, designed for professionals and businesses committed to staying ahead in GST compliance. Explore our in-depth analysis and updates to manage your GST processes effectively and enhance operations.`,
			keywords: `recent changes in gst, latest gst updates, gst latest updates, new changes in gst, gst new updates`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Explore all Customs Tariff and Rates of Duties`,
			description: `Centaxonline provides access to the Rates of Custom Duties and Tariffs for Import and Export in one place chapter-wise, powered by advanced filters and search by chapters and subjects. It allows users to quickly access and compare import and export duties for various goods globally.`,
			keywords: `cbic exchange rate, customs tariff, customs tariff 2023, custom rate, custom tariff, custom duty rates, cbic rates, cbic rate`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax Tariffs | Chapter/Heading No.`,
			description: `Excise & Service Tax Tariffs provide the latest rates of central excise, which are updated in real-time. Start your search chapter-wise or heading-wise.`,
			keywords: `central excise tariff, excise tariff, excise duty tariff`,
		},
	},
	COMMENTARIES: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Commentary on Custom Law Practice and Procedure`,
			description: `Centaxonline gives you access to in-depth commentaries on Foreign Trade Policy authored by leading experts in the field. The commentary is structured into multiple chapters, simplifying the process of finding relevant discussions and related analyses.`,
			keywords: `foreign trade policy 2024, ftp 2024, new ftp policy 2024, latest foreign trade policy, foreign trade policy in India, foreign trade policy`,
		},
		GST: {
			title: `Centaxonline | Goods & Services Tax – Expert's Commentaries on GST`,
			description: `Centaxonline gives you access to in-depth commentaries on Goods & Services Tax authored by leading experts in the field. Commentaries are structured into multiple chapters, simplifying finding relevant discussions and related analyses.`,
			keywords: `gst commentary, gst commentaries, gst opinions, commentaries on gst, centax gst commentaries`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs – Commentary on Customs Law | Practice | Procedures`,
			description: `Centaxonline offers in-depth insights on Customs through expert-written commentaries organized into well-defined chapters for easy access to specific topics and analyses. You can find relevant discussions and commentaries on Custom Law Practices and Procedures here.`,
			keywords: `customs law, custom duty, custom commentaries, customs valuation rules, warehousing in customs, commentary on customs act 1962`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – 50+ Expert’s Commentaries`,
			description: `Get Commentaries on Central Excise Law and Service Tax Ready Reckoner crafted by experts. Commentaries are organized point-wise to allow access to the details of Excise & Service Tax.`,
			keywords: `Excise Tax commentaries, commentaries on excise and service tax, service tax commentaries, excise commentaries`,
		},
	},
	FAQ: {
		GST: {
			title: `Centaxonline | Goods & Services Tax – Get The Answer to All Your GST Queries`,
			description: `Centaxonline presents a collection of GST FAQs covering various subjects and topics. Our platform answers common questions clearly, enriching your knowledge of GST regulations and practices.`,
			keywords: `gst queries, gst faqs, faqs on gst, gst queries, gst help, gst faq, cbic faq`,
		},
		CUSTOMS: {
			title: ``,
			description: ``,
			keywords: ``,
		},
		EXCISE_SERVICE_TAX: {
			title: ``,
			description: ``,
			keywords: ``,
		},
	},
	FINANCE_BILL: {
		GST: {
			title: `Centaxonline | GST | Finance Bill | Authentic Documents from the Ministry of Finance`,
			description: `Centaxonline provides coverage of the finance bills introduced in the Parliament since 1961. The platform offers access to a wide range of documents associated with each bill, including the finance minister's budget speech, the text of the finance bill, detailed notes on clauses, and the explanatory memorandum. Users can easily browse and access information on the Finance Bill organized by year.`,
			keywords: `Finance Bill, Latest Finance Bill, Finance Bill 2024, Budget speech, memorandum of Finance bill`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs | Browse all Finance Bills from 1961 to 2024`,
			description: `Centaxonline offers access to Finance Bills introduced in Parliament since 1961, including the Finance Minister's budget speech, the text of the Finance Bill, notes on clauses, and the explanatory memorandum. Users can browse and access Finance Bill information organized by year, ensuring efficient research and analysis in Customs law developments.`,
			keywords: `Finance Bill, Latest Finance Bill, Finance Bill 2022, Budget speech, memorandum of Finance bill`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Collection of All Finance Bills from 1961-2023`,
			description: `Centaxonline provides coverage of the finance bills introduced in the Parliament since 1961. The platform offers access to a wide range of documents associated with each bill, including the finance minister's budget speech, the text of the finance bill, detailed notes on clauses, and the explanatory memorandum. Users can easily browse and access information on the Finance Bill organized by year.`,
			keywords: `Finance Bill, Latest Finance Bill, Finance Bill 2022, Budget speech, memorandum of Finance bill`,
		},
	},
	FINANCE_ACTS: {
		FTP: {
			title: `Centaxonline | Foreign Trade Policy – Access Always Amended Acts`,
			description: `Centaxonline provides you with always updated and amended Foreign Trade (Development & Regulation) Act, Special Economic Zone (SEZ) Act, and Foreign Exchange Management Act (FEMA) in a section-wise format.`,
			keywords: `New: FTP Acts, Foreign Trade Policy Acts, Foreign Trade Development and regulation act 1992, foreign trade act 1992, Foreign Trade Act, sez in India, sez act 2005, special economy zone act 2005, foreign exchange management act, fema 1999`,
		},
		GST: {
			title: `Centaxonline | GST | Get All the Finance Acts from 1961 Released by Ministry of Finance`,
			description: `Centaxonline provides detailed archives of Finance Acts from 1961 till date, featuring the final text, the finance minister's speech, clause annotations, and explanatory memorandum. Information is organized Year-Wise for easy access to specific documents.`,
			keywords: `Finance Act, Previous Finance Acts, Latest Finance Act, Finance Act 2024, Amendments in Finance Act, Latest amended Finance Act`,
		},
		CUSTOMS: {
			title: `Centaxonline | Customs | Get all Finance Acts at your Fingertips`,
			description: `Centaxonline hosts a detailed collection of Finance Acts dating from 1961 to the present, including the final text, the Finance Minister's speech, annotations on clauses, and the explanatory memorandum. The information is arranged by year, simplifying the locating of specific documents for users interested in customs law.`,
			keywords: `Act, Previous Finance Acts, Latest Finance Act, Finance Act 2022, Amendments in Finance Act, Latest amended Finance Act`,
		},
		EXCISE_SERVICE_TAX: {
			title: `Centaxonline | Excise & Service Tax – Get All the Finance Acts from 1961-2023`,
			description: `Centaxonline provides detailed archives of Finance Acts from 1961 till date, featuring the final text, the finance minister's speech, clause annotations, and explanatory memorandum. Information is organized Year-Wise for easy access to specific documents.`,
			keywords: `Finance Act, Previous Finance Acts, Latest Finance Act, Finance Act 2022, Amendments in Finance Act, Latest amended Finance Act`,
		},
	},
	GST_COUNCIL_MEETING: {
		GST: {
			title: `Centaxonline | GST | Detailed Coverage of GST Council Meetings`,
			description: `Centaxonline offers you the latest updates on GST Council meetings, including detailed agendas, Minutes of Meetings, and press releases.`,
			keywords: `Gst council meeting, latest gst council meeting, gst council meeting live, gst meeting update, gst council meeting minutes, gst council meeting live updates, gst council meetings dates`,
		},
		CUSTOMS: {
			title: ``,
			description: ``,
			keywords: ``,
		},
		EXCISE_SERVICE_TAX: {
			title: ``,
			description: ``,
			keywords: ``,
		},
	},

	LEGAL: {
		TERMS_AND_CONDITIONS: {
			title: `Centaxonline | Terms and Conditions`,
			description: `By accessing centaxonline.com, you agree to abide by the Company's terms and conditions. Read now!`,
			keywords: `centax terms and condition, terms and condition`,
		},
		COPYRIGHTS_POLICY: {
			title: `Centaxonline | Copyright Policy`,
			description: `Centaxonline.com contains multimedia, including images and videos, protected by the Copyright Act of 1957. All copyright policies are given here.`,
			keywords: `copyright policies, centax copyright`,
		},
		PRIVACY_POLICY: {
			title: `Centaxonline | Privacy Policy`,
			description: `Centaxonline.com respects your privacy and is committed to protecting it with our privacy policies. Read all privacy policies here.`,
			keywords: `privacy terms, privacy policy, centax privacy policy`,
		},
		RETURN_POLICY: {
			title: `Centaxonline | Return Policy`,
			description: `We will accept return or refund requests if they meet our return criteria. Read all the conditions you need to fulfil while or before making a return request.`,
			keywords: `centax return policies, book return policies, refund policies`,
		},
		SHIPPING_POLICY: {
			title: `Centaxonline | Shipping Policy`,
			description: `Centax delivers GST-ExCus DVD, GST, Customs, and Service-tax books. Read all shipping policies of Centaxonline.com here.`,
			keywords: `centax shipping policies, shipping conditions, shipping charges`,
		},
		DISCLAIMER: {
			title: `Centaxonline | Disclaimer`,
			description: `All the contents of Centaxonline.com are only for general information and use. By using this website, you agree to some conditions given here.`,
			keywords: `centax disclaimer, legal disclaimer, centax policies`,
		},
	},

	TOOLS: {
		CUSTOMS_DUTY_CALCULATOR: {
			title: `Customs Tariff Tool | Simplify Tariff Management with Centaxonline`,
			description: `Access the all-new Customs Tariff Tool on Centaxonline.com. Calculate import duties, view tariff rates, and explore policy conditions at the 8-digit HSN level with real-time updates. Simplify customs classifications and ensure compliance today!`,
			keywords: `custom duty calculator, duty calculator, custom duty calculator india, customs duty calculator india, import duty calculator india, custom duty, custom duty calculation, customs calculator, know your import duty, know your customs duty, import duty calculation, indian customs duty calculator, custom calculator, duty calculator customs`,
		}
	}
}
