export const filemodifyCSS = `
a[href] {
	color: Blue;
	text-decoration: underline;
}
/*
sub, sup {
	vertical-align: super;
	font-size: smaller;
	line-height: inherit;
	position: inherit;
}
*/
`

export const filecontentCSS = `
.dgthreedigest {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: justify;
  }
  .dgbullettext {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-family: Verdana;
	text-align: justify;
  }
  .threedigest {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 8mm;
	margin-right: 8mm;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: justify;
  }
  .bullettext {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 8mm;
	margin-right: 8mm;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-family: Verdana;
	text-align: justify;
  }
  .centerbox {
	display: block;
	margin-top: 12pt;
	margin-bottom: 12pt;
	text-align: center;
	text-indent: 0;
	color: #e4e4e4;
  }
  .citiation {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 4pt;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
  }
  .court {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-transform: uppercase;
	text-align: center;
  }
  .party {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
  }
  .verse {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
	font-style: italic;
  }
  .member {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 11pt;
	font-family: Verdana;
	text-align: center;
	text-transform: uppercase;
  }
  .hnnote {
	display: block;
	text-indent: 0;
	margin-top: 18pt;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: justify;
  }
  .h1 {
	display: block;
	text-indent: 0;
	margin-top: 4pt;
	margin-bottom: 0;
	font-size: 11pt;
	font-weight: 700;
	font-family: "Verdana";
	text-align: left;
  }
  hr {
	margin-top: -1pt;
  }
  .tx {
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .rightbox {
	display: block;
	margin-top: 12pt;
	margin-bottom: 12pt;
	text-align: right;
	text-indent: 0;
  }
  .leftstar {
	display: block;
	margin-left: 8mm;
	margin-bottom: 4pt;
	text-indent: 0;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .quote {
	display: block;
	margin-left: 8mm;
	margin-bottom: 4pt;
	text-indent: 0;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent1 {
	display: block;
	margin-left: 10mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent1A {
	display: block;
	margin-left: 14mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent2 {
	display: block;
	margin-left: 22mm;
	margin-bottom: 4pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent2A {
	display: block;
	margin-left: 24mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent3 {
	display: block;
	margin-left: 32mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent4 {
	display: block;
	margin-left: 40mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent5 {
	display: block;
	margin-left: 48mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent6 {
	display: block;
	margin-left: 56mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent3A {
	display: block;
	margin-left: 34mm;
	margin-bottom: 4pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .stathead1 {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-indent: 0;
	font-size: 11pt;
	font-family: Verdana;
	font-weight: 700;
	text-transform: uppercase;
  }
  .stathead2 {
	display: block;
	margin-top: 6pt;
	margin-bottom: 6pt;
	text-align: center;
	text-indent: 0;
	font-size: 10pt;
	font-family: Verdana;
	font-weight: 700;
	text-transform: uppercase;
  }
  .arthead1 {
	display: block;
	text-indent: 0;
	margin-top: 6pt;
	margin-bottom: 6pt;
	font-size: 13pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .arthead2 {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 6pt;
	font-size: 11pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .artrighttext {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 10pt;
	font-family: "Verdana";
	text-align: right;
	text-transform: uppercase;
	font-weight: 700;
  }
  .artrighttext1 {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 6pt;
	font-size: 10pt;
	font-family: "Verdana";
	text-align: right;
  }
  .artcircle {
	display: block;
	margin-top: 6pt;
	margin-bottom: 0;
	text-align: right;
	text-indent: 0;
	font-size: 19pt;
  }
  .large {
	display: inline;
	text-indent: 0;
	font-size: 15pt;
  }
  .allborder table {
	border: 1px solid #000;
  }
  .allborder td {
	border: 1px solid #000;
  }
  .allborder {
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
	border-collapse: collapse;
	width: 100%;
  }
  .allborder1 {
	border: 1px solid #000;
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
	border-collapse: collapse;
  }
  .topbotrule {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
  }
  .toprule {
	border-top: 1px solid #000;
  }
  .botrule {
	border-bottom: 1px solid #000;
  }
  .leftrule {
	border-left: 1px solid #000;
  }
  .rightrule {
	border-right: 1px solid #000;
  }
  .leftrightrule {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .toprightrule {
	border-top: 1px solid #000;
	border-right: 1px solid #000;
  }
  .topleftrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
  }
  .topleftrightrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .botrightrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
  }
  .botleftrule {
	border-bottom: 1px solid #000;
	border-left: 1px solid #000;
  }
  .botrightlefttrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
  }
  .blocktext {
	margin-bottom: 4pt;
	text-align: justify;
	background-color: gray;
	font-size: 6pt;
	font-family: "Verdana";
	text-transform: uppercase;
	float: right;
	padding: 2px;
  }
  .stx {
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 6pt;
	font-family: "Verdana";
  }
  div.AfterAppendix,
  li.AfterAppendix,
  p.AfterAppendix {
	border: medium none;
	font-family: AsterV;
	font-size: 9.5pt;
	font-weight: 700;
	margin: 0 0 8pt;
	padding: 0;
	text-align: center;
  }
  div.Chapter,
  li.Chapter,
  p.Chapter {
	border: medium none;
	color: #000;
	font-family: Times;
	font-size: 9.5pt;
	font-style: italic;
	font-weight: 700;
	margin: 12pt 0 3pt;
	padding: 0;
	text-align: center;

	text-transform: uppercase;
  }
  div.AfterChapter,
  li.AfterChapter,
  p.AfterChapter {
	font-family: Times;
	font-size: 9.5pt;
	font-weight: 700;
	margin: 0 0 6pt;
	text-align: center;
	text-transform: uppercase;
  }
  div.MsoBodyText,
  li.MsoBodyText,
  p.MsoBodyText {
	font-family: "Verdana";
	font-size: 11pt;
	font-weight: 700;
	margin: 12pt 0 2pt;
	text-align: center;
  }
  div.BoldHeading,
  li.BoldHeading,
  p.BoldHeading {
	font-family: AsterV;
	font-size: 9.5pt;
	font-weight: 700;
	margin: 2pt 0;
	text-align: justify;
  }
  div.CenteredHeading1,
  li.CenteredHeading1,
  p.CenteredHeading1 {
	mso-style-name: "Centered Heading 1";
	mso-style-next: Normal;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 12pt;
	margin-left: 0;
	text-align: center;
	mso-pagination: widow-orphan;
	tab-stops: -1in -0.9in -0.6in -0.3in 0 0.3in 0.6in 0.9in 1.2in 1.5in 1.8in 2.1in
	  2.4in 2.7in 3in 3.3in 3.6in 3.9in 4.2in 4.5in 4.8in 5.1in 5.4in 5.7in 6in;
	font-size: 14pt;
	mso-bidi-font-size: 10pt;
	font-family: "Verdana";
	mso-fareast-font-family: "Verdana";
	color: #00f;
	mso-ansi-language: EN-GB;
	mso-fareast-language: EN-GB;
	font-weight: 700;
	mso-bidi-font-weight: normal;
  }
  .logo-name {
	font-family: "Verdana Black", Gadget, sans-serif;
	font-size: 35px;
	font-weight: 400;
	color: #545454;
	text-transform: uppercase;
  }
  .logo-text {
	font-size: 13px;
	font-weight: 700;
	color: #545454;
	width: 300px;
	text-align: center;
	font-family: "Verdana", Times, serif;
  }
  .logo-text ul {
	list-style-position: inside;
	list-style-type: none;
	margin: 0;
	padding: 0 10px 0 0;
	overflow: hidden;
  }
  .logo-text ul li {
	float: left;
	text-align: center;
	margin-left: 10px;
  }
  .logo-text ul .center-text {
	padding-left: 100px;
	padding-top: 3px;
  }
  .logo-text .taxmann-url {
	font-size: 21px;
	font-weight: 700;
	color: #545454;
	border-bottom: 1px solid #545454;
	padding-bottom: 3px;
	margin-bottom: 3px;
  }
  .DTAAHead {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 12pt;
	font-size: 16pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .DTAA11pt {
	display: block;
	text-indent: 0;
	margin-top: 12pt;
	margin-bottom: 0;
	font-size: 11pt;
	margin-bottom: 4pt;
	font-family: "Verdana";
	text-align: center;
	text-transform: uppercase;
  }
  .DTAA10pt {
	display: block;
	text-indent: 0;
	margin-top: 16pt;
	margin-bottom: 0;
	font-size: 10pt;
	margin-bottom: 16pt;
	font-family: "Verdana";
	text-align: justify;
	text-transform: uppercase;
	font-weight: 700;
  }
  .cirhead {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-indent: 0;
	font-size: 12pt;
	font-family: "Verdana";
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 4pt;
  }
  .cirhead1 {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-indent: 0;
	font-size: 11pt;
	font-family: "Verdana";
	font-weight: 700;
	margin-bottom: 6pt;
	text-transform: uppercase;
  }
  body {
	margin: 0;
	padding: 0;
	font-family: Verdana;
  }
  .html-view .container {
	width: 600px;
	margin: 0 auto;
	border: 1px solid #d2d2d2;
  }
  .html-view .container strong {
	font-family: Verdana;
	font-size: 17px;
	color: #2e2e2e;
	text-align: center;
  }
  .html-view .container h2 {
	font-family: Verdana;
	font-size: 17px;
	text-align: center;
	font-weight: 400;
	margin: 20px;
	color: #08476e;
  }
  .html-view .container h1 {
	font-family: Verdana;
	font-size: 21px;
	text-align: center;
	font-weight: 700;
	margin: 20px;
	color: #000;
  }
  .login-box {
	width: 400px;
	margin: 0 auto;
	border: 1px solid #d2d2d2;
	margin-top: 30px;
	padding: 20px;
	overflow: hidden;
	margin-bottom: 30px;
  }
  .login-box-textname {
	font-family: Verdana;
	font-size: 16px;
	text-align: right;
	display: block;
	float: left;
	width: 99px;
	margin: 4px 8px 0 0;
	font-weight: 400;
	color: #4e4e4e;
  }
  .textname {
	font-family: Verdana;
	font-size: 16px;
	text-align: right;
	display: block;
	float: left;
	width: 128px;
	margin: 4px 8px 0 0;
	font-weight: 400;
	color: #4e4e4e;
  }
  .w264p {
	width: 264px;
  }
  .w130p {
	width: 990px;
  }
  .fl-l {
	float: left;
  }
  .w100 {
	width: 100%;
  }
  .error-message {
	font-family: Verdana;
	font-size: 14px;
	text-align: center;
	padding-right: 20px;
	padding-top: 10px;
	font-weight: 400;
	float: right;
	color: #e0281c;
  }
  .red-strick {
	font-family: Verdana;
	font-size: 14px;
	text-align: left;
	font-weight: 400;
	color: #e0281c;
  }
  .input-text {
	width: 250px;
	color: #333;
	font-size: 12px;
	margin-bottom: 16px;
	border: #bfbdbd 1px solid;
	padding: 6px;
	height: 22px;
  }
  .input-text-pincode {
	width: 95px;
	color: #333;
	font-size: 12px;
	margin-bottom: 16px;
	border: #bfbdbd 1px solid;
	padding: 6px;
	height: 22px;
  }
  .input-text-address {
	width: 250px;
	color: #333;
	font-size: 12px;
	margin-bottom: 16px;
	border: #bfbdbd 1px solid;
	padding: 6px;
	height: 80px;
  }
  .marl5 {
	margin-left: 10px;
  }
  .submit-area {
	width: 63%;
	margin: 0 auto;
	float: right;
	margin-top: -7px;
  }
  .button {
	background-color: #2b97e3;
	border: none;
	color: #fff;
	padding: 7px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 3px;
	margin: 4px 2px;
	font-family: Verdana;
	font-size: 17px;
	cursor: pointer;
  }
  .button4 {
	background-color: #bcbcbc;
	color: #fff;
  }
  .select-dropdown {
	border: #bfbdbd 1px solid;
	color: #888;
	margin-bottom: 15px;
	height: 34px;
	outline: 0;
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	width: 107px;
	background: url(../images/right-arrow.png);
	background-position: right;
	background-repeat: no-repeat;
  }
  select {
	padding: 3px;
	border: #bfbdbd 1px solid;
	color: #888;
	margin-bottom: 15px;
	height: 34px;
	outline: 0;
	display: inline-block;
	width: 265px;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
	select {
	  padding-right: 18px;
	}
  }
  /*
  label {
	position: relative;
  }
  label:after {
	content: ">";
	font: 18px Consolas, monospace;
	color: #000;
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	right: 8px;
	top: 0;
	padding: 0 0 2px;
	border-bottom: 1px solid #d6d6d6;
	position: absolute;
	pointer-events: none;
  }
  label:before {
	content: "";
	right: 6px;
	top: 0;
	width: 20px;
	height: 34px;
	position: absolute;
	pointer-events: none;
	display: block;
  }
  */
  .pinname {
	font-family: Verdana;
	font-size: 16px;
	text-align: right;
	display: block;
	float: left;
	width: 40px;
	margin: 4px 8px 0 0;
	font-weight: 400;
	color: #4e4e4e;
  }
  @media only screen and (max-width: 800px) {
	#no-more-tables table,
	#no-more-tables tbody,
	#no-more-tables td,
	#no-more-tables th,
	#no-more-tables thead,
	#no-more-tables tr {
	  display: block;
	}
	#no-more-tables thead tr {
	  position: absolute;
	  top: -9999px;
	  left: -9999px;
	}
	#no-more-tables tr {
	  border: 1px solid #ccc;
	}
	#no-more-tables td {
	  border: none;
	  border-bottom: 1px solid #eee;
	  position: relative;
	  padding-left: 50%;
	  white-space: normal;
	  text-align: left;
	}
	#no-more-tables td:before {
	  position: absolute;
	  top: 6px;
	  left: 6px;
	  width: 45%;
	  padding-right: 10px;
	  white-space: nowrap;
	  text-align: left;
	  font-weight: 700;
	}
	#no-more-tables td:before {
	  content: attr(data-title);
	}
  }
  .gstph1 {
	display: block;
	text-indent: 0;
	margin-top: 4pt;
	margin-bottom: 6pt;
	font-size: 16pt;
	font-weight: 700;
	font-family: "Verdana";
	text-align: left;
  }
  .gstph2 {
	display: block;
	text-indent: 0;
	margin-top: 12pt;
	margin-bottom: -2pt;
	font-size: 14pt;
	font-weight: 400;
	font-family: "Verdana";
	text-align: left;
  }
  .gstph3 {
	display: block;
	text-indent: 0;
	margin-top: 12pt;
	margin-bottom: 6pt;
	font-size: 12pt;
	font-family: "Verdana";
	text-align: left;
  }
  .gstptab {
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 10pt;
	font-family: "Verdana";
  }
  .artindent1 {
	display: block;
	margin-left: 10mm;
	margin-bottom: 11pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .arttx {
	font-family: Verdana;
	font-size: 15px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	text-align: justify;
	margin-bottom: 11pt;
  }
  
  /* updated aaa file css added for indent issue */
  
  .dgthreedigest {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: justify;
  }
  .dgbullettext {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-family: Verdana;
	text-align: justify;
  }
  .threedigest {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 8mm;
	margin-right: 8mm;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: justify;
  }
  .bullettext {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-left: 8mm;
	margin-right: 8mm;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-family: Verdana;
	text-align: justify;
  }
  .centerbox {
	display: block;
	margin-top: 12pt;
	margin-bottom: 12pt;
	text-align: center;
	text-indent: 0;
	color: #e4e4e4;
  }
  .citiation {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 4pt;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
  }
  .court {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-transform: uppercase;
	text-align: center;
  }
  .party {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
  }
  .verse {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
	font-style: italic;
  }
  .member {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 11pt;
	font-family: Verdana;
	text-align: center;
	text-transform: uppercase;
  }
  .hnnote {
	display: block;
	text-indent: 0;
	margin-top: 18pt;
	margin-bottom: 0.5em;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: justify;
  }
  .h1 {
	display: block;
	text-indent: 0;
	margin-top: 4pt;
	margin-bottom: 0;
	font-size: 11pt;
	font-weight: 700;
	font-family: "Verdana";
	text-align: left;
  }
  hr {
	margin-top: -1pt;
  }
  .tx {
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .rightbox {
	display: block;
	margin-top: 12pt;
	margin-bottom: 12pt;
	text-align: right;
	text-indent: 0;
  }
  .leftstar {
	display: block;
	margin-left: 8mm;
	margin-bottom: 4pt;
	text-indent: 0;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .quote {
	display: block;
	margin-left: 8mm;
	margin-bottom: 4pt;
	text-indent: 0;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent1 {
	display: block;
	margin-left: 10mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent1A {
	display: block;
	margin-left: 14mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent2 {
	display: block;
	margin-left: 22mm;
	margin-bottom: 4pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent2A {
	display: block;
	margin-left: 24mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent3 {
	display: block;
	margin-left: 32mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent4 {
	display: block;
	margin-left: 40mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent5 {
	display: block;
	margin-left: 48mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent6 {
	display: block;
	margin-left: 56mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .indent3A {
	display: block;
	margin-left: 34mm;
	margin-bottom: 4pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
  }
  .stathead1 {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-indent: 0;
	font-size: 11pt;
	font-family: Verdana;
	font-weight: 700;
	text-transform: uppercase;
  }
  .stathead2 {
	display: block;
	margin-top: 6pt;
	margin-bottom: 6pt;
	text-align: center;
	text-indent: 0;
	font-size: 10pt;
	font-family: Verdana;
	font-weight: 700;
	text-transform: uppercase;
  }
  .arthead1 {
	display: block;
	text-indent: 0;
	margin-top: 6pt;
	margin-bottom: 6pt;
	font-size: 13pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .arthead2 {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 6pt;
	font-size: 11pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .artrighttext {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 10pt;
	font-family: "Verdana";
	text-align: right;
	text-transform: uppercase;
	font-weight: 700;
  }
  .artrighttext1 {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 6pt;
	font-size: 10pt;
	font-family: "Verdana";
	text-align: right;
  }
  .artcircle {
	display: block;
	margin-top: 6pt;
	margin-bottom: 0;
	text-align: right;
	text-indent: 0;
	font-size: 19pt;
  }
  .large {
	display: inline;
	text-indent: 0;
	font-size: 15pt;
  }
  .allborder table {
	border: 1px solid #000;
  }
  .allborder td {
	border: 1px solid #000;
  }
  .allborder {
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
	border-collapse: collapse;
	width: 100%;
  }
  .allborder1 {
	border: 1px solid #000;
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: "Verdana";
	border-collapse: collapse;
  }
  .topbotrule {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
  }
  .toprule {
	border-top: 1px solid #000;
  }
  .botrule {
	border-bottom: 1px solid #000;
  }
  .leftrule {
	border-left: 1px solid #000;
  }
  .rightrule {
	border-right: 1px solid #000;
  }
  .leftrightrule {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .toprightrule {
	border-top: 1px solid #000;
	border-right: 1px solid #000;
  }
  .topleftrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
  }
  .topleftrightrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .botrightrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
  }
  .botleftrule {
	border-bottom: 1px solid #000;
	border-left: 1px solid #000;
  }
  .botrightlefttrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
  }
  .blocktext {
	margin-bottom: 4pt;
	text-align: justify;
	background-color: gray;
	font-size: 6pt;
	font-family: "Verdana";
	text-transform: uppercase;
	float: right;
	padding: 2px;
  }
  .stx {
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 6pt;
	font-family: "Verdana";
  }
  div.AfterAppendix,
  li.AfterAppendix,
  p.AfterAppendix {
	border: medium none;
	font-family: AsterV;
	font-size: 9.5pt;
	font-weight: 700;
	margin: 0 0 8pt;
	padding: 0;
	text-align: center;
  }
  div.Chapter,
  li.Chapter,
  p.Chapter {
	border: medium none;
	color: #000;
	font-family: Times;
	font-size: 9.5pt;
	font-style: italic;
	font-weight: 700;
	margin: 12pt 0 3pt;
	padding: 0;
	text-align: center;
	text-transform: uppercase;
  }
  div.AfterChapter,
  li.AfterChapter,
  p.AfterChapter {
	font-family: Times;
	font-size: 9.5pt;
	font-weight: 700;
	margin: 0 0 6pt;
	text-align: center;
	text-transform: uppercase;
  }
  div.MsoBodyText,
  li.MsoBodyText,
  p.MsoBodyText {
	font-family: "Verdana";
	font-size: 11pt;
	font-weight: 700;
	margin: 12pt 0 2pt;
	text-align: center;
  }
  div.BoldHeading,
  li.BoldHeading,
  p.BoldHeading {
	font-family: AsterV;
	font-size: 9.5pt;
	font-weight: 700;
	margin: 2pt 0;
	text-align: justify;
  }
  div.CenteredHeading1,
  li.CenteredHeading1,
  p.CenteredHeading1 {
	mso-style-name: "Centered Heading 1";
	mso-style-next: Normal;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 12pt;
	margin-left: 0;
	text-align: center;
	mso-pagination: widow-orphan;
	tab-stops: -1in -0.9in -0.6in -0.3in 0 0.3in 0.6in 0.9in 1.2in 1.5in 1.8in 2.1in
	  2.4in 2.7in 3in 3.3in 3.6in 3.9in 4.2in 4.5in 4.8in 5.1in 5.4in 5.7in 6in;
	font-size: 14pt;
	mso-bidi-font-size: 10pt;
	font-family: "Verdana";
	mso-fareast-font-family: "Verdana";
	color: #00f;
	mso-ansi-language: EN-GB;
	mso-fareast-language: EN-GB;
	font-weight: 700;
	mso-bidi-font-weight: normal;
  }
  .logo-name {
	font-family: "Verdana Black", Gadget, sans-serif;
	font-size: 35px;
	font-weight: 400;
	color: #545454;
	text-transform: uppercase;
  }
  .logo-text {
	font-size: 13px;
	font-weight: 700;
	color: #545454;
	width: 300px;
	text-align: center;
	font-family: "Verdana", Times, serif;
  }
  .logo-text ul {
	list-style-position: inside;
	list-style-type: none;
	margin: 0;
	padding: 0 10px 0 0;
	overflow: hidden;
  }
  .logo-text ul li {
	float: left;
	text-align: center;
	margin-left: 10px;
  }
  .logo-text ul .center-text {
	padding-left: 100px;
	padding-top: 3px;
  }
  .logo-text .taxmann-url {
	font-size: 21px;
	font-weight: 700;
	color: #545454;
	border-bottom: 1px solid #545454;
	padding-bottom: 3px;
	margin-bottom: 3px;
  }
  .DTAAHead {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 12pt;
	font-size: 16pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .DTAA11pt {
	display: block;
	text-indent: 0;
	margin-top: 12pt;
	margin-bottom: 0;
	font-size: 11pt;
	margin-bottom: 4pt;
	font-family: "Verdana";
	text-align: center;
	text-transform: uppercase;
  }
  .DTAA10pt {
	display: block;
	text-indent: 0;
	margin-top: 16pt;
	margin-bottom: 0;
	font-size: 10pt;
	margin-bottom: 16pt;
	font-family: "Verdana";
	text-align: justify;
	text-transform: uppercase;
	font-weight: 700;
  }
  .cirhead {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-indent: 0;
	font-size: 12pt;
	font-family: "Verdana";
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 4pt;
  }
  .cirhead1 {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-indent: 0;
	font-size: 11pt;
	font-family: "Verdana";
	font-weight: 700;
	margin-bottom: 6pt;
	text-transform: uppercase;
  }
  tbody tr th span.cl_top {
	text-align: left !important;
	font-size: 20px;
	font-family: Verdana;
	font-weight: 700;
	color: #1f4d71;
	border-bottom: 1px #255276 solid;
	line-height: 38px;
	float: left !important;
	width: 100% !important;
  }
  tbody tr u {
	font-family: Verdana;
	font-size: 18px;
	font-weight: 700;
	text-decoration: none;
	color: #2784ce;
  }
  .td_co_boder {
	padding: 0 10px;
	border-left: 1px #ccc solid;
  }
  .td_co_boder:hover {
	background-color: #e0f1fd;
  }
  hr {
	margin-top: -1pt;
  }
  .aaatx {
	margin-top: 4pt;
	margin-bottom: 6pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaahead {
	margin-top: 0;
	margin-bottom: 4pt;
	text-align: justify;
	font-weight: 700;
	font-size: 23pt;
	font-family: Verdana;
  }
  .aaaquote {
	display: block;
	margin-left: 8mm;
	margin-bottom: 4pt;
	text-indent: 0;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent1 {
	display: block;
	margin-left: 10mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent1A {
	display: block;
	margin-left: 14mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent2 {
	display: block;
	margin-left: 22mm;
	margin-bottom: 4pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent2A {
	display: block;
	margin-left: 24mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent3 {
	display: block;
	margin-left: 32mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent4 {
	display: block;
	margin-left: 40mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent5 {
	display: block;
	margin-left: 48mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent6 {
	display: block;
	margin-left: 56mm;
	margin-bottom: 4pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaindent3A {
	display: block;
	margin-left: 34mm;
	margin-bottom: 4pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
  }
  .aaaallborder table {
	border: 1px solid #000;
  }
  .aaaallborder td {
	border: 1px solid #000;
  }
  .aaaallborder {
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
	border-collapse: collapse;
	width: 100%;
  }
  .aaaallborder1 {
	border: 1px solid #000;
	margin-top: -1pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 11pt;
	font-family: Verdana;
	border-collapse: collapse;
  }
  .aaatopbotrule {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
  }
  .aaatoprule {
	border-top: 1px solid #000;
  }
  .aaabotrule {
	border-bottom: 1px solid #000;
  }
  .aaaleftrule {
	border-left: 1px solid #000;
  }
  .aaarightrule {
	border-right: 1px solid #000;
  }
  .aaaleftrightrule {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .aaatoprightrule {
	border-top: 1px solid #000;
	border-right: 1px solid #000;
  }
  .aaatopleftrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
  }
  .aaatopleftrightrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .aaabotrightrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
  }
  .aaabotleftrule {
	border-bottom: 1px solid #000;
	border-left: 1px solid #000;
  }
  .aaabotrightlefttrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
  }
  .aaarowhead {
	font-family: Verdana;
	text-align: justify;
  }
  
  .a_b_row {
	display: flex;
	margin-top: 20px;
  }
  .a_b_row .a_b_bx {
	max-width: 45%;
	margin-bottom: 25px;
	margin-right: 30px;
	display: flex;
	align-items: center;
  }
  .a_b_row .a_b_img {
	min-width: 82px;
	width: 82px;
	height: 82px;
	overflow: hidden;
	border-radius: 100px;
	margin-right: 15px;
  }
  .a_b_row .a_b_img img {
	width: 100%;
	height: 100%;
  }
  .a_b_row .a_b_details .a_b_name {
	font-size: 22px;
	font-weight: 500;
	letter-spacing: 0.5px;
	color: #4b4b4b;
	margin-bottom: 4px;
  }
  .a_b_row .a_b_details .a_b_n_d {
	font-size: 14px;
	color: #aaa;
	margin-bottom: 0px;
  }
  @media (max-width: 768px) {
	.a_b_row {
	  display: block;
	}
	.a_b_row .a_b_bx {
	  text-align: center;
	  margin-right: 0px;
	  display: block;
	  max-width: 100%;
	}
	.a_b_row .a_b_img {
	  margin: 0 auto;
	}
  }
  
  /* 08-01-2021 */
  .citiation {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 4pt;
	font-size: 11pt;
	font-weight: 700;
	font-family: Verdana;
	text-align: center;
  }
  hr {
	margin-top: -1pt;
  }
  .arthead1 {
	display: block;
	text-indent: 0;
	margin-top: 6pt;
	margin-bottom: 11pt;
	font-size: 13pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .arthead2 {
	display: block;
	text-indent: 0;
	margin-top: 0;
	margin-bottom: 6pt;
	font-size: 11pt;
	font-family: Verdana;
	text-align: center;
	font-weight: 700;
  }
  .arth1 {
	font-family: Verdana;
	font-size: 14px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	text-align: justify;
	margin-bottom: 11pt;
	color: #4c4c4c;
  }
  .artauthor {
	font-family: Verdana;
	font-size: 8pt;
	font-style: normal;
	font-variant: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: center;
	margin-bottom: 0;
	text-transform: uppercase;
  }
  .artauthor1 {
	font-family: Verdana;
	font-size: 8pt;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	text-align: center;
	margin-bottom: 11pt;
	margin-top: 0;
  }
  .artimage {
	font-family: Verdana;
	font-size: 0;
	font-style: normal;
	font-variant: normal;
	font-weight: 700;
	line-height: 0;
	text-align: center;
	margin-bottom: 4pt;
  }
  .arttx {
	font-family: Verdana;
	font-size: 15px;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	line-height: 20px;
	text-align: justify;
	margin-bottom: 11pt;
  }
  .rightbox {
	display: block;
	margin-top: 11pt;
	margin-bottom: 11pt;
	text-align: right;
	text-indent: 0;
  }
  .artquote {
	display: block;
	margin-left: 8mm;
	margin-bottom: 11pt;
	text-indent: 0;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artindent1 {
	display: block;
	margin-left: 10mm;
	margin-bottom: 11pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artindent1A {
	display: block;
	margin-left: 14mm;
	margin-bottom: 11pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artindent2 {
	display: block;
	margin-left: 22mm;
	margin-bottom: 11pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artindent2A {
	display: block;
	margin-left: 24mm;
	margin-bottom: 11pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artindent3 {
	display: block;
	margin-left: 32mm;
	margin-bottom: 11pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artindent3A {
	display: block;
	margin-left: 34mm;
	margin-bottom: 11pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
  }
  .artallborder table {
	border: 1px solid #000;
  }
  .artallborder td {
	border: 1px solid #000;
	line-height: 20px;
  }
  .artallborder {
	margin-top: -1pt;
	margin-bottom: 11pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
	border-collapse: collapse;
	width: 100%;
	line-height: 20px;
  }
  .allborder1 {
	border: 1px solid #000;
	margin-top: -1pt;
	margin-bottom: 11pt;
	text-align: justify;
	font-size: 15px;
	font-family: Verdana;
	border-collapse: collapse;
	line-height: 20px;
  }
  .topbotrule {
	border-top: 1px solid #000;
	border-bottom: 1px solid #000;
  }
  .toprule {
	border-top: 1px solid #000;
  }
  .botrule {
	border-bottom: 1px solid #000;
  }
  .leftrule {
	border-left: 1px solid #000;
  }
  .rightrule {
	border-right: 1px solid #000;
  }
  .leftrightrule {
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .toprightrule {
	border-top: 1px solid #000;
	border-right: 1px solid #000;
  }
  .topleftrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
  }
  .topleftrightrule {
	border-top: 1px solid #000;
	border-left: 1px solid #000;
	border-right: 1px solid #000;
  }
  .botrightrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
  }
  .botleftrule {
	border-bottom: 1px solid #000;
	border-left: 1px solid #000;
  }
  .botrightlefttrule {
	border-bottom: 1px solid #000;
	border-right: 1px solid #000;
	border-left: 1px solid #000;
  }
  
  /* 24-07-2021 */
  body {
	font-family: futura-pt, sans-serif, Verdana, Helvetica;
	color: black;
	font-weight: 400;
  }
  
  hr {
	margin-top: -1pt;
	color: black;
	border-color: black;
	background-color: black;
	border-bottom-style: solid;
	border-style: solid;
	font-weight: 400;
  }
  .tpmtx {
	margin-top: -1pt;
	margin-bottom: 8pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 19pt;
	font-weight: 400;
	color: black;
  }
  
  .tpmtxtable {
	margin-top: -1pt;
	margin-bottom: 8pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 19pt;
	color: black;
	font-weight: 400;
  }
  
  .tpmtxftn {
	margin-top: -1pt;
	margin-bottom: 8pt;
	text-align: justify;
	font-size: 10pt;
	line-height: 19pt;
	color: black;
	font-weight: 400;
  }
  
  .tpmtx1 {
	margin-top: 16pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 16pt;
	line-height: 19pt;
	color: black;
	font-weight: 400;
  }
  
  .tpmtx2 {
	margin-top: 12pt;
	margin-bottom: 6pt;
	text-align: justify;
	font-size: 14pt;
	line-height: 19pt;
	font-weight: 400;
  }
  
  .tpmtx3 {
	margin-top: 16pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 20pt;
	line-height: 19pt;
	font-weight: 400;
  }
  
  .tpmtx4 {
	margin-top: 12pt;
	margin-bottom: 4pt;
	text-align: justify;
	font-size: 14pt;
	line-height: 19pt;
	font-weight: 400;
  }
  
  .rightbox {
	display: block;
	margin-top: 12pt;
	margin-bottom: 8pt;
	text-align: right;
	text-indent: 0em;
	font-weight: 400;
  }
  .tpmleftstar {
	display: block;
	margin-left: 8mm;
	margin-bottom: 8pt;
	text-indent: 0em;
	font-size: 11pt;
	font-weight: 400;
  }
  .tpmquote {
	display: block;
	margin-left: 8mm;
	margin-bottom: 8pt;
	text-indent: 0em;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 19pt;
	font-weight: 400;
  }
  .tpmindent1 {
	display: block;
	margin-left: 10mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  .tpmindent1table {
	display: block;
	margin-left: 10mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent1A {
	display: block;
	margin-left: 14mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent2 {
	display: block;
	margin-left: 22mm;
	margin-bottom: 8pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent2table {
	display: block;
	margin-left: 22mm;
	margin-bottom: 8pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent2A {
	display: block;
	margin-left: 24mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent3 {
	display: block;
	margin-left: 32mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent3table {
	display: block;
	margin-left: 32mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent4 {
	display: block;
	margin-left: 40mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent5 {
	display: block;
	margin-left: 48mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent6 {
	display: block;
	margin-left: 56mm;
	margin-bottom: 8pt;
	text-indent: -7mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmindent3A {
	display: block;
	margin-left: 34mm;
	margin-bottom: 8pt;
	text-indent: -8mm;
	margin-top: -1pt;
	text-align: justify;
	font-size: 11pt;
	line-height: 16pt;
	font-weight: 400;
  }
  
  .tpmallborder table {
	border: 1px solid #000000;
  }
  .tpmallborder td {
	border: 1px solid #000000;
  }
  .tpmallborder {
	margin-top: -1pt;
	margin-bottom: 8pt;
	text-align: justify;
	font-size: 11pt;
	border-collapse: collapse;
	width: 100%;
	line-height: 19pt;
	border-color: black;
	font-weight: 400;
  }
  .tableheading {
	background-color: #e9e9e9;
	border-color: black;
	font-weight: 400;
  }
  
  .tpmallborder1 {
	border: 1px solid #000000;
	margin-top: -1pt;
	margin-bottom: 8pt;
	text-align: justify;
	font-size: 11pt;
	border-collapse: collapse;
	border-color: black;
	line-height: 19pt;
	font-weight: 400;
  }
  .topbotrule {
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .toprule {
	border-top: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .botrule {
	border-bottom: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  .leftrule {
	border-left: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  .rightrule {
	border-right: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .leftrightrule {
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .toprightrule {
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .topleftrule {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .topleftrightrule {
	border-top: 1px solid black;
	border-left: 1px solid black;
	border-right: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .botrightrule {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .botleftrule {
	border-bottom: 1px solid black;
	border-left: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  
  .botrightlefttrule {
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	border-left: 1px solid black;
	border-color: black;
	font-size: 11pt;
	font-weight: 400;
  }
  /* case laws print */
.arialallborder
{
margin-top: 0pt;
margin-bottom: 2pt;
text-align: justify;
font-size:10pt;
font-family:Verdana;
border-collapse: collapse;
}
.arialallborder td
{
border: 1px solid #000000;
font-family:Verdana;
}
.arialallborder1
{
border: 1px solid #000000;
margin-top: 0pt;
margin-bottom: 2pt;
text-align: justify;
font-size:10pt;
font-family:Verdana;
border-collapse: collapse;
}
.arialallborder td table td.list
{
border: 0px solid #000000 !important; font-family:Verdana !important; margin:0px;
}
.arialallborder td table td.listtld
{
border: 0px solid #000000 !important;font-family:Verdana !important;
}
.list
{
border: 0px solid #000000 !important;
font-family:Verdana !important;
}
.list td
{
border: 0px solid #000000 !important;
font-family:Verdana !important;
}
@media screen
{
.footprint
{
display:none;
font-family: Verdana;
font-size: 10pt;
}
body
{
font-family:Verdana;
font-size:10pt;
}
.list
{
border: 0px solid #000000 !important;
font-family:Verdana !important;
}
}
@media print
{
.footprint
{
display:block;
padding-right:20px;
font-family: Verdana;
font-size: 10pt;
color: #000000;
}
a
{
color:#000000;
text-decoration:none;
}
}
div.dbs_members > div
{
font-size: 9pt !important;
text-indent: -1em !important;
margin-left: 1em !important;
font-family:Verdana;
}
div.text > div
{
font-size: 9pt !important;
text-indent: -1em !important;
margin-left: 1em !important;
font-family:Verdana;
}
div.casereffered > div
{
font-size: 9pt !important;
text-indent: -1em !important;
margin-left: 1em !important;
font-family:Verdana;
}
div.headnote > i
{
font-weight: normal !important;
font-style: italic;
font-family:Verdana;
}
div.appealorder
{
text-indent: 0em;
margin-top: 0.3em;
margin-bottom: 0.3em;
font-size: 10pt;
font-family: Verdana;
text-align: center !important;
font-style: italic;
}
div.appealorder > sup > a
{
font-style: normal !important;
font-weight: normal !important;
font-family:Verdana;
}
`
