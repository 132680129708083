import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'sidebarUrlResearch',
})
export class SidebarUrlPipe implements PipeTransform {
	constructor() {}

	transform(level: number, list: any, ischildren: any, subCategoryItem?: any, item?: any) {
		if (!list.parent_category_id && !ischildren && !['All Case Laws', 'Latest News'].includes(list?.name)) {
			return
		} else {
			return list.url
		}
	}

	// createUrlForMenu(childNode: any, url: string, parentNode: any) {
	//     return url;
	// }
}
