import { Injectable } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { CentaxApiList } from '@App/constant/centax-api.constant'
import { LocalStorageDataModel } from '@App/constant/localstorage.constant'
import { APIResponse } from '@App/interface/centax-data.interface'
import { HttpHelperService } from '@App/services/http-helper.service'
import { AppUtilityService } from '@App/utility/app-utlility.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { citationParamJournalType, journalTypeList } from './constants/journalTypeList.constant'
import { CommonAlertService } from '@App/services/common-alert.service'
import { ApiStatus } from '@App/constant/centax-app.constant'
import { GST_FINANCE_ACTS_API } from '../constant/api.constant'

@Injectable({
	providedIn: 'root',
})
export class BaseService {
	public page = 1
	public pageSize = 50
	public filterDetailsChange: FormGroup
	private resetMiddlepanel = new Subject()
	private filterMiddlePanel = new Subject()
	private uploadSearchFilterMiddlePanel = new BehaviorSubject<any>({})
	private moreFilterList = new Subject()
	private subjectFilterList = new Subject()
	private glbSrchFilterFromAdv = new Subject()
	public catUrl = ''
	public subCatUrl = ''

	public selectedSubject
	public subjectSearchText: string = ''
	public subjectLabelArr = []
	public searchTextFromHeader: string
	public initialAutoSelectZeroth = false
	private $searchTextHeader = new Subject()
	public catUrlSubject = ''
	public groupSubject = ''
	public didYouMeanText = ''
	public searchInsteadFor: boolean = false
	public showingResultsFor: string = ''
	public filterList;
	constructor(
		protected httpHelperService: HttpHelperService,
		protected _appUtilityService: AppUtilityService,
		protected _fb: FormBuilder,
		protected commonAlertService: CommonAlertService
	) { }

	getResetMiddlepanel() {
		return this.resetMiddlepanel.asObservable()
	}

	setResetMiddlepanel(data: any) {
		this.resetMiddlepanel.next(data)
	}

	getFilterMiddlePanel() {
		return this.filterMiddlePanel.asObservable()
	}

	setUploadSearchFilterMiddlepanel(data: any) {
		this.uploadSearchFilterMiddlePanel.next(data)
	}

	getUploadSearchFilterMiddlePanel() {
		return this.uploadSearchFilterMiddlePanel.asObservable()
	}

	setFilterMiddlePanel(data: any) {
		this.filterMiddlePanel.next(data)
	}

	getMoreFilterList() {
		return this.moreFilterList.asObservable()
	}

	setMoreFilterList(data: any) {
		this.moreFilterList.next(data)
	}

	getSubjectFilterList() {
		return this.subjectFilterList.asObservable()
	}

	setSubjectFilterList(data: any) {
		this.subjectFilterList.next(data)
	}

	public setFilterFormValue(key: string, value: any) {
		this.filterDetailsChange.get(key).setValue(value)
	}

	public getFilterFormValue(key: string) {
		return this.filterDetailsChange.get(key).value
	}

	public resetFilterFormValue() {
		this.filterDetailsChange.setValue(
			{
				defaultApiUrl: [''],
				subjectApiUrl: [''],
				uploadSearchFilterApi: [''],
				selectedFilter: {},
				sortby: '',
				sortorder: '',
			},
			{ emitEvent: false }
		)
	}

	public callFiltersApiList() {
		if (this.filterDetailsValue.uploadSearchFilterApi) {
			this.getCentaxUploadSearchFilterAndMidPanel(this.filterDetailsValue.uploadSearchFilterApi)
		}
		if (this.filterDetailsValue.subjectApiUrl) {
			// return
			this.getCentaxSubjectFilter(this.filterDetailsValue.subjectApiUrl)
		}
		if (this.filterDetailsValue.defaultApiUrl && !this._appUtilityService.getDataFromLocalStorage('fileName')) {
			if(this.filterDetailsValue.defaultApiUrl == 'getAllCaselaws'|| this.filterDetailsValue.defaultApiUrl == 'getCaselaws'){
			this.getCentaxGroupFilterAndMidPanelWithQueryParam(this.filterDetailsValue.defaultApiUrl)
			}else{
				this.getCentaxGroupFilterAndMidPanel(this.filterDetailsValue.defaultApiUrl)
			}
		}
	}
	getCentaxSubjectFilter(api: string) {
		// this._appUtilityService.showLoader = true;
		this.mapSubjectType()
		let url = this._appUtilityService.getApiUrl(api)
		return this.httpHelperService
			.post(
				url,
				{
					catUrl: this.catUrlSubject,
					groupUrl: this.groupSubject,
					searchBoxText: this.searchTextFromHeader,
					subjectSearchText: this.subjectSearchText,
					selectedSubject: this.selectedSubject || [],
					ftpGroupUrl:this.groupSubject 
				},
				true
			)
			.subscribe((response: APIResponse) => {
				// this._appUtilityService.showLoader = false;
				if (response.Data) {
					this.subjectFilterList.next(response?.Data?.filter || [])
					this._appUtilityService.setSubjectSearchStatus(true)
				} else if (response.ResponseType == 'FIRST_SEARCH') {
					this._appUtilityService.setSubjectSearchStatus(true)
					this.subjectFilterList.next([])
				} else if (response.ResponseType == 'DATA_NOT_FOUND') {
					this._appUtilityService.setSubjectSearchStatus(false)
					this.subjectFilterList.next([])
				}
			})
	}

	getCentaxGroupFilterAndMidPanel(api: string) {

		let journalList = this.filterDetailsValue.selectedFilter?.journalList;
		if (journalList?.length == 1 && this.filterDetailsValue.sortby == 'citationwise') {
			this.filterDetailsValue.sortby = this.modifySortBy(journalList);
		}
		this._appUtilityService.showLoader = true
		let url = this._appUtilityService.getApiUrl(api)
		// advanceSearchCPData.isAdvSearch = this._appUtilityService.isAdvSearch;
		// this.searchTextFromHeader = advanceSearchCPData.searchText;
		return this.httpHelperService
			.post(
				url,
				{
					catUrl: this.catUrl,
					subCatUrl: this.subCatUrl,
					page: this.page,
					pageSize: this.pageSize,
					filter: this.filterDetailsValue.selectedFilter,
					sortby: this.filterDetailsValue.sortby, //this.searchTextFromHeader ? 'relevance' : this.filterDetailsValue.sortby,
					sortorder: this.filterDetailsValue.sortorder,
					searchText: this.searchTextFromHeader,
					subjectLabelArr: this._appUtilityService.subjectLabelArr,
					isheadnoteToggle: this._appUtilityService.isheadnoteToggle,
					isAdvSearch: this._appUtilityService.isAdvSearch,
					// advanceSearch: {} //this will be removed as we dont need advance search in current page
				},
				true
			)
			.subscribe((response: APIResponse) => {
				if (response.Data) {
					this._appUtilityService.showLoader = false
					if (response.Data?.searchInsteadfor) {
						this.searchInsteadFor = true
						this.showingResultsFor = response.Data?.showingResultsfor
					} else {
						this.searchInsteadFor = false
						this.showingResultsFor = ''
					}
					console.log("mid  list in api", response?.Data?.result)
					this.setFilterMiddlePanel({
						midlist: response?.Data?.result || [],
						total: response?.Data?.total || 0,
						filterlist: response?.Data?.filter || [],
					});
					this.filterList = response?.Data?.filter || [];
					this._appUtilityService.errorAPIInvalidCharacters$.next(false);
				} else {
					this._appUtilityService.showLoader = false
					this.searchInsteadFor = false
					this.showingResultsFor = ''
					this.setFilterMiddlePanel({
						midlist: [],
						total: 0,
						filterlist: [],
					})
					this.filterList = [];
					if(response.StatusMsg.toLowerCase() == ApiStatus.INVALID_CHARACTERS){
						this._appUtilityService.errorAPIInvalidCharacters$.next(true);
					// this.commonAlertService.errorToast(ApiStatus.INVALID_CHARACTERS_ERROR_MSG);
					}else{
						this._appUtilityService.errorAPIInvalidCharacters$.next(false);
						this.commonAlertService.errorToast(response.StatusMsg);
					}
				}
			})
	}

	getCentaxGroupFilterAndMidPanelWithQueryParam(api: string) {

		let journalList = this.filterDetailsValue.selectedFilter?.journalList;
		if (journalList?.length == 1 && this.filterDetailsValue.sortby == 'citationwise') {
			this.filterDetailsValue.sortby = this.modifySortBy(journalList);
		}
		this._appUtilityService.showLoader = true
		let url = this._appUtilityService.getApiUrl(api)
		// advanceSearchCPData.isAdvSearch = this._appUtilityService.isAdvSearch;
		// this.searchTextFromHeader = advanceSearchCPData.searchText;
		return this.httpHelperService
			.postWithQueryParam(
				url,
				{
					catUrl: this.catUrl,
					subCatUrl: this.subCatUrl,
					page: this.page,
					pageSize: this.pageSize,
					filter: this.filterDetailsValue.selectedFilter,
					sortby: this.filterDetailsValue.sortby, //this.searchTextFromHeader ? 'relevance' : this.filterDetailsValue.sortby,
					sortorder: this.filterDetailsValue.sortorder,
					searchText: this.searchTextFromHeader,
					subjectLabelArr: this._appUtilityService.subjectLabelArr,
					isheadnoteToggle: this._appUtilityService.isheadnoteToggle,
					isAdvSearch: this._appUtilityService.isAdvSearch,
					// advanceSearch: {} //this will be removed as we dont need advance search in current page
				},
				{
					isAdvSearch: this._appUtilityService.isAdvSearch
				},
				true
			)
			.subscribe((response: APIResponse) => {
				if (response.Data) {
					this._appUtilityService.showLoader = false
					if (response.Data?.searchInsteadfor) {
						this.searchInsteadFor = true
						this.showingResultsFor = response.Data?.showingResultsfor
					} else {
						this.searchInsteadFor = false
						this.showingResultsFor = ''
					}
					console.log("mid  list in api", response?.Data?.result)
					this.setFilterMiddlePanel({
						midlist: response?.Data?.result || [],
						total: response?.Data?.total || 0,
						filterlist: response?.Data?.filter || [],
					});
					this.filterList = response?.Data?.filter || [];
					this._appUtilityService.errorAPIInvalidCharacters$.next(false);
				} else {
					this._appUtilityService.showLoader = false
					this.searchInsteadFor = false
					this.showingResultsFor = ''
					this.setFilterMiddlePanel({
						midlist: [],
						total: 0,
						filterlist: [],
					})
					this.filterList = [];
					if(response.StatusMsg.toLowerCase() == ApiStatus.INVALID_CHARACTERS){
						this._appUtilityService.errorAPIInvalidCharacters$.next(true);
					// this.commonAlertService.errorToast(ApiStatus.INVALID_CHARACTERS_ERROR_MSG);
					}else{
						this._appUtilityService.errorAPIInvalidCharacters$.next(false);
						this.commonAlertService.errorToast(response.StatusMsg);
					}
				}
			})
	}

	modifySortBy(journalList) {
		if (journalList[0] == journalTypeList.GSTL) {
			return citationParamJournalType.GSTL
		} else if (journalList[0] == journalTypeList.STR) {
			return citationParamJournalType.STR
		} else if (journalList[0] == journalTypeList.CENTAX) {
			return citationParamJournalType.CENTAX
		} else if (journalList[0] == journalTypeList.ELT) {
			return citationParamJournalType.ELT
		} else {
			return citationParamJournalType.DEFAULT
		}
	}

	getCentaxUploadSearchFilterAndMidPanel(api: string) {
		let keyList = []
		this._appUtilityService.getDataByStoreName('upload_and_search').subscribe((res) => {
			if (!res?.length) {
				return
			} else {
				keyList = res[res?.length - 1]?.data?.keyList
				const body = {
					page: this.page,
					pageSize: this.pageSize,
					sortorder: this.filterDetailsValue.sortorder,
					searchText: this.searchTextFromHeader,
					filters: this.filterDetailsValue.selectedFilter,
					keyList,
				}
				const formData = new FormData()
				formData.append('payload', JSON.stringify(body))
				this._appUtilityService.showLoader = true
				const url = this._appUtilityService.getApiUrl(api)
				return this.httpHelperService
					.postFile(url, formData, true, false, {}, true, false)
					.subscribe((response: any) => {
						if (response.body.Data) {
							this._appUtilityService.showLoader = false
							this._appUtilityService.setFileUploadSearchResult({ Data: response?.body })
							this._appUtilityService.setFileUploadSearchResult({
								fileName: this._appUtilityService.getDataFromLocalStorage('fileName'),
								Data: response.body?.Data,
							})
							this.setFilterMiddlePanel({
								midlist: response?.body?.Data?.itemarray || [],
								total: response?.body?.Data?.totalCount || 0,
								filterlist: response?.body?.Data?.filter || [],
							})
						}
					})
			}
		})
	}
	getMoreFilterDetails() {
		if (this.filterDetailsValue.moreFilterApiUrl) {
			this.getCentaxGroupExtraFilter(this.filterDetailsValue.moreFilterApiUrl)
		}
	}

	getCentaxGroupExtraFilter(api: string) {
		this._appUtilityService.showLoader = true
		let url = this._appUtilityService.getApiUrl(api)
		return this.httpHelperService
			.post(
				url,
				{
					catUrl: this.catUrl,
					subCatUrl: this.subCatUrl,
					page: this.page,
					pageSize: this.pageSize,
					filter: this.filterDetailsValue.selectedFilter,
					sortby: this.filterDetailsValue.sortby,
					sortorder: this.filterDetailsValue.sortorder,
					searchText: this.searchTextFromHeader,
					isheadnoteToggle: this._appUtilityService.isheadnoteToggle,
					isAdvSearch: this._appUtilityService.isAdvSearch,
					advanceSearch: {}, // this wil be removed as we dont need advance search in current page,
					subjectLabelArr: this._appUtilityService.subjectLabelArr,
				},
				true
			)
			.subscribe((response: APIResponse) => {
				this._appUtilityService.showLoader = false
				// if (response.Data) {
				this.setMoreFilterList(response?.Data?.filter || [])
				// }
			})
	}

	get filterDetailsValue() {
		return this.filterDetailsChange.value
	}

	emptyOldSearches() {
		this.searchTextFromHeader = ''
	}

	getGlbSrchFilterFromAdv() {
		return this.glbSrchFilterFromAdv.asObservable()
	}

	setGlbSrchFilterFromAdv(data: any) {
		this.glbSrchFilterFromAdv.next(data)
	}

	getsearchTextHeader() {
		return this.$searchTextHeader.asObservable()
	}

	setsearchTextHeader(data: any) {
		this.$searchTextHeader.next(data)
	}

	mapSubjectType() {
		switch (this.catUrl) {
			case 'centax-gst':
				this.catUrlSubject = 'gst'
				break
			case 'centax-customs':
				this.catUrlSubject = 'customs'
				break
			case 'centax-excise-service-tax':
				this.catUrlSubject = 'excise-service-tax'
				break;
				case 'centax-foreign-trade-policy':
					this.catUrlSubject = 'gst'
					break;	
		}
	}

	getFilterLabelNameFromId(filterName, key, listName) {
		let filters = this.getFilterFormValue(filterName);
		let filterId = filters[key];
		let centaxFilterListData = this.filterList;
		let filterList = this.getFiltersList(listName, centaxFilterListData);
		let foundLabel = filterList.find((filter) => filter.value == filterId[0]);
		return foundLabel.label
	}

	getFiltersList(filterName, filterArr) {
		let filterList = filterArr.find((filter) => filter.name == filterName);
		return filterList.list.items;
	}
	getFinanceACtSectionList(payload) {
		let url = this._appUtilityService.getApiUrl(GST_FINANCE_ACTS_API.GET_SECTIONS_LIST)
		return this.httpHelperService.post(url, payload, true)
	}
	getFinanceACtChildList(payload) {
		let url = this._appUtilityService.getApiUrl(GST_FINANCE_ACTS_API.GET_SECTIONS_CHILD_LIST)
		return this.httpHelperService.post(url, payload, true)
	}
	getFinanceActChapterWise(payload) {
		let url = this._appUtilityService.getApiUrl(GST_FINANCE_ACTS_API.GET_FINANCE_ACT_CHAPTER_WISE_PRINT)
		return this.httpHelperService.post(url, payload, true)
	}
	getFinanceActSectionWise(payload) {
		let url = this._appUtilityService.getApiUrl(GST_FINANCE_ACTS_API.GET_FINANCE_ACT_SECTION_WISE_PRINT)
		return this.httpHelperService.post(url, payload, true)
	}
}
