import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SubstringPipe } from './pipes/substring.pipe'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { DialogModule } from 'primeng/dialog'
import { PopupComponent } from './popup/popup.component'
import { NoDataFoundComponent } from './no-data-found/no-data-found.component'
import { KeyboardEvent } from '../library/directives/keyboard-event.directive'

@NgModule({
	declarations: [SubstringPipe, ConfirmDialogComponent, PopupComponent, KeyboardEvent, NoDataFoundComponent],
	imports: [CommonModule, DialogModule],
	exports: [SubstringPipe, ConfirmDialogComponent, PopupComponent, NoDataFoundComponent, KeyboardEvent],
})
export class CentaxSharedModule {}
