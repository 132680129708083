export const LocalStorageDataModel = {
	AUTH_TOKEN: 'centaxauthorization',
	IS_PROXY_USER: 'isProxyUser',
	CONTENT_TOKEN: 'contenttoken',
	I_READER_TOKEN: 'ireadertoken',
	SEARCH_TEXT: 'SEARCH_TEXT',
	MODULE_TYPE: 'moduleType',
	DOC_TYPE: 'docType',
	LAST_VISITED_URL_BEFORE_ALL_PAGE_SEARCH: 'lastVisitedURLBeforeAllPageSearch',
	USER_IP: 'user_ip',
	MULTIPLE_EMAILS_ASSOCIATED: 'multiple_emails_associated',
	USER_MOBILE_ID: 'user_mobile_id',
	USER_MOBILE_NUMBER: 'userMobileNumber',
	USER_NAME: 'userName',
	USER_LNAME: 'userLName',
	USER_EMAIL: 'userEmail',
	PREVIOUS_URL: 'previousURL',
	FIRST_TIME_VISIT: 'firstTimeVisit',
	EMAIL: 'email',
	STATE_NAME : 'stateName',
	ISIPUSER: 'isIpUser',
	USER_DOMAIN: 'userDomain',
	SOCIAL_LOGIN_FAILED: 'social_login_failed',
	LOGIN_TYPE: 'loginType',
	SOCIAL_TYPE: 'socialType',
	TRIAL_EMAIL: 'trialEmial',
	CENTAX_FREE_TRIAL_ACTION: 'cemntax_trial_action_enable',
	LOGIN_SIGNUP_TYPE:'LOGIN_SIGNUP_TYPE',
    CART_INFO: 'cartInfo',
	USER_MOBILE : 'mobile',
	NEWSLETTER_CONSENT_FLAG : 'newsletterConsentFlags',
	DISPLAY_CONSENT_FORM:'displayNewsletterConsentform',
	SEARCH_MENU:'searchMenu',
	RENEW_RESEARCH_PLAN: 'isRenewResearchPlan',
	NOTIFICATION_ALERT: 'notificationalert',
	MACHINE_ID: 'machineId',
	MACHINE_ID_NEW: 'machineId1'
}
